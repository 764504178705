import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import "./Home.css"
import Slider from 'react-slick';
import $, { event } from 'jquery';
import { UserContext } from '../../authentication/pages/UserContext';
import Header from '../common-components/Header';
import { HashLink as Linker } from 'react-router-hash-link';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';
import { Modal } from 'react-bootstrap';
import PositiveModel from '../../PositiveModel';
import HomeGallery from '../common-components/HomeGallery'




function Home() {
  
  const [isLoading, setIsLoading] = useState(false);

  const [liturgiaMass, setLiturgiaMass] = useState([]);
  const [message, setMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [palabras, setPalabras]=useState("")
  const [sec3Data, setSec3Data] = useState([])

  let slider;
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '10px',
    slidesToShow: 3,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,

    css: {
      '.slick-center': {
        backgroundColor: 'yellow',
      },
    },
    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px',

        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',

        }
      }

    ],
    beforeChange: (currentSlide, nextSlide) => {
      const slickList = document.querySelector('.card3-home');
      if (slickList) {
        if (slickList.childNodes[nextSlide + 1]) {
          slickList.childNodes.forEach((child, index) => {
            if (index === nextSlide + 1) {
              child.style.height = '450px'; // Set height for the center slide
              child.style.width = '350px';

              child.style.backgroundColor = 'yellow';
            } else {
              child.style.height = '400px'; // Set height for non-center slides
              child.style.width = '350px';

              child.style.backgroundColor = ''; // Reset background color for non-center slides
            }
          });
        }
      }

    },
  };


  const [GalleryData, setGalleryData] = useState([]);
  
 const gallerySlideSettings = {
  dots: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 3000,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
     {
        breakpoint: 992,
        settings: {
           slidesToShow: 3
        }
     },
     {
        breakpoint: 768,
        settings: {
           slidesToShow:2
        }
     },
     {
        breakpoint: 576,
        settings: {
           slidesToShow:1
        }
     }
  ],
};






  const { setIsScrollingUp } = useContext(UserContext)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrollingUp(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);




  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isVisible = prevScrollPos > currentScrollPos;

      setIsVisible(isVisible);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const scrollToTarget = () => {
    const targetDiv = document.getElementById('target-home');
    targetDiv.scrollIntoView({ behavior: 'smooth' });
  };

  const [showmore, setShowmore] = useState(true);

  const showmorebtn = () => {
    setShowmore(!showmore)
  }

  const settingsup = {
    dots: false,
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,


  };






  const sliderRef = useRef(null);

  const slideLeft = () => {
    sliderRef.current.slickPrev();
  };

  const slideRight = () => {
    sliderRef.current.slickNext();
  };



  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
 
  const fetchEvents = async (search_key) => {
    try {
      const response = await API.get(`events/?search_key=${search_key}`);
      console.log("API success", response);
      return response.data.results.map(event => ({
        ...event,
        start_time: convertTo12Hour(event.start_time),
        end_time: convertTo12Hour(event.end_time),
        status: search_key === 'completed' ? 'completed' : 'upcoming'
      }));
    } catch (error) {
      console.log("API error", error);
      return [];
    }
  };

  const loadSec3Data = () => {
    API.get(`/websitecontent/?page_name=home&section_number=3`)
      .then(response => {
        if (response.data && response.data.results) {
          setSec3Data(response.data.results.sort((a, b) => a.id - b.id));

        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }
  const loadPalabras=()=>{
    API.get("/websitecontent/?page_name=home&section_number=9")
    .then(response=>{
      setPalabras(response.data.results[0])
    })
  }

  const loadEvents = async () => {
    const upcomingEvents = await fetchEvents('upcoming');     
      setUpcomingEvents(upcomingEvents);
  };

  const loadGalleryData = () => {
    API.get(`gallery/?page_size=6&page=1`)
      .then(response => {
        setGalleryData(response.data.results);
      })
      .catch(error => {
        // setMessage(error.response?.data?.message || error.message);
        // setIsErrorModalOpen(true);
      });
  }

  useEffect(() => {
   
    loadEvents();
    loadPalabras();
    loadGalleryData();
    loadSec3Data();
  }, []);



  const convertTo12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(part => parseInt(part, 10));
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }
    let ampm = 'AM';
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = 'PM';
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours = adjustedHours < 10 ? '0' + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };


  const setttingid = (id) => {

    localStorage.setItem("event_id", id)
  }

 
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await API.get(`mass/?mass_type=liturgia`);
        // console.log("API success", response);
        const updatedEvents = response.data.results.map((event) => ({
          ...event,
          start_time: convertTo12Hour(event.start_time),
          end_time: convertTo12Hour(event.end_time),
          mass_date: convertToDDMMYYYYmass(event.mass_date), // Convert mass_date here
        }));
        setLiturgiaMass(updatedEvents);
      } catch (error) {
        console.log("API error", error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      }
    };
    fetchEvents();
  }, []);


  const convertToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const convertToDDMMYYYYmass = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[date.getMonth()];
    return { day, monthName };
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };





  return (
    <div >
      <div className='image-head' style={{ backgroundImage: `url("/images/home/Image 2.jpg")` }}>
        <Header />


        <div className='tail-background-home'>
          <span className='poppins-semibold white-small'>Parroquia</span>
          <span className='poppins-semibold white'>Santa María del Bosque</span>
          <img src='/images/home/Group.svg' onClick={scrollToTarget} className='down-arrow-home' />
        </div>


      </div>


      <div className='background-text-home' id='target-home'>
        <p className='text-home dm-sans-regular'>
          La constitución de la parroquia fue un camino largo, con varias etapas. El 21 de septiembre de 1971, la antigua parroquia de San Saturio cambia de nombre y pasa a llamarse SANTA MARÍA DEL BOSQUE. A primeros de octubre de 1971, según los libros parroquiales, se inaugura en sus proximidades otra parroquia, bajo el nombre de Nuestra Señora del Faro.

        </p>
        {showmore ? ("") : (
          <p className='text-home dm-sans-regular'>
            Siete años después, el 15 de diciembre de 1978, el Cardenal Enrique Tarancón unifica las dos parroquias, quedando como única titular la de SANTA MARÍA DEL BOSQUE, desapareciendo Nuestra Señora del Faro.

          </p>)}
        {showmore ? (<div className='readmore' onClick={showmorebtn}>
          <span className='dm-sans-regular rm'>LEER MÁS</span><img src="/images/home/Group (1).svg" className='updown-read' />
        </div>)
          :
          (<div className='readmore' onClick={showmorebtn}>
            <span className='dm-sans-regular rm'>LEER MENOS</span><img src="/images/home/upreadless.svg" className='updown-read' />
          </div>)}


      </div>



{sec3Data&&sec3Data.length==3&&
      <div className='card-img-box'>
        <div className='card-contain-home'>
          <img src='/images/home/Card 1.jpg' className='imgcard-home' />
          <div className='card-home'>
            <p className='poppins-regular1 headcard' >{sec3Data[0].title}</p>
            <p className='mulish-regular bodycard-home' style={{whiteSpace: "pre-line"}}>{sec3Data[0].description}</p>
            {/* <Linker to='/espiritualidad#bm' className="txt-dec "> <div className='btn-tyellow-home mulish-bold'>VIEW PRAYER</div> </Linker> */}
            <Link to='/prayer-virginmary' className="txt-dec "> <div className='btn-tyellow-home mulish-bold'>VER ORACIÓN</div> </Link>

          </div>
        </div>
        <div className='card-contain-home'>
          <img src='/images/home/Card 2.jpg' className='imgcard-home' />
          <div className='card-home'>
            <p className='poppins-regular1 headcard' >{sec3Data[1].title}</p>
            <p className='mulish-regular bodycard-home'  style={{whiteSpace: "pre-line"}}>{sec3Data[1].description} </p>
            <Linker to="/contacto#cnt" className='txt-dec'><div className='btn-tyellow-home mulish-bold'>
            LIBRO</div></Linker>
          </div>
        </div>
        <div className='card-contain-home'>
          <img src='/images/home/Card 3.jpg' className='imgcard-home' />
          <div className='card-home'>
            <p className='poppins-regular1 headcard' >{sec3Data[2].title}</p>
            <p className='mulish-regular bodycard-home' style={{whiteSpace: "pre-line"}}>{sec3Data[2].description}</p>
            {/* <Link to="/events" className='lik'> <div className='btn-tyellow-home mulish-bold'>VIEW PRAYER</div></Link> */}
            <Link to="/prayer-saintrita" className='lik'> <div className='btn-tyellow-home mulish-bold'>VER ORACIÓN</div></Link>
          </div>
        </div>

      </div>
}



      <div className='box1-home'>
        {/* <p className='poppins-semibold heading1-home '>Worship with Us.</p> */}
        <p className='dm-sans-regular text1-home'>
          La Encomienda de la parroquia a los Padres Agustinos tuvo estos momentos importantes. El 28 de enero de 1982, el Cardenal Enrique Tarancón solicita por escrito a nuestro P. Provincial que acepte colaborar con la pastoral diocesana rigiendo la parroquia de Santa María del Bosque. El 9 de febrero, el P. Provincial aceptó hacerse cargo de la misma. El primer matrimonio se celebró el día 13 de mayo de 1982 y el primer Bautismo el 5 de junio del mismo año. La parroquia está situada en la avenida Arturo Soria en plena Ciudad Lineal y su nombre y advocación de Santa María del Bosque lo toma del barrio y de la situación originaria que era un bosque de pinos fundamentalmente.
        </p>
      </div>

{upcomingEvents.length > 1&&
      <div className='box2-home'>
        <div className='text-box2'>
          <span className='great-vibes-regular cursivez'>Siguientes <span className='poppins-regular1'>Actividades</span></span>
        </div>



        <div className='card-box2'>


          <img src='/images/home/leftarrow.svg' className='arrows' onClick={slideLeft} />
          <div className='rct'>
            <Slider ref={sliderRef} {...settingsup}>
              {upcomingEvents.map((event, index) => (
                <div className='reactslick-div' key={index}>
                  <div className='yellowcard-home'>
                    <div className='black'>
                      <img src="\images\home\book.svg" className='book' />
                    </div>
                    <span className='mulish-bold font1'>{event.title}</span>
                    <p className='mulish-regular insidetext'>Fecha : {event.date}</p>
                    <p className='mulish-regular insidetext'>
                    Tiempo : {event.start_time} - {event.end_time}</p>
                    <p className='mulish-regular insidetext'>
                    Ubicación : {event.location}</p>
                    <Linker to="/reg#reg" className='txt-dec' > <div className='btn-card2 dm-sans-regular' onClick={() => localStorage.setItem("event_id", event.id)} >
                    Toca para unirte</div></Linker>

                  </div>
                </div>
              ))}

            </Slider>

          </div>
          <img src='/images/home/rightarrow.svg' className='arrowsr' onClick={slideRight} />




        </div>


      </div>
}


<div className='forimg-home'>
<img src="\images\home\ALV_9086.jpg" className='' width={"100%"}  />

</div>
    



      <div className='box3-home'>

        <div className='text-box3'>
          <span className='great-vibes-regular cursive1'>Oración comunitaria   <span className='poppins-regular1'></span></span>
          <p className='poppins-regular1 cursive1'>  parroquial</p>

        </div>

        <p className='dm-sans-regular text2-home'>
        Todos los jueves, después de la misa de la tarde, a las 20,00h tenemos oración comunitaria delante del Santísimo Sacramento.
        </p>



        <img src='/images/home/cross.svg' className='cross' />


      </div>

{liturgiaMass.length>3&&
      <div className='box5-home'>
        <p className='head-home poppins-regular1'>
          Liturgia de cada día
        </p>
        <div className='cardflex'>
          <div className="slider-container">
            <Slider ref={(c) => (slider = c)}  {...settings}>

              {liturgiaMass.map((event, index) => (

                <div className='slider1'>
                  <div className='card3-home' >
                    <div className='darkcircle dm-sans-bold'><span className='number1'> {event.mass_date.day} </span><span className='month'>{event.mass_date.monthName}</span></div>
                    <p className='poppins-semibold head-card3'>{event.title}</p>
                    <span className='timebox-home'>

                      <img src='\images\home\kisspng-computer-icons-time-attendance-clocks-font-aweso-clock-icon-5b14c636776207 1.png' className='timelogo' />
                      <span className='dm-sans-regular'>{event.start_time} - {event.end_time}</span>

                    </span>
                    <p className='dm-sans-regular'> Ubicación : {event.location}</p>


                    <div className='btn4-home dm-sans-bold' onClick={() => openModal(event)} >SABER MÁS</div>
                  </div>
                </div>

              ))}

            </Slider>
          </div>


        </div>

      </div>
}
      <div className='box4-home'>
        <div className='text-box4'>
          <span className='great-vibes-regular cursive cursivemob'>Reflexiones sobre

</span>
          <p className='poppins-regular1 cursive cursivemob'>Lecturas del Domingo </p>
          <Link to="/month-prayer" className='txt-dec btn-card3 dm-sans-bold'> 
            Leer
          </Link>
        </div>
        <img src='/images/home/hands.svg' className='hands' />
      </div>
      <div className="home-sec-4">
     
        <HomeGallery secData={GalleryData} secSlideSettings={gallerySlideSettings} />
      </div>


      <div className='image-down' style={{ backgroundImage: `url('/images/home/Rectangle 764.png')` }}>

        <p className='yellowtext'>PALABRAS DEL MES</p>
         <p className='whitetext-bottom dm-sans-italics '>"{palabras.description}"</p>  {/* I can do all things through Christ who strengthens me */}
        <p className='white-bottom'>{palabras.title}</p>{/*PHILIPPIANS 4:13 */}
      </div>

      {isLoading && <LoadingSpinner />}
      <ErrorPopup
        message={message}
        state={isErrorModalOpen}
        setterFunction={setIsErrorModalOpen}
        okClickedFunction={() => {
          window.location.reload();
        }}
      />

      {isModalOpen && selectedEvent && (
        <PositiveModel
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedEvent.name}
          content={selectedEvent.description}
        />
      )}


    </div>




  )
}

export default Home