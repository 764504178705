import React, { useContext, useEffect, useState } from 'react'
import "./AdminList.css"
import { Link } from 'react-router-dom'
import Delete from '../common-components/Delete';
import { UserContext } from '../../authentication/pages/UserContext';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import $ from "jquery"


function AdminList() {
  const navigate = useNavigate();
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])
  const [newAdminList, setNewAdminList] = useState([]);
  const [EditAdminList, setEditAdminList] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(0);

  const loadData = () => {
    // new admins
    API.get(`user/admin_users/?admin=new`)
      .then(response => {
        setNewAdminList(response.data);
        // console.log(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true)
        setMessage(error.message)
      });
    // Old admin list
    API.get(`user/admin_users/`)
      .then(response => {
        setEditAdminList(response.data);
        // console.log(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true)
        setMessage(error.message)
      });
  }

  useEffect(() => {
    loadData();
  }, [isDeleteModalOpen]);


  const { deletebtn2, setDeleteuser, deleteuser } = useContext(UserContext)

  const handlePermissionClick = (userData, isEdit) => {
    navigate('/admin/adminlist/permission', { state: { userData, isEdit  } });
  };

  const handleDelete = (id) => {
    setSelectedItemID(id);
    setIsDeleteModalOpen(true)
  }
  const deleteItem = (id) => {
    API.delete(`user/assign_permissions/${id}/`)
      .then(response => {
        console.log(response.data);
        setIsSuccessModalOpen(true);
        setMessage('Usuario eliminado exitosamente.')
      })
      .catch(error => {
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
    setIsLoading(false);
  }
  

  return (
    <div className='adminlistbody'>
      <div className='headerofevent55'>

        <span className='titlebox-ad dm-sans-regular'>Página de lista de administradores</span>
        <Link to="regpage" className='create123'>
          <div className='createbox' >
            <img src='\images\admin\eventad\Plus.svg' className='crtplus' />
            Crear administrador
          </div>
        </Link>

      </div>
      <p className='dm-sans-regular newchat'>Nuevas solicitudes de administrador</p>


      <div className='whitebox-message-al dm-sans-bold dontshow'>
        <span className='head-message'>Nombre</span>

        <span className='head-message123'>Correo electrónico </span>

        <span className='head-message'>Acciones</span>

      </div>





      {newAdminList.map((user, index) => (

        <div className='whitebox-message-al dm-sans-regular '>
          <span className='head-message66'><span className='formob-msg'>Name</span>
          {user.first_name ? `${user.first_name} ${user.last_name}` : user.username}
          </span>

          <span className='head-message123'><span className='formob-msg'>Correo electrónico </span>{user.email}</span>
          <div className='head-message66'>
            <span className='formob-msg'> Acciones</span>
            <div to="permission" className='sendreply-al' onClick={()=>handlePermissionClick(user,false)} >Dar Permiso</div>
          </div>
        </div>
      ))}
      {
        newAdminList.length==0 && <div className='w-100 text-center'> No se encontraron usuarios! </div>
      }

      <p className='dm-sans-regular newchat'>Lista de administradores</p>

      {EditAdminList.map((user, index) => (

        <div className='whitebox-message-al dm-sans-regular forcolormob' >
          <span className='head-message66'><span className='formob-msg'> Name</span>
          {user.first_name ? `${user.first_name} ${user.last_name}` : user.username}</span>

          <span className='head-message123'><span className='formob-msg'>Correo electrónico </span>{user.email}</span>
          <div className='head-message-btn'>
            <span className='formob-msg'>Acciones</span>
            <div to="permission" onClick={()=>handlePermissionClick(user,true)} >
              <img src="\images\admin\adminlist\Group 292213.svg" className='btnde'  /></div>
            <img src="\images\admin\adminlist\Group 290508.svg" onClick={()=>handleDelete(user.id)} className='btnde' />
          </div>


        </div>
      ))}
      {
        EditAdminList.length==0 && <div className='w-100 text-center'> No se encontraron usuarios! </div>
      }

      {isDeleteModalOpen ? <Delete onSubmit={() => deleteItem(selectedItemID)} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={loadData} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  )
}

export default AdminList