// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container{
    background-color: #ffffff;
    width: 100%;
   
    height: 100%;
      
}
`, "",{"version":3,"sources":["webpack://./src/user-panel/common-components/UserLayout.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;;IAEX,YAAY;;AAEhB","sourcesContent":[".app-container{\r\n    background-color: #ffffff;\r\n    width: 100%;\r\n   \r\n    height: 100%;\r\n      \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
