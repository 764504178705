import axios from "axios";

const API = axios.create({
  baseURL: "https://dj.santamariadelbosque.es",
  headers: {
    
    "Content-Type": "application/json"
    
  }
});

API.interceptors.request.use(
  config => {
    const isLoginAPI = config.url === '/api/login/';

    if (!isLoginAPI) {
      // Include the authentication token from the cookie
      //const authToken = Cookies.get('authToken');use this for more secure login. the token will be removed after page refresh
      const authToken = localStorage.getItem('accessToken')
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);



export default API; // Export the instance as the default export
