import React, { useContext, useEffect, useRef, useState } from 'react'
import "./Navbar.css"
import { HashLink as Linker } from 'react-router-hash-link';

import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../../authentication/pages/UserContext'
import { CloseButton } from 'react-bootstrap';

function Navbar() {




  const { tooglemenu, setTooglemenu, isFlipped, setIsFlipped, handleFlip } = useContext(UserContext)
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(0);


  const btnclosemenu = () => {
    setTooglemenu(false);
  };

  const btnclose = () => {
    setTooglemenu(false)
  }

  const [showoptionparaq, setShowoptionparaq] = useState(false);
  const optionshowparaq = () => {
    setShowoptionparaq(!showoptionparaq)

  }
  const [showoptioncat, setShowoptioncat] = useState(false);
  const optionshowcat = () => {
    setShowoptioncat(!showoptioncat)
  }
  const [showoptionlit, setShowoptionlit] = useState(false);
  const optionshowlit = () => {
    setShowoptionlit(!showoptionlit)
  }

  const [showoptiongrp, setShowoptiongrp] = useState(false);
  const optionshowgrp = () => {
    setShowoptiongrp(!showoptiongrp)
  }
  const [showoptionsocial, setShowoptionsocial] = useState(false);
  const optionshowsocial = () => {
    setShowoptionsocial(!showoptionsocial)
  }




  const handleChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    filterSuggestions(value);
  };

  const filterSuggestions = (value) => {
    const suggestions = [

      'Incinio',
      'Parroquia',
      'Pastoral Parroquial',
      'Sacerdotes Agustinos',
      'Equipo Parroquial',
      'Consejo Pastoral',
      'Consejo Economico',
      'Boletin Mensual',
      'Liturgia',
      'Catequesis',
      'Despacho',
      'Pastoral social',
      'Grupos',
      "Sacramentos", "Fiestas", "Oraciones", "Confrimation", 'Confirmation de adultos',
      'Grupo: Charlas formativas', 'Emaús Mujeres', 'Grupo de Jóvenes', 'Grupo de Liturgia',
      'Contacto', "Bulletins", "Gallery", "Events", "Masstime"
    ];

    const filtered = suggestions.filter((item) => item.toLowerCase().includes(value));
    setFilteredSuggestions(filtered);
    setActiveSuggestion(0);
  };


  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      // Up arrow key
      setActiveSuggestion((prevActive) => (prevActive === 0 ? filteredSuggestions.length - 1 : prevActive - 1));
    } else if (event.keyCode === 40) {
      // Down arrow key
      setActiveSuggestion((prevActive) => (prevActive === filteredSuggestions.length - 1 ? 0 : prevActive + 1));
    } else if (event.keyCode === 13) {
      // Enter key
      setSelectedSuggestion(filteredSuggestions[activeSuggestion]);
      setSearchQuery(filteredSuggestions[activeSuggestion]);
      setFilteredSuggestions([]);
    }
  };


  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setSearchQuery(suggestion);
    setFilteredSuggestions([]);

  };
  const getLinkForSuggestion = (suggestion) => {
    switch (suggestion.toLowerCase()) {

      case 'incinio':
        return '/home';
      case 'parroquia':
        return '/espiritualidad';
      case 'pastoral parroquial':
        return '/espiritualidad#pp';
      case 'sacerdotes agustinos':
        return '/espiritualidad#sa';
      case 'equipo parroquial':
        return '/espiritualidad#ep';
      case 'consejo pastoral':
        return '/espiritualidad#pc';
      case 'consejo economico':
        return '/espiritualidad#ce';
      case 'boletin mensual':
        return '/espiritualidad#bm';
      case 'liturgia':
        return '/liturgy#lit';
      case 'catequesis':
        return '/catechism#cat';
      case 'despacho':
        return '/despacho';
      case 'pastoral social':
        return '/accion';
      case 'grupos':
        return '/groupo#grp';
      case 'sacramentos':
        return 'liturgy#sac';
      case 'fiestas':
        return 'liturgy#euc';
      case 'oraciones':
        return 'liturgy#con';
      case 'confrimation':
        return '/catechism#conf';
      case 'confirmation de adultos':
        return '/catechism#conf-de';
      case 'grupo: charlas formativas':
        return '/groupo#gcf';
      case 'emaús mujeres':
        return '/groupo#em';
      case 'grupo de jóvenes':
        return '/groupo#gdj';
      case 'grupo de liturgia':
        return '/groupo#gdl';
      case 'contacto':
        return '/contacto';
      case 'events':
        return '/events';
      case 'masstime':
        return '/mass';
      case 'gallery':
        return '/gallery';
      case 'bulletins':
        return '/bulletin';
      default:
        return '/';
    }
  };








  return (
    <div className='navbar-body'>
      <div className='close ' onClick={btnclose}>

        <img src='\images\navbaruser\close-button 1.svg' className='closelogo' id='closeButton' onClick={btnclosemenu} />

      </div>
      <div className='searchbox'>
        <input className='searchbox-nav' placeholder="Search" value={searchQuery}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {searchQuery &&
          filteredSuggestions.map((suggestion, index) => (
            <Linker onClick={btnclose} className='search-linker' to={getLinkForSuggestion(suggestion)}>
              <div
                className={`suggestion ${index === activeSuggestion ? 'active' : ''}`}
                key={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <p className='sug-name'>{suggestion}</p>
              </div>
            </Linker>
          ))}

      </div>
      <div className='btn-nav'>
        <Link to="/home" className={`button-nav dm-sans-bold`} onClick={() => { handleFlip(); btnclose(); }}><span className="extend" onClick={btnclose}>Inicio </span>

        </Link>


      </div>
      <div className='btn-nav'>
        <Linker to="/espiritualidad#banner" className={`button-nav dm-sans-bold`} onClick={() => { handleFlip(); btnclose(); }}><span className="extend" onClick={btnclose}>Parroquia</span></Linker>

        {showoptionparaq ? (
          <img src='\images\navbaruser\toup.svg' className='todownlogo' onClick={() => { optionshowparaq(); }} />


        ) : (
          <img src='\images\navbaruser\todown.svg' className='todownlogo' onClick={optionshowparaq} />
        )}

      </div>
      {showoptionparaq ? (
        <div className='secondaryoption'>

          <Linker to='/espiritualidad#pp' className="marker " onClick={btnclose} ><p className=' dm-sans-regular suboption'>Pastoral Parroquial</p></Linker>
          <Linker to='/espiritualidad#sa' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Sacerdotes Agustinos</p></Linker>
          <Linker to='/espiritualidad#ep' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Equipo Parroquial</p></Linker>
          <Linker to='/espiritualidad#pc' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Consejo Pastoral</p></Linker>
          <Linker to='/espiritualidad#ce' className="marker " onClick={btnclose} >  <p className=' dm-sans-regular suboption'>Consejo Economico</p></Linker>
          <Linker to='/espiritualidad#bm' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Boletin Mensual</p></Linker>
        </div>
      ) : ("")}


      <div className='btn-nav'>
        <Linker to="/liturgy#lit" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Liturgia
        </Linker>

        {showoptionlit ? (
          <img src='\images\navbaruser\toup.svg' className='todownlogo' onClick={optionshowlit} />


        ) : (
          <img src='\images\navbaruser\todown.svg' className='todownlogo' onClick={optionshowlit} />
        )}
      </div>
      {showoptionlit ? (
        <div className='secondaryoption'>

          <Linker to='/liturgy#sac' className="marker" onClick={btnclose} ><p className=' dm-sans-regular suboption'>Sacramentos</p></Linker>
          <Linker to='/liturgy#euc' className="marker" onClick={btnclose} ><p className=' dm-sans-regular suboption'>Fiestas</p></Linker>
          <Linker to='/liturgy#con' className="marker" onClick={btnclose} ><p className=' dm-sans-regular suboption'>Oraciones</p></Linker>
        </div>
      ) : ("")}

      <div className='btn-nav'>
        <Linker to="/catechism#cat" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Catequesis</Linker>
        {showoptioncat ? (
          <img src='\images\navbaruser\toup.svg' className='todownlogo' onClick={optionshowcat} />


        ) : (
          <img src='\images\navbaruser\todown.svg' className='todownlogo' onClick={optionshowcat} />
        )}

      </div>
      {showoptioncat ? (
        <div className='secondaryoption'>

          <Linker to='/catechism#primera' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption' >Primera Comunión</p></Linker>
          <Linker to='/catechism#conf-jovenes' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption' >Confirmación de jóvenes</p></Linker>
          <Linker to='/catechism#conf-adultos' className="marker " onClick={btnclose} ><p className=' dm-sans-regular suboption'>Confirmación de adultos</p></Linker>
        </div>
      ) : ("")}
     
      <div className='btn-nav'>
        <Link to="/accion" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}> Pastoral social</Link>
        {showoptionsocial ? (
          <img src='\images\navbaruser\toup.svg' className='todownlogo' onClick={optionshowsocial} />

        ) : (
          <img src='\images\navbaruser\todown.svg' className='todownlogo' onClick={optionshowsocial} />
        )}
      </div>
      {showoptionsocial ? (
        <div className='secondaryoption'>

          <Linker to='/accion#caritas' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Caritas parroquial</p></Linker>
          <Linker to='/accion#costurero' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Costurero misionero</p></Linker>
          <Linker to='/accion#voluntariado' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Voluntariado misionero agustiniano</p></Linker>
        </div>
      ) : ("")}

      <div className='btn-nav'>
        <Linker to="/groupo#grp" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Oraciones y grupos</Linker>

        {showoptiongrp ? (
          <img src='\images\navbaruser\toup.svg' className='todownlogo' onClick={optionshowgrp} />

        ) : (
          <img src='\images\navbaruser\todown.svg' className='todownlogo' onClick={optionshowgrp} />
        )}
      </div>
      {showoptiongrp ? (
        <div className='secondaryoption'>

          <Linker to='/groupo#oracion' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Oración comunitaria</p></Linker>
          <Linker to='/groupo#ad-noct' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Adoración nocturna</p></Linker>
          <Linker to='/groupo#gcf' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Grupo: Charlas formativas</p></Linker>
          <Linker to='/groupo#gdl' className="marker " onClick={btnclose} ><p className=' dm-sans-regular suboption'>Grupo de Liturgia</p></Linker>
          <Linker to='/groupo#gdj' className="marker " onClick={btnclose} ><p className=' dm-sans-regular suboption'>Grupo de Jóvenes</p></Linker>
          <Linker to='/groupo#em' className="marker " onClick={btnclose} > <p className=' dm-sans-regular suboption'>Emaús Mujeres</p></Linker>
         
        </div>
      ) : ("")}





<div className='btn-nav'>
        <Link to="/bulletin" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Boletín</Link>
      </div>
      <div className='btn-nav'>
        <Link to="/mass" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}> Horario de misas</Link>
      </div>
      <div className='btn-nav'>

<Link to="/despacho" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Despacho</Link>
</div>




<div className='btn-nav'>
        <Link to="/events" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Eventos</Link>
      </div>


      <div className='btn-nav'>
        <Link to="/contacto" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Contacto</Link>
      </div>

      <div className='btn-nav'>
        <Link to="/gallery" className='button-nav dm-sans-bold' onClick={() => { btnclose(); handleFlip(); }}>Galería</Link>
      </div>



    </div>
  )
}

export default Navbar