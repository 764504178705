import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./Prayerpage.css"
import Header from '../common-components/Header'
import $ from "jquery"
import API from '../../API'
function Prayer() {

  const [data, setFormData] = useState({
    page_name: "st-rita-prayer",
    section_number: "1",
    title: "",
    description: "",

  })
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [pdfList, setPdfList] = useState([]);

  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true);
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };


  const fetchData = async () => {
    try {
      const response = await API.get(`websitecontent/?page_name=st-rita-prayer&section_number=1`);
      const eventData = response.data.results[0];
      console.log("this is success in fetching data from masstime");
      console.log("response", response);



      // Set the form data with the formatted date
      setFormData(eventData);
    } catch (error) {
      console.log("API error", error);
    }
  };
  useEffect(() => {
    loadPDFs('Oracion', 'Oracion A Santa Rita', setPdfList);

   
    fetchData();

  }, []);


  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);
  return (
    <div>
      <div className='image-head-bul'  >
        <Header />
        <span className='poppins-regular1 prayerhead'>{data.title}</span>
      </div>


      <div className='written-body merriweather-sans'>
        <p style={{ whiteSpace: "pre-line" }}>{data.description}
        </p>
      </div>

      {pdfList && pdfList.length > 0 && <div className='prayer-pdf-box'>
        <h3>Descargar PDF:</h3>
        {pdfList.map((pdf, index) => (
          <div key={index} className='btn-card3-esp dm-sans-bold '>
            <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
            <span onClick={() => downloadPDF(pdf)}>Descargar PDF</span>
          </div>
        ))}
      </div>
      }

      {data && data.link &&
        <div className='link-box'>
          <Link to={data.link}>{data.link}</Link>
        </div>
      }

    </div>

  )
}

export default Prayer