import React, { useEffect, useState } from 'react'
import "./Contacto.css"
import $ from 'jquery';
import Header from '../common-components/Header';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import ErrorPopup from '../../ErrorPopup';

function Contacto() {

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionSelectcnt = (option) => {
    setFormData((formData) => ({
      ...formData,
      subject: option
    }));
    setIsOpen(false)
  };


  const [wordCount, setWordCount] = useState(0);
  const maxWords = 30;


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "message") {
      const words = value.split(/\s+/).filter(word => word.length > 0);
      if (words.length <= maxWords) {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
        setWordCount(words.length);
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: words.slice(0, maxWords).join(' ')
        }));
        setWordCount(maxWords);
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }



  };


  const [errors, setErrors] = useState({});
  // ------- Validate ---------
  const validateForm = (data) => {
    let errors = {};

    if (!data.subject) {
      errors.subject = 'Por favor seleccione un tema';
    }
    if (!data.first_name) {
      errors.first_name = 'Por favor ingrese el nombre';
    } else if (data.first_name.length > 20) {
      errors.first_name = 'El nombre no debe tener más de 20 caracteres.';
    }

    if (!data.last_name) {
      errors.last_name = 'Por favor ingrese el apellido';
    } else if (data.last_name.length > 20) {
      errors.last_name = 'El apellido no debe tener más de 20 caracteres.';
    }

    if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Por favor ingresa una dirección de email válida';
    }
    if (!(data.message)) {
      errors.message = 'Por favor ingrese el mensaje';
    }

    return errors;
  };


  const handleSubmit = () => {
    const validationErrors = validateForm(formData);
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      API.post(`message/`, formData)
        .then((response) => {
          console.log('success');
          setIsLoading(false);
          setIsSuccessModalOpen(true);
          setMessage('Detalles enviados exitosamente.');
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            subject: "",
            message: "",
          })
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true);
          setMessage(error.message);
        });
    }
  }




  return (
    <div id='cntb'>
      <div className='img-banner-cont' style={{ backgroundImage: `url('images/contacto/Rectangle 810.png')` }}>
        <Header />
      </div>
      <div className='card-contact' >
        <div className='in-card' id='cnt'>
          <img src='\images\contacto\Group (1).svg' className='msgbox' />

          <p className='heading-ct great-vibes-regular  '>Conectar
            <p className='poppins-regular1'>Con nosotros</p> </p>

            <a href="tel:34913002949">
          <div className='subbody2-div'>
            <img src='images/contacto/Frame 36409.svg' className='logo-cont' />
            <span className='dm-sans-regular b4'>Despacho parroquial (34) 913 002 949<p className='pp'>Despacho cáritas (34)913 002 154</p> </span>
          </div>
</a>
<a href="mailto:stamariadelbosque@gmail.com">
          <div className='subbody3-div'>
            <img src='images/contacto/Frame 36407.svg' className='logo-cont' />
            <span className='dm-sans-regular b4'>Correo: stamariadelbosque@gmail.com</span>
          </div>
          </a>

          <a href='https://www.google.com/maps/place/C.+de+Manuel+Uribe,+1,+Cdad.+Lineal,+28033+Madrid,+Spain/@40.4645938,-3.6672539,17z/data=!3m1!4b1!4m6!3m5!1s0xd42292d7921da83:0xe720ff2962633f8e!8m2!3d40.4645897!4d-3.664679!16s%2Fg%2F11c2f8n7cv?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D'>
          <div className='subbody-div'>
            <img src='images/contacto/Frame 36408 (1).svg' className='logo-cont' />
            <span className='dm-sans-regular b4'>C. de Manuel Uribe, 1, Cdad. Lineal, 28033 Madrid, Spain</span>
          </div>
          </a>



          {/* <div className='subbody3-div hidemob-cont'>
            <img src='images/contacto/Group 783.svg' className='logo-cont' />

           <span className='dm-sans-regular b4'>Para recibir información y boletín parroquial</span>
          </div> */}

          <div className='subbody4-div '>
            <div className='off-hrs'>
              <img src='images/contacto/Frame 36411.svg' className='logo-cont' />
              <span className='dm-sans-regular b4'>HORAS DE OFICINA</span>
              <div className='in-off-div'>

                <div className='li-count' >
                  <img src='images/contacto/Rectangle 611.svg' className='bc-topimg' />

                  <div className='dm-sans-regular b4 li-cont '><div className='bc'><img src='images/contacto/Ellipse 18.svg' className='bc-img' /><span className='day'>Lunes-Viernes</span> </div>  <span className='timedate'>9:00 AM-5:00 PM </span></div>
                  <div className='dm-sans-regular b4 li-cont'><div className='bc'><img src='images/contacto/Ellipse 18.svg' className='bc-img' /><span className='day'>Sábado</span> </div>  <span className='timedate'>         10:00 AM-2:00 PM</span></div>
                  <div className='dm-sans-regular b4 li-cont'><div className='bc'><img src='images/contacto/Ellipse 18.svg' className='bc-img' /><span className='day'>domingo cerrado</span> </div></div>
                </div>

              </div>
            </div>
          </div>
        </div>



        <div className='form-cont'>

          <div className='poppins-regular1 form-head'>Enviar un mensaje</div>
          <div className='name-field'>
            <div className='subbody-form'>
              <span className='sub-head-cont dm-sans-regular'>Nombre</span>
              <input type='text' className='inputbox' name='first_name' onChange={handleInputChange} value={formData.first_name} />
              {errors.first_name && <p className="invalid-feedback">{errors.first_name}</p>}
            </div>
            <div className='subbody-form'>
              <span className='sub-head-cont dm-sans-regular'>Apellido</span>
              <input type='text' className='inputbox' name='last_name' onChange={handleInputChange} value={formData.last_name} />
              {errors.last_name && <p className="invalid-feedback">{errors.last_name}</p>}
            </div>
          </div>

          <div className='subbody-form'>
            <span className='sub-head-cont dm-sans-regular'>
              Ingresa tu email</span>
            <input type='text' className='inputbox' name='email' onChange={handleInputChange} value={formData.email} />
            {errors.email && <p className="invalid-feedback">{errors.email}</p>}
          </div>
          <div className='dropdownbox' onClick={() => setIsOpen(!isOpen)}>
            <span className='sub-head-cont dm-sans-regular'>Sujeto</span>
            <input type='text' className='inputboxdrop dm-sans-regular'
              value={formData.subject === 'Prayer Request' ? 'Request Prayer' : formData.subject} readOnly />
            <img src="\images\contacto\Chevron_Down.svg" className='ardown-cnt' />
            <div class="dropdown-content dm-sans-regular" style={{ visibility: isOpen ? 'visible' : 'hidden' }}>
              <p onClick={() => handleOptionSelectcnt('Solicitar oración')} className='dm-sans-regular'>Solicitar oración</p>
              <p onClick={() => handleOptionSelectcnt('Pedir ayuda')} className='dm-sans-regular'>Pedir ayuda</p>
            </div>
            {errors.subject && <p className="invalid-feedback">{errors.subject}</p>}
          </div>

          <div className='subbody-form'>
            <span className='sub-head-cont dm-sans-regular'>Mensaje</span>
            <textarea type='textarea' className='inputbox2' name='message' onChange={handleInputChange} value={formData.message}></textarea>
            {/* <div>El recuento de palabras: {wordCount}/{maxWords}</div> */}
            {errors.message && <p className="invalid-feedback">{errors.message}</p>}
          </div>
          <button className='btn-form dm-sans-regular' onClick={handleSubmit} >Enviar mensaje</button>

        </div>



      </div>




      <div className='mapcont'>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2153.8679600656606!2d-3.6667834353005415!3d40.464395445573146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42292d7980e271%3A0x5fb5494b77322784!2sParroquia%20Santa%20Mar%C3%ADa%20del%20Bosque!5e0!3m2!1sen!2sin!4v1713965082984!5m2!1sen!2sin"
          className='map'

          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>





      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => setIsSuccessModalOpen(false)} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />

    </div>
  )
}

export default Contacto