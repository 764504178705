import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../LoadingSpinner';
import { imageUrls } from "../constants/GalleryImages";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import './Gallery.css'
import Header from '../common-components/Header';
import $ from "jquery"
import API from '../../API';

const Gallery = () => {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);


  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ImageList, setImageList] = useState([]);

  const ImageList2 = imageUrls
  const columnCountBreakPoints = { 350: 1, 750: 1, 900: 3 }

  const convertImagesToUrls = (imageList) => {
    return imageList.map(item => item.image);
  };

  // IMAGE POPUP
  const [popupImage, setPopupImage] = useState(false);

  // Load Images
  const loadData = () => {
    API.get(`/gallery/?page_size=1000&page=1`)
      .then(response => {
        setImageList(convertImagesToUrls(response.data.results));
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
      });
  }

  //get API
  useEffect(() => {
    loadData();
  }, []);


  const handleImageClick = (url) => {
    console.log(url)
    setPopupImage(true);
    setSelectedImageUrl(url);
  };

  const handleClosePopup = () => {
    setPopupImage(false);
  };

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);



  return (
    <div className='gallery-page'>
      <div className='banner-acc'>
        <Header />
        <div className='banner-title'>
          <span>
            Galería
          </span>
        </div>
      </div>
      <div className='gallery-container'>
        <div className='row gallery-imgs'>
          <ResponsiveMasonry columnsCountBreakPoints={columnCountBreakPoints}>
            <Masonry gutter='20px'>
              {(ImageList && ImageList.length > 0 ? ImageList : []).map((image, i) => (
                <img
                  key={i}
                  onClick={() => handleImageClick(image)}
                  src={image}
                  style={{ width: '100%', display: 'block' }}
                  alt={`Image ${i}`}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>

      <div className={popupImage ? "img-popup show " : "img-popup"}>
        <img src={selectedImageUrl} />

        <svg onClick={handleClosePopup} class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </div>

      {isLoading && <LoadingSpinner />}
    </div>
  )
}

export default Gallery