import React, { useEffect, useState } from "react";
import "./Workdays.css";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../API";
import LoadingSpinner from "../../LoadingSpinner";
import ErrorPopup from "../../ErrorPopup";

function Eucaristic() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);
  
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    data: { "Monday to Saturday": [], Sunday: [] },
  });

  const [originalData, setOriginalData] = useState({
    "Monday to Saturday": [],
    Sunday: [],
  });

  const location = useLocation();
  const { title, data } = location.state || {
    title: "",
    data: { "Monday to Saturday": [], Sunday: [] },
  };

  useEffect(() => {
    setFormData({ title, data });
    setOriginalData(JSON.parse(JSON.stringify(data))); // Store a deep copy of the original data
  }, [title, data]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setAllMassTimesToNull("Monday to Saturday");
    } else {
      restoreOriginalMassTimes("Monday to Saturday");
    }
  };

  const handleToggle3 = () => {
    setIsChecked3(!isChecked3);
    if (!isChecked3) {
      setAllMassTimesToNull("Sunday");
    } else {
      restoreOriginalMassTimes("Sunday");
    }
  };

  const setAllMassTimesToNull = (dayType) => {
    const newData = { ...formData };
    newData.data[dayType] = newData.data[dayType].map((mass) => ({
      ...mass,
      start_time: null,
    }));
    setFormData(newData);
  };

  const restoreOriginalMassTimes = (dayType) => {
    const newData = { ...formData };
    newData.data[dayType] = JSON.parse(JSON.stringify(originalData[dayType])); // Restore the original data
    setFormData(newData);
  };

  const transformDataForAPI = (formData) => {
    const mondayToSaturdayMasses = formData.data["Monday to Saturday"].map((mass) => ({
      id: mass.id,
      mass_type: mass.mass_type,
      mass_date: mass.mass_date,
      location: mass.location,
      start_time: mass.start_time === "" ? null : mass.start_time,
      end_time: mass.end_time === "" ? null : mass.end_time,
      description: mass.description,
      repeatation_day_type: mass.repeatation_day_type,
      day_shift: mass.day_shift,
    }));

    const sundayMasses = formData.data.Sunday.map((mass) => ({
      id: mass.id,
      mass_type: mass.mass_type,
      mass_date: mass.mass_date,
      location: mass.location,
      start_time: mass.start_time === "" ? null : mass.start_time,
      end_time: mass.end_time === "" ? null : mass.end_time,
      description: mass.description,
      repeatation_day_type: mass.repeatation_day_type,
      day_shift: mass.day_shift,
    }));

    return [...mondayToSaturdayMasses, ...sundayMasses];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const transformedData = transformDataForAPI(formData);
      console.log(transformedData, "Transformed data for API");

      await API.put(`mass/bulk_update/`, transformedData);
      navigate("/admin/masstime");
      setIsLoading(false);
      console.log("Form data updated successfully!");
    } catch (error) {
      setIsLoading(false);
      setMessage(error.message);
      setIsErrorModalOpen(true);
      console.error("Error updating form data:", error);
    }
  };

  return (
    <div className="createpage">
      <div className="headerofevent2">
        <div className="titlebox2-ad dm-sans-regular">
          <span>{formData.title}</span>
        </div>
      </div>

      <form className="craeteform dm-sans-bold" onSubmit={handleSubmit}>
        <p className="titlebox-wd dm-sans-bold">Monday to Saturday</p>
        <p className="subtext2-wd dm-sans-bold">Tiempo</p>
        <div className="time-inp-box">
          {formData.data["Monday to Saturday"].map((mass, index) => (
            <div className="time-wd-box" key={index}>
              <input
                id={`monday-morning-${index}`}
                name={`monday-morning-${index}`}
                type="time"
                className="time-wd"
                value={mass.start_time || ''}
                onChange={(e) => {
                  const newData = { ...formData };
                  newData.data["Monday to Saturday"][index].start_time = e.target.value;
                  setFormData(newData);
                }}
              />
              <img
                className="timelogo-oh"
                src="/images/admin/Masstime/Alarm.svg"
                alt="Alarm Icon"
              />
            </div>
          ))}
        </div>
        <div className="rdo-wd-box" onClick={handleToggle}>
          <input type="radio" className="rdo-wd" checked={isChecked} readOnly />
          <label className="textof-rdo">Sin Hora de Misa</label>
        </div>

        <p className="subtext-wd dm-sans-bold">Sunday</p>
        <p className="subtext2-wd dm-sans-bold">Tiempo</p>
        <div className="time-inp-box">
          {formData.data.Sunday.map((mass, index) => (
            <div className="time-wd-box" key={index}>
              <input
                id={`sunday-morning-${index}`}
                name={`sunday-morning-${index}`}
                type="time"
                className="time-wd"
                value={mass.start_time || ''}
                onChange={(e) => {
                  const newData = { ...formData };
                  newData.data.Sunday[index].start_time = e.target.value;
                  setFormData(newData);
                }}
              />
              <img
                className="timelogo-oh"
                src="/images/admin/Masstime/Alarm.svg"
                alt="Alarm Icon"
              />
            </div>
          ))}
        </div>
        <div className="rdo-wd-box" onClick={handleToggle3}>
          <input type="radio" className="rdo-wd" checked={isChecked3} readOnly />
          <label className="textof-rdo">Sin Hora de Misa</label>
        </div>

        <div className="btns-ad">
          <Link to="/admin/masstime" className="trans-btn">
            Descartar
          </Link>
          <button className="solid-btn">Actualizar</button>
        </div>
      </form>

      {isLoading && <LoadingSpinner />}
      <ErrorPopup
        message={message}
        state={isErrorModalOpen}
        setterFunction={setIsErrorModalOpen}
        okClickedFunction={() => {
          window.location.reload();
        }}
      />
    </div>
  );
}

export default Eucaristic;
