import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import { data } from 'jquery';


function HomeSection3() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState([
    {
      
      id:"",
      title: '',
      description: '',
    },
    {
      id:"",
      title: '',
      description: '',
    },
    {
      id:"",
      title: '',
      description: '',
    },
  ]
);


const loadData = () => {
  API.get(`/websitecontent/?page_name=home&section_number=3`)
    .then(response => {
      if (response.data && response.data.results) {
        setFormData(response.data.results.sort((a, b) => a.id - b.id));

      } else {
        console.log('No result field in API response.');
        setMessage("No data to show");
        setIsErrorModalOpen(true);
      }
    })
    .catch(error => {
      console.log(error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
    });
}
useEffect(() => {
  loadData();
}, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
   let data=[...formData]
   
   if(name=="title1"){
    data[0].title=value
  }
  
  else if(name=="description1"){
    data[0].description=value
  }


  else if(name=="title2"){
    data[1].title=value
  }


  else if(name=="description2"){
    data[1].description=value
  }


  else if(name=="title3"){
    data[2].title=value
  }


  else if(name=="description3"){
    data[2].description=value
  }


    setFormData(data);
  };
 


 

  const validateForm = (data) => {
    const errors = {};
    if (!data[0].title.trim()) {
      errors.title1 = "El título es obligatorio.";
    }
    if (!data[0].description.trim()) {
      errors.description1 = "El contenido es obligatorio.";
    }
    if (!data[1].title.trim()) {
      errors.title2 = "El título es obligatorio.";
    }
    if (!data[1].description.trim()) {
      errors.description2 = "El contenido es obligatorio.";
    }
    if (!data[2].title.trim()) {
      errors.title3 = "El título es obligatorio.";
    }
    if (!data[2].description.trim()) {
      errors.description3 = "El contenido es obligatorio.";
    }
    

    return errors;
  };

  // Crear boletín Data
  const handleSubmit = (e) => {
 
    e.preventDefault();
    // Ensure programs are updated in formData before validating
   
    // Validate form data
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
     
     // Use Promise.all to send all PUT requests simultaneously
  Promise.all(
    formData.map((data, index) =>
      API.put(`websitecontent/${data.id}/`, data)
        .then((response) => {
          console.log(`Object ${index + 1} updated successfully`);
        })
        .catch((error) => {
          console.error(`Error updating object ${index + 1}:`, error);
          setIsErrorModalOpen(true);
          setMessage(error.message);
        })
    )
  )
    .then(() => {
      setIsLoading(false);
     setMessage("Datos actualizados exitosamente!")
     setIsSuccessModalOpen(true)
    })
    .catch((error) => {
      setIsLoading(false);
      console.error('Error completing all requests:', error);
      setIsErrorModalOpen(true);
      setMessage(error.message);
    });

    }
  };







  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Inicio sección 3</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>
        
      <div className='title-cb'>
          <label className='lbl-cb'>Título 1</label>
          <input type='text' className='title-inp-cb' name='title1' value={formData[0].title} onChange={handleInputChange}   maxLength={25}/>
          {errors.title1 && <p className='invalid-feedback'>{errors.title1}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Descripción 1</label>
          <textarea type='text' className='title-inp-cb2' name='description1' value={formData[0].description} onChange={handleInputChange} maxLength={100}/>
          {errors.description1 && <p className='invalid-feedback'>{errors.description1}</p>}
        </div>

        
        <div className='title-cb'>
          <label className='lbl-cb'>Título 2</label>
          <input type='text' className='title-inp-cb' name='title2' value={formData[1].title} onChange={handleInputChange}   maxLength={25}/>
          {errors.title2 && <p className='invalid-feedback'>{errors.title2}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Descripción 2</label>
          <textarea type='text' className='title-inp-cb2' name='description2' value={formData[1].description} onChange={handleInputChange}  maxLength={100}/>
          {errors.description2 && <p className='invalid-feedback'>{errors.description2}</p>}
        </div>

        
        <div className='title-cb'>
          <label className='lbl-cb'>Título 3</label>
          <input type='text' className='title-inp-cb' name='title3' value={formData[2].title} onChange={handleInputChange}  maxLength={25}/>
          {errors.title3 && <p className='invalid-feedback'>{errors.title3}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Descripción 3</label>
          <textarea type='text' className='title-inp-cb2' name='description3' value={formData[2].description} onChange={handleInputChange}  maxLength={100}/>
          {errors.description3 && <p className='invalid-feedback'>{errors.description3}</p>}
        </div>

        
     

        <div className='btn-cb d-none'>
          <button className='btn-save-cb'>Guardar</button>
        </div>

        <div className='hrdashed' />

    
        <div className='btn-cb-savedis'>
          <Link to="/admin/boletines-generales" className="discard-cb">
            Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Guardar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default HomeSection3;
