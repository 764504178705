import React, {useState} from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
import { HashLink as Linker } from 'react-router-hash-link'
import API from '../../API'
import LoadingSpinner from '../../LoadingSpinner'
import PositiveModal from '../../PositiveModal'
import ErrorPopup from '../../ErrorPopup'
import {sanitizeForSql} from '../../General functions'
function FooterSaintMaria() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ------- Validate ---------
  const validateForm = (data) => {
    let errors = {};

    if(sanitizeForSql(data.email)){
      errors.email = 'email contains invalid characters';
    }
    if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Please enter a valid email address';
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm(formData);
    setErrors(validationErrors)
    console.log(formData);
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      API.post(`subscriber/`, formData)
        .then((response) => {
          console.log('success',response.data);
          setIsLoading(false);
          setIsSuccessModalOpen(true);
          setMessage('Details submitted successfully.');
          setFormData({email:""});
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true);
          setMessage(error.message);
        });
    }
  }

  return (
    <div className='main-footer'>
      <div className='logos-box-footer'>
        <a href='https://www.conferenciaepiscopal.es/'>
          <img src='images\footer\Group 1092.svg' className='eachlogo-footer' /></a>


        <a href='https://www.augustinianorder.org/'>
          <img src='images\footer\Group 838.svg' className='eachlogo-footer' />
        </a>


        <a href='https://agustinos.es/'>
          <img src='images\footer\Group 839.svg' className='eachlogo-footer' />
        </a>

        <img src='images\footer\Group 840.svg' className='eachlogo-footer' />

        <a href='https://www.archimadrid.org/index.php'>
          <img src="\images\footer\Group 841.svg" className='eachlogo-footer' />
        </a>

        <a href='https://www.caritas.es/'>
          <img src='images\footer\Group 837.svg' className='eachlogo-footer' />
        </a>

      </div>

      <div className='lowerbody-footer'>
        <div className='logo-box-footer'>
          <img src='images\footer\saintlogo.svg' className='saintlogo' />
        </div>

        <div className='link-box-footer'>
          <div className='linkcenter'>


            <p className='poppins-medium whitebold'>Explorar</p>
            <div className='mainlinkbox'>

              <div className='linkbox1'>
                <Link to="home" className='link'>Inicio</Link>
                <Linker to="espiritualidad#banner" className='link'>Parroquia</Linker>
                <Linker to="bulletin" className='link'>Boletín</Linker>
                <Linker to="mass" className='link'> Horario de misas</Linker>
                
               
                <Linker to="liturgy#lit" className='link'>Liturgia</Linker>
                <Linker to="catechism#cat" className='link'>Catequesis</Linker>
               
              </div>
              <div className='linkbox1'>
             

                <Link to="despacho" className='link'>Despacho</Link>
                <Linker to="accion#accionsocial321" className='link'>Acción Social</Linker>
                <Linker to="groupo#grp" className='link'>Grupos</Linker>
                <Linker to="events" className='link'>Eventos</Linker>
                <Linker to="contacto#cntb" className='link'>Contacto</Linker>
                <Linker to="gallery" className='link'>Galería</Linker>
               


              </div>

            </div>


          </div>
        </div>


        <div className='search-box-footer'>
          <div className='searchcenter'>


            <span className='poppins-medium whitebold'>Mantente informado</span>
            <span className='dm-sans-text whitetext'>
            Suscríbase a nuestra lista de correo</span>
            <div className='search-for-fix'>
              <input type='text' className='searchbox-footer' placeholder='Ingresa tu email'
                onChange={handleInputChange}  name='email' value={formData.email}/>
              <div className='yellowsearch' onClick={handleSubmit}>
                <img src='images\footer\Group.svg' className='img-arrow-footer' />
              </div>
              {errors.email && <p className="invalid-feedback">{errors.email}</p>}
            </div>
            <div className='social-grp-img'>
            <a href='https://www.instagram.com/parroquia_el_bosque?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' className='social-img'> <img src="\images\footer\Instagram 1.svg" className='watsp'/><p className='int'>Instagram</p></a>
            <a href='https://wa.me/+34658834183' className='social-img'> <img src="\images\footer\Whatsapp 1.svg" className='watsp'/><p className='int'>WhatsApp</p></a>
            </div>
          </div>



        </div>


      </div>
      <div className='lowest-part'>
        <div className='mobhide20'>
          <span className='whitelower dm-sans-text'>
            © 2024 | Parroquia Santa María del Bosque
          </span>

          <span className='whitelower dm-sans-text'>
          Diseñada por Zog Global
            <img src='images\footer\Vector.svg' className='img-upward-footer' onClick={scrollToTop} />
          </span>
        </div>
        <div className='mobview20'>
          <span className='whitelower dm-sans-text width bold'>
            © 2024 | Parroquia Santa María del Bosque
          </span>
          <span className='whitelower dm-sans-text yellow'>
          Diseñada por Zog Global

          </span>

        </div>


      </div>


      {isLoading && <LoadingSpinner /> }
      {/* {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => setIsSuccessModalOpen(false) } />} */}
      {/* <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false) } /> */}


    </div>
  )
}
export default FooterSaintMaria