import React, { useEffect, useState } from 'react'

import "./Eventtest.scss"
import $ from 'jquery';
import Header from '../common-components/Header';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import API from '../../API';
import { Upcoming } from '@mui/icons-material';
import PositiveModel from '../../PositiveModel';


function Events() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  // Utility function to convert time to 12-hour format
  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [workDaysList, setWorkDaysList] = useState([]);
  const [saturdaysList, setSaturdaysList] = useState([]);
  const [morningMassTime, setMorningMassTime] = useState('');
  const [afternoonMassTime, setAfternoonMassTime] = useState('');
  const [regularMassTime, setRegularMassTime] = useState('');

  const [isEmpty, setIsEmpty] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await API.get(`events/?search_key=${"today"}`);
        console.log("card API success", response);
        const formattedEvents = response.data.results.map(event => ({
          ...event,
          start_time: convertTo12Hour(event.start_time),
          end_time: convertTo12Hour(event.end_time),
        }));
        setEvents(formattedEvents);
        setIsEmpty(true);

      } catch (error) {
        console.log("API error", error);
        setIsEmpty(false);
      }
    };

    fetchEvents(); // Call the fetchEvents function
  }, []);



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 3,
    slidesToScroll: 3,

    centerMode: true,
    centerPadding: '30px',
    responsive: [
      {
        breakpoint: 1033,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 768,
        settings: {

          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,

          centerMode: true,
          centerPadding: '0px',
        },

      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnHover: true,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
        },

      },
    ],
  };


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  const [isScrollingUp1, setIsScrollingUp1] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrollingUp1(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);
  // console.log(isScrollingUp1)

  // Load data from backend
  const loadMassTimes = (mass_type, day_type, setList) => {
    setIsLoading(true);
    let queryParams = [];
    if (day_type) queryParams.push(`day_type=${day_type}`);
    if (mass_type) queryParams.push(`mass_type=${mass_type}`);
    let apiUrl = `mass/?${queryParams.join('&')}`;
    API.get(`${apiUrl}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };
  const loadData = () => {
    loadMassTimes(null, 'mon-fri', setWorkDaysList);
    loadMassTimes(null, 'saturday', setSaturdaysList);
    loadMassTimes("regular", null, setRegularMassTime);
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    // Function to get today's mass times based on current day
    const getTodaysMassTimes = () => {
      const currentDay = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)

      if (currentDay >= 1 && currentDay <= 5) {
        // Weekdays (Monday to Friday)
        const todayMasses = workDaysList.filter(item => item.repeatation_day_type === 'mon-fri');
        return todayMasses;
      } else if (currentDay === 6) {
        // Saturday
        const todayMasses = saturdaysList.filter(item => item.repeatation_day_type === 'saturday');
        return todayMasses;
      } else {
        // Handle Sunday or other cases if needed
        return [];
      }
    };

    // Get today's mass times
    const todaysMassTimes = getTodaysMassTimes();
    // Set morning and afternoon mass times
    const morningTime = todaysMassTimes.filter(item => item.day_shift === 'morning');
    const morningTimeStrings = morningTime.filter(time => time.start_time !== null).map(time => convertTo12Hour(time.start_time));
    const joinedMorningTime = morningTimeStrings.join(' - ');
    const afternoonTime = todaysMassTimes.filter(item => item.day_shift === 'afternoon');
    const afternoonTimeStrings = afternoonTime.filter(time => time.start_time !== null).map(time => convertTo12Hour(time.start_time));
    const joinedafternoonTime = afternoonTimeStrings.join(' - ');
    setMorningMassTime(morningTime ? joinedMorningTime : '');
    setAfternoonMassTime(afternoonTime ? joinedafternoonTime : '');
  }, [workDaysList, saturdaysList]);









  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };



  const dayTranslations = {
    monday: 'Lunes',
    teusday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo'
  };

  // Filter Shift Times (work days)
  const filterByDaytype = (dayType, massData) => {
    return massData
      .filter((mass) => mass.repeatation_day_type === dayType && mass.start_time)
      .sort((a, b) => a.start_time.localeCompare(b.start_time)) // sort by start_time

  };

  return (
    <div>
      <div className='banner-evt' style={{ backgroundImage: `url("/images/espnew-edit/Events -B.jpg")` }}>
        <Header />
        <p className=' headingtext-evt'><span className='great-vibes-regular'>Horario de
        </span> <span className='poppins-regular1'>misas</span></p>
      </div>

      <div className='body-evt'>

        {/* <p className=' headingtext-evt'><span className='great-vibes-regular'>Evento
        </span> <span className='poppins-regular1'>hoy</span></p>
        <p className='dm-sans-regular body-text-evt'>Aquí se puede ver el horario de misas y celebraciones litúrgicas de los próximos eventos</p>
         */}
        <div className='whitebody-evt mt-5'>
          {/* <p className=' headingtext-evt'><span className='great-vibes-regular'>Horario de  </span> <span className='poppins-regular1'>misas </span></p> */}

          <div className='time-evt'>
            <div className='indiv-time'><span className='text'>LUNES:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("monday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>MARTES:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("teusday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>MIÉRCOLES:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("wednesday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>JUEVES:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("thursday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>VIERNES:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("friday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>SÁBADO:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("saturday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>

            <div className='indiv-time'><span className='text'>DOMINGO:</span> {regularMassTime && regularMassTime.length > 0 &&
              filterByDaytype("sunday", regularMassTime).map((item, index) =>
              (<span className='time1-evt'>{item.start_time}</span>
              ))}
            </div>
          </div>



          <Link className='btn-evt' to="/accion">Hora de Misa-Mañana</Link>
          <img src="\images\events\blesshand.svg" className='blesshand' />
        </div>

        {/* <p className=' headingtext-evt poppins-regular1'>Actividades</p>
        <div className='card-main-body-evt'>



          {events.length === 0 ? (
            <div className='evntno poppins-regular1'>¡No hay ningún evento hoy!</div>
          ) : (
            events.map((data, index) => (
              <div className='card-evt' key={index}>
                <div className='img-card-acc'>
                  <img src='/images/socialaccion/Event -Bible.svg' className='booklog' />
                </div>
                <div className='cardhead-acc dm-sans-bold '>{data.title}</div>

                <div className='cardbody-acc'>
                  <div className='cardline-acc'>
                    <span className='sp2'>Fecha</span>
                    <span className='sp3'>{data.date}</span>
                  </div>

                  <div className='cardline-acc'>
                    <span className='sp2'>Tiempo</span>
                    <span className='sp3'>{data.start_time} - {data.end_time}</span>
                  </div>
                  <div className='cardline-acc'>
                    <span className='sp2'>Ubicación</span>
                    <span className='sp3'>{data.location}</span>
                  </div>
                  <div className='btn4-home dm-sans-bold' style={{ marginTop: "30px" }} onClick={() => openModal(data)}> SABER MÁS</div>
                </div>
              </div>
            ))
          )}
        </div> */}




      </div>


      <div className='banner-evt2' style={{ backgroundImage: `url('/images/events/crowd.JPG' )` }}>
        <p className='poppins-regular1 text2-evt2'>¡Bendiciones por ser parte de nuestra comunidad de fe!</p>
      </div>



      {isModalOpen && selectedEvent && (
        <PositiveModel
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedEvent.name}
          content={selectedEvent.description}
        />
      )}


    </div>
  )
}

export default Events