import React, { useContext, useState, useEffect } from 'react'
import "./Message.css"
import { Link } from 'react-router-dom'
import Messagepopup from '../common-components/Messagepopup'
import { UserContext } from '../../authentication/pages/UserContext'
import { DateRangePicker } from 'react-date-range'
import API from '../../API'
import LoadingSpinner from '../../LoadingSpinner'
import PositiveModal from '../../PositiveModal.js'
import ErrorModal from '../../ErrorModal.js'


function Message() {

  const [messagePopup, setMessagePopup] = useState(false);
  const [messageViewPopup, setMessageViewPopup] = useState(false);
  const [messageSelected, setMessageSelected] = useState(false);
  const [isToReply, setIsToReply] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const [data, setData] = useState(null);
  const [showfilter, setShowfilter] = useState(false)
  const [filters, setFilters] = useState({
    subject: '',
    from_date: null,
    to_date: null,
    search_key: null,
  });


  const [showcal, setShowcal] = useState(false);
  const handleclickdate = () => {
    setShowcal((prev) => !prev)
  }

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'

  })

  const formatDate = (dateObject) => {
    // Extract the year, month, and day
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(dateObject.getDate()).padStart(2, '0');
    // Format the date to yyyy-mm-dd
    return `${year}-${month}-${day}`;
  }

  // Date Change
  const handleChange = (ranges) => {
    setDate(ranges.selection);
    setFilters((filters) => ({
      ...filters,
      from_date: formatDate(ranges.selection.startDate),
      to_date: formatDate(ranges.selection.endDate),
    }));
  }

  // Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handlesubjectChange = (option) => {
    setFilters((filters) => ({
      ...filters,
      subject: option
    }));
    setShowfilter(false);
  }

  const fltshow = () => {
    setShowfilter(!showfilter)
  }

  // API INTGRATION
  useEffect(() => {
    loadMessageData();
  }, [filters, messagePopup]);

  const loadMessageData = () => {
    let apiUrl = `/message/?page=${1}`;
    setIsLoading(true);
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    console.log(apiUrl);

    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
      setIsLoading(false);
  }

  // Handle Reply Message
  const handleMessagePopup = (item) => {
    setMessageSelected(item);
    setMessagePopup(true);
  }
  // handle View message
  const handleMessageView = (item) => {
    console.log(messageViewPopup);
    setMessageSelected(item);
    setMessageViewPopup(true);
  }




  return (
    <div className='messagepart'>
      <div className='headerofevent44'>

        <div className='titlebox-ad dm-sans-regular'><span>Mensajes</span></div>


      </div>

      <div className='search-and-btn'>
        <div className='search-message-box'>
          <input type='text' className='search-message' placeholder='Buscar por nombres' name='search_key' onChange={handleInputChange} />
          <img src="\images\admin\message\Shape.svg" className='srlogo' />
        </div>
        <div className='clnderandfilter'>
          <div className='clnder' onClick={handleclickdate}>
            <img src='\images\admin\message\Calendar.svg' />
          </div>
          <div className='filterbox-ad'>
            {showfilter ?
              <div className='fltr'>
                <p onClick={() => handlesubjectChange(null)} className='filteropt'>Todos</p>
                <p onClick={() => handlesubjectChange('Solicitar oración')} className='filteropt'>Solicitar oración</p>
                <p onClick={() => handlesubjectChange('Pedir ayuda')} className='filteropt'>Pedir ayuda</p>

              </div>
              : ""}
            <div className='sortby dm-sans-regular ' onClick={fltshow}>

              <span className='dm-sans-regular create '>Filtrar :</span>
              <div className='fl1'>{filters.subject ? filters.subject : 'Todos'}</div>
              <div><img src='\images\admin\eventad\Shape.svg' className='crtdown' /></div>
            </div>

          </div>
        </div>
      </div>
      {showcal &&

        <div className='datecal'>
          <DateRangePicker
            ranges={[date]}
            onChange={handleChange}

          >

          </DateRangePicker>
          <p className='btn-apply'>
            <span onClick={handleclickdate} className='applybtn'>Apply</span>
          </p>
        </div>
      }


      <div className='whitebox-message dm-sans-bold dontshow'>
        <span className='head-message'>Fecha</span>
        <span className='head-message'>Nombre</span>
        <span className='headmail-message'>Correo electrónico</span>
        <span className='head-message'>Asunto</span>
        <span className='head-message'>Mensaje</span>
        <span className='head-message'>Acciones</span>

      </div>
      <p className='dm-sans-regular newchat'>Nuevo chat</p>


      {data && data.results.length > 0 && data.results.map(item => (
        !item.reply &&
        <div className='whitebox-message dm-sans-regular'>

          <span className=' date-mail'><span className='formob-msg'>Fecha</span>{item.created_at}</span>
          <span className=' date-mail'><span className='formob-msg'>Nombre</span>{item.first_name} {item.last_name}</span>


          <span className='headmail-message '> <span className='formob-msg'>Correo electrónico</span>{item.email}</span>
          <span className='head-message'><span className='formob-msg'>Asunto</span>{item.subject}</span>
          <span className='head-message click' onClick={() => { handleMessageView(item) }}><span className='formob-msg'>Mensaje</span>Ver</span>
          <span className='head-message' onClick={() => { handleMessagePopup(item); setIsToReply(true) }}><span className='formob-msg'>Acciones</span>
            <button className='sendreply' onClick={() => { handleMessagePopup(item); setIsToReply(true) }}>Enviar Respuesta</button></span>
        </div>


      ))}


      <p className='dm-sans-regular newchat'>Chat anterior</p>


      {data && data.results.length > 0 && data.results.map(item => (
        item.reply &&

        <div className='whitebox-message dm-sans-regular'>

          <span className=' date-mail'><span className='formob-msg'>Fecha</span>{item.created_at}</span>
          <span className=' date-mail'><span className='formob-msg'>Nombre</span>{item.first_name} {item.last_name}</span>
          <span className='headmail-message'><span className='formob-msg'>Correo electrónico</span>{item.email}</span>
          <span className='head-message'><span className='formob-msg'>Asunto</span>{item.subject}</span>
          <span className='head-message click'
            onClick={() => { handleMessageView(item) }}><span className='formob-msg'>Mensaje</span>Ver</span>
          <span className='head-message'>
            <span className='formob-msg'>Acciones</span>
            <button className='replysent'> Respuesta Enviada</button>

          </span>
        </div>


      ))}

      {/* message view popup */}
      {messageViewPopup &&
        <div className='message-detail-popup'>
          <div className='msg-text'>
            <div className='close' onClick={() => setMessageViewPopup(!messageViewPopup)}>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4698 1.00568L1.46973 15.0057M1.46973 1.00568L15.4698 15.0057" stroke="#141B34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div className=''>
              {messageSelected.message}

            </div>
          </div>


        </div>
      }
      {messagePopup ? <Messagepopup setterFunction={setMessagePopup} messageItem={messageSelected} toReply={isToReply} /> : ""}

      {isLoading && <LoadingSpinner /> }
      
    </div>
  )
}

export default Message