import React from 'react';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import './PdfViewer.css'
import { useNavigate, useLocation } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function PdfViewer ( {file} ) {
  const navigate = useNavigate();
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    console.error('Failed to load the document:', error);
  }

  const handlePagination = (increment) => {
    if (pageNumber + increment >= 1 && pageNumber + increment <= numPages) {
      setPageNumber(pageNumber + increment);
    }
  };
  console.log(file)

  return (
    <div className='pdf-container'>
      
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>

        <Page pageNumber={pageNumber} />
      </Document>
      {
        numPages && (
          <div className="pagination">
        <button onClick={() => handlePagination(-1)} disabled={pageNumber === 1}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24px"
            height="24px"
          >
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
        </button>
        <div className='page-number'>
           {pageNumber} / {numPages}
        </div>
        <button onClick={() => handlePagination(1)} disabled={pageNumber === numPages}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24px"
            height="24px"
          >
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
          </svg>
        </button>
      </div>
      )}
      
    </div>
  );
}

export default PdfViewer;
