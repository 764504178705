import React, { useContext } from 'react';
import "./Delete.css";
import { Link } from 'react-router-dom';

function Delete({onSubmit, setterFunction}) {

  const closeModal = () => {
    setterFunction(false);
  }
  const handleSubmit = () => {
    setterFunction(false);
    onSubmit()
  }

  return (
    <div className='backgrd-del'>
    <div className='delete-body'>
        <img src="\images\admin\Group 292033.svg" className='delete-logo' />
        <p className='poppins-regular1'>¿Estás seguro de que quieres eliminar esto ?</p>



        <button className="delete-btn poppins-regular" onClick={handleSubmit}>Sí, eliminar</button>
        <div className="delete-discard  poppins-regular" onClick={closeModal}>Descartar</div>
    </div>
    </div>
  )
}

export default Delete