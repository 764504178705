import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import BulletinPreview from './BulletinPreview';
import { Description } from '@mui/icons-material';


function CreateBulletin() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    title: '',
    description: '',
    link: "",
  });







  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));

  };













  const validateForm = (data) => {
    const errors = {};
    if (!data.title.trim()) {
      errors.title = "El título es obligatorio.";
    }
    if (!data.description.trim()) {
      errors.description = "El contenido es obligatorio.";
    }
    if (!data.link.trim()) {
      errors.link = "Link is required.";
    }



    return errors;
  };

  // Crear boletín Data
  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure programs are updated in formData before validating

    // Validate form data
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);

      API.post(`general-bulletines/`, formData)
        .then((response) => {
          setIsLoading(false);
          // navigate("/admin/boletines-generales") 

          // Return a promise that resolves when all image upload promises have resolved

        })


        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true)

          setMessage(error.message);
        });

    }
  };







  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Crear boletín</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>
        <div className='title-cb'>
          <label className='lbl-cb'>Título del boletín</label>
          <input type='text' className='title-inp-cb' name='title' onChange={handleInputChange} />
          {errors.title && <p className='invalid-feedback'>{errors.title}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Contenido del boletín</label>
          <textarea type='text' className='title-inp-cb2' name='description' onChange={handleInputChange} />
          {errors.description && <p className='invalid-feedback'>{errors.description}</p>}
        </div>


        <div className='title-cb-combine'>
          <div className='innertitle-cb w-100'>
            <label className='lbl-cb'>Enlace</label>
            <input
              type='text'
              name='link'
              className={`smallinp-cb ${errors[`programme_name`] ? 'invalid-input' : ''}`}
              value={formData.link}
              onChange={handleInputChange}
            />
            {errors.link && <p className='invalid-feedback'>{errors.link}</p>}

          </div>


        </div>

        

        <div className='btn-cb d-none'>
          <button className='btn-save-cb'  >
            Guardar boletín
          </button>
        </div>

        <div className='hrdashed' />


        <div className='btn-cb-savedis'>
          <Link to="/admin/boletines-generales" className="discard-cb">
            Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Guardar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default CreateBulletin;
