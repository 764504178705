// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorbackground{
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: transparent;

}
.warningbox{
    width: 400px;
    height: 350px;
    
}
.btn-error{
    cursor: pointer;
    z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/Errormodal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,6BAA6B;;AAEjC;AACA;IACI,YAAY;IACZ,aAAa;;AAEjB;AACA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":[".errorbackground{\r\n    position: relative;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background-color: transparent;\r\n\r\n}\r\n.warningbox{\r\n    width: 400px;\r\n    height: 350px;\r\n    \r\n}\r\n.btn-error{\r\n    cursor: pointer;\r\n    z-index: 9999;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
