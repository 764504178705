import React, { useEffect, useState } from "react";
import "./AperturaDeLaIgnesia.css";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../API"; // Import your API instance
import PositiveModal from "../../PositiveModal";
import LoadingSpinner from "../../LoadingSpinner";
import ErrorPopup from "../../ErrorPopup";

function AperturaDeLaIglesia() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);


  const [officehour, setOfficehour] = useState([]);

  useEffect(() => {
    const fetchChurchOpeningTimes = async () => {
      try {
        const response = await API.get("church-opening-time/");

        // Array of days in correct order, starting from 'Lunes'
        const weekdayOrder = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

        // Sorting function
        const sortedResults = response.data.results.sort((a, b) => {
          return weekdayOrder.indexOf(a.day) - weekdayOrder.indexOf(b.day);
        });
        setOfficehour(sortedResults);
      } catch (error) {
        console.log(error);
      }
    };

    fetchChurchOpeningTimes();
  }, []);

  function convertTimeToHHMMSS(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Format the time with seconds added as ":00"
    const formattedTime = `${hours}:${minutes}:00`;

    return formattedTime;
  }
  const navigate = useNavigate();

  // State to manage form data for each day
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    setFormData(officehour);
  }, [officehour]);  // Add officehour as a dependency to avoid unnecessary re-renders

  console.log(formData, "inside");

  // Function to handle input changes
  const handleChange = (e, index, timetype) => {
    const value = e.target.value;

    setFormData(prev =>
      prev.map((item, i) =>
        i === index ? { ...item, [timetype]: value } : item
      )
    );

    console.log('testttt', formData, index, timetype);
  };

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    try {
      // Create an array of promises for updating office hours
      const updatePromises = formData.map(async (data) => {
        const { id, ...cpyData } = data; // Destructure to remove `id` from the copied data
        await API.put(`church-opening-time/${id}/`, cpyData);
        return { ...cpyData, id }; // Return the original object with `id` added back
      });

      // Wait for all promises to complete
      await Promise.all(updatePromises);
      navigate("/admin/masstime")
      setIsLoading(false)
    } catch (error) {
      console.error("Error updating office hours:", error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
      setIsLoading(false)

    }
  };


  return (
    <div className="createpage">
      <div className="headerofevent2">
        <div className="titlebox2-ad dm-sans-regular">
          <span>Apertura De La Iglesia</span>
        </div>
      </div>
      <form className="craeteform dm-sans-regular" onSubmit={handleSubmit}>
        {formData.map((data, index) => (

          <div className="outer" key={index}>
            <div className="text-container">
              <span className="textof-oh2">{data.day}</span>
            </div>
            <div className="time-container">
              <div className="time-inp-box22">
                {/* Input for start time */}
                <div className="time-wd-box3">
                  <input
                    type="time"
                    className="time-wd"
                    value={data.morning_start_time}
                    onChange={(e) => handleChange(e, index, "morning_start_time")}
                  />
                  <img
                    className="timelogo-oh"
                    src="/images/admin/Masstime/Alarm.svg"
                    alt="alarm"
                  />
                </div>
                {/* Input for end time */}
                <div className="time-wd-box3">
                  <input
                    type="time"
                    className="time-wd"
                    value={data.morning_end_time}
                    onChange={(e) => handleChange(e, index, "morning_end_time")}
                  />
                  <img
                    className="timelogo-oh"
                    src="/images/admin/Masstime/Alarm.svg"
                    alt="alarm"
                  />
                </div>
              </div>

              <div className="time-inp-box22">
                <div className="time-wd-box3">
                  <input
                    type="time"
                    className="time-wd"
                    value={data.afternoon_start_time}
                    onChange={(e) => handleChange(e, index, "afternoon_start_time")}
                  />
                  <img
                    className="timelogo-oh"
                    src="/images/admin/Masstime/Alarm.svg"
                    alt="alarm"
                  />
                </div>
                {/* Input for end time */}
                <div className="time-wd-box3">
                  <input
                    type="time"
                    className="time-wd"
                    value={data.afternoon_end_time}
                    onChange={(e) => handleChange(e, index, "afternoon_end_time")}
                  />
                  <img
                    className="timelogo-oh"
                    src="/images/admin/Masstime/Alarm.svg"
                    alt="alarm"
                  />
                </div>
              </div>
            </div>
          </div>

        ))
        }

        <div className="btns-ad">
          <Link to="/admin/masstime" className="trans-btn">
            Descartar
          </Link>
          <button type="submit" className="solid-btn">
            Actualizar
          </button>
        </div>
      </form >
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/adminlist') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div >
  );
}

export default AperturaDeLaIglesia;
