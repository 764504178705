import React, { useContext, useEffect, useRef, useState } from 'react';
import "./Members.scss";
import API from '../../API';
import { UserContext } from '../../authentication/pages/UserContext';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';

function Members() {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { deletest, setDeletest, deletebtn } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [priest, setPriest] = useState([]);
  const [errors, setErrors] = useState({});
  const [create, setCreate] = useState(false);
  const [formdata, setFormData] = useState({
    name: "",
    position:"",
    image: ""
  });




  //get API

  useEffect(() => {
    API.get(`/priests/?page_size=100`)
      .then(response => {
        if (response.data && response.data.results) {
          setPriest(response.data.results);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }, []);






  //validation 
  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required.";
    }
    if (!data.position.trim()) {
      errors.position = "Designation is required.";
    }
    else if (data.name.length > 40) {
      errors.name = "Limit exceed";
    }
    if (!data.image) {
      errors.image = "Por favor, sube una imagen";
    }
    return errors;
  };



  //change input
  const handleChange = (e) => {
    const { name, type, value, files } = e.target;
    if (type === 'file') {
      const file = files[0];

      console.log(file, "inside file")

      setFormData(prevFormData => ({
        ...prevFormData,
        image: file
      }))

    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };





  const handleSubmit = async (e) => {

    e.preventDefault();

    const validationErrors = validateForm(formdata);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {

      setIsLoading(true);
      API.post(`/priests/?page_size=100`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          setIsLoading(false);
          setCreate(false);
          setIsSuccessModalOpen(true);
          setMessage('User created successfully.')
          setCreate(false);

          API.get(`/priests/`)
            .then(response => {
              if (response.data && response.data.results) {
                setPriest(response.data.results);
              } else {
                console.log('No result field in API response.');
                setMessage("No data to show");
                setIsErrorModalOpen(true);
              }
            })
            .catch(error => {
              console.log(error);
              setMessage(error.message);
              setIsErrorModalOpen(true);
            });
        })

        .catch((error) => {
          setIsLoading(false);
          setCreate(false);
          setIsErrorModalOpen(true);
          setFormData({
            name: '',
            image: ""
          });
          if (error.response && error.response.data) {
            setMessage(error.response.data);
          } else {
            setMessage(error.message);
          }
        });
    }
  };
  console.log(formdata, "inside data form")

  const discard = () => {
    setCreate(!create);
  }



  const deleteId = localStorage.getItem("delete_id");

  const confirmDelete = async () => {

    try {
      const response = await API.get(`/priests/?page_size=100`);
      const priests = response.data.results;
      const deleteIdInt = parseInt(deleteId, 10);
      const deleteIndex = priests.findIndex(priest => priest.id === deleteIdInt);
      setIsLoading(true);
      console.log(deleteIndex, "index"); // Debug log
      console.log(deleteIndex, "index")
      if (priests.length < 6) {

        setIsLoading(false);
        setMessage("No se puede eliminar esto ya que el límite mínimo es 5. Intente agregar otra imagen y luego intente eliminarla.");
        setIsErrorModalOpen(true);
        setDeletest(false)
        return;
      }

      else {
        setIsLoading(true)
        await API.delete(`/priests/${deleteId}`);
        const response = await API.get(`/priests/?page_size=100`);
        setIsLoading(false);
        setPriest(response.data.results);
        setDeletest(false);
      }
    } catch (error) {
      setDeletest(false);
      setMessage(error.message);
      setIsErrorModalOpen(true);
      setIsLoading(false);
    }
  };




  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  return (
    <div>
      <div className='members-panel'>
        <div className='header-member dm-sans-regular'>
          <button onClick={discard}>
            <span>
              <img src='/images/admin/eventad/Plus.svg' className='crtplus' alt="Agregar" />
              Agregar Nuevo Miembro
            </span>
          </button>
        </div>
        <div className='body-member'>
          {priest && priest.length > 0 ? priest.map((priestdetail, index) => (
            <div className='each-div' key={index} onClick={() => {
              localStorage.setItem('delete_id', priestdetail.id);  // Assuming `data` is the object containing the id
              deletebtn();
            }}
            >
              <img src={priestdetail.image} alt="Priest" />

              <img className='trash' src="/images/admin/trash.svg" alt="Trash" />
              <span>{priestdetail.name}<br/>{priestdetail.position}</span>
            </div>
          )) : <p>No se encontraron miembros.</p>}
        </div>



        {create ?
          <form className='add-sec dm-sans-regular' onSubmit={handleSubmit}>
            <p>Detalles del Miembro</p>
            <label>
            Ingrese el Nombre del Miembro
            </label>
            <input
              type='text'
              name='name'
              onChange={handleChange}
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}

            <label>
            Ingrese la Designación del Miembro.
            </label>
            <input
              type='text'
              name='position'
              onChange={handleChange}
            />
            {errors.position && <div className="invalid-feedback">{errors.position}</div>}


            <label>
            Subir la Imagen del Miembro
            </label>

            <input
              type='file'
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleChange}
            />
            <input
              onClick={handleButtonClick}
              readOnly
              placeholder={formdata.image ? formdata.image.name : 'Elegir Imagen'}
            />
            {errors.image && <div className="invalid-feedback">{errors.image}</div>}


            <div className='btn-grp'>
              <button type="submit">Guardar</button>
              <button type="button" onClick={discard}>Descartar</button>
            </div>
          </form>


          : ""}

      </div>


      {deletest && (
        <div className='backgrd-del'>
          <div className='delete-body'>
            <img src="/images/admin/Group 292033.svg" className='delete-logo' alt="Delete" />
            <p className='poppins-regular1'>¿Estás seguro de que quieres eliminar esto?</p>
            <button className="delete-btn poppins-regular" onClick={confirmDelete}>Sí, eliminar</button>
            <div className="delete-discard poppins-regular" onClick={deletebtn}>Descartar</div>
          </div>
        </div>
      )}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  );
}

export default Members;
