// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bullet-pdf{
    width: 100%;
    padding: 0 30px;
}
.bullet-pdf .top-sec{
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bullet-pdf .top-sec .btns{
    display: flex;
    gap: 10px;
}
.bullet-pdf .top-sec button{
    width: fit-content;
    padding: 10px 24px;
}

/*mobile view*/
@media only screen  and (max-width: 690px) {
    .bullet-pdf .top-sec {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
    }
    .bullet-pdf .top-sec .btns{
        display: flex;
        justify-content: flex-start;
    }
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/BulletinPreview.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA,cAAc;AACd;IACI;QACI,sBAAsB;QACtB,2BAA2B;QAC3B,uBAAuB;QACvB,SAAS;IACb;IACA;QACI,aAAa;QACb,2BAA2B;IAC/B;AACJ","sourcesContent":[".bullet-pdf{\r\n    width: 100%;\r\n    padding: 0 30px;\r\n}\r\n.bullet-pdf .top-sec{\r\n    padding-bottom: 30px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.bullet-pdf .top-sec .btns{\r\n    display: flex;\r\n    gap: 10px;\r\n}\r\n.bullet-pdf .top-sec button{\r\n    width: fit-content;\r\n    padding: 10px 24px;\r\n}\r\n\r\n/*mobile view*/\r\n@media only screen  and (max-width: 690px) {\r\n    .bullet-pdf .top-sec {\r\n        flex-direction: column;\r\n        justify-content: flex-start;\r\n        align-items: flex-start;\r\n        gap: 20px;\r\n    }\r\n    .bullet-pdf .top-sec .btns{\r\n        display: flex;\r\n        justify-content: flex-start;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
