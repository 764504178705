import React from 'react';
import "./CustomPopup.css";

function PositiveModel({ isOpen, onClose, title, content }) {

  function convertNewlinesToBreaks(text) {
    if (!text) return text;
    return text.split('\n').join('<br/>');
  }

  const formattedContent = convertNewlinesToBreaks(content);

  return (
    isOpen ? (
      <div className='modal-overlay'>
        <div className='modal-content'>
          <h2 className='modal-title'>{title}</h2>
          <div className='modal-body' dangerouslySetInnerHTML={{ __html: formattedContent }} />
          <button className='modal-close' style={{ width: "fit-content",margin:"0 auto" }} onClick={onClose}>Cerca</button>
        </div>
      </div>
    ) : null
  );
}

export default PositiveModel;
