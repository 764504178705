import React, { useEffect, useState } from 'react'
import "./CreateMasstime.css"
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { error } from 'jquery';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';



function PalabrasDelMes() {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState(false)


    const [formData, setFormData] = useState({
        page_name: "home",
        section_number: "9",
        title: "",
        description: "",

    })


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await API.get(`websitecontent/?page_name=home&section_number=9`);
                const eventData = response.data.results[0];
                console.log("this is success in fetching data from masstime");
                console.log("response", response);



                // Set the form data with the formatted date
                setFormData(eventData);
            } catch (error) {
                console.log("API error", error);
            }
        };
        fetchData();

    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(e)
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));

        console.log(formData)

    };






    const validateForm = (data) => {
        const errors = {};
        if (!data.title.trim()) {
          errors.title = "El título es obligatorio.";
        }
        if (!data.description.trim()) {
          errors.description = "El contenido es obligatorio.";
        }
        if (!data.link.trim()) {
          errors.link = "Link is required.";
        }
    
    
    
        return errors;
      };

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault(); // Prevent the default form submission
        // Handle form submission logic here


        API.put(`websitecontent/${formData.id}/`, formData).then(response => {
            navigate('/admin/masstime');
        })
            .catch(error => {
                setMessage(error.message)
                setIsErrorModalOpen(true)
            })



    };







    return (
        <div className='createpage'>
            <div className='headerofevent2'>
                <div className='titlebox2-ad dm-sans-regular'><span>Palabras Del Mes</span>
                </div>
            </div>


            <form className='craeteform dm-sans-bold' onSubmit={handleSubmit}>




                <div className='names-box'>
                    <div className='eachname-ad2'>
                        <label>Palabras</label>
                        <textarea
                            onChange={handleInputChange}
                            name='description'
                            className='namebox-ad2'
                            value={formData&&formData.description} >
                        </textarea>
                    </div>
                </div>

                <div className='names-box'>
                    <div className='eachname-ad2'>
                        <label>por</label>
                        <input
                            onChange={handleInputChange}
                            name='title'
                            className='namebox-ad1'
                            value={formData&&formData.title} />

                    </div>
                </div>



                <div className='btns-ad'>
                    <Link to="/admin/masstime" className='trans-btn'>Descartar</Link>
                    <button className='solid-btn'>Actualizar</button>

                </div>
            </form>

            {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/adminlist') }} />}
            {isLoading && <LoadingSpinner />}
            <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
        </div>
    )
}

export default PalabrasDelMes
