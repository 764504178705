import React from 'react';
import { Modal } from "react-bootstrap";
import './CustomPopup.css'
const PositiveModal = ({ message,title, state, setterFunction, okClickedFunction  }) => {

  
  const okClicked=()=>{
    okClickedFunction();
    setterFunction(false)
  }

  return (
    <div className='custom-modal positive-modal'>
            <div className='card'>
               
                    <div className='first-screen'>
                        {/* <img src='/images/positive-popup-icon.svg'></img> */}
                        <h1>{title?title:"Éxito!"}</h1>
                        <p>{message?message:"Cambios guardados con éxito"}</p>
                        
                        <div className='footer single-button-footer'>
                        
                        <button type='button' className='ok-button' onClick={okClicked}>Hecho</button>

                        </div>
                    </div>
                    
            </div>
           

        </div>
  );
};

export default PositiveModal;
