import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import BulletinPreview from './BulletinPreview';


function CreateBulletin() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    title: '',
    content: '',
    expiry_date: format(new Date(), 'yyyy-MM-dd'),
    programme: [],
  });

  const [programs, setPrograms] = useState([
    { programme_name: '', total_days: '', start_time: '', end_time: '' }
  ]);

  const addProgram = () => {
    // Add a new program section if validation passes
    setPrograms([...programs, { programme_name: '', total_days: '', start_time: '', end_time: '' }]);
  };
  // Add Program to FormData
  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      programme: programs
    }));
  }, [programs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));

  };
  // console.log(formData);

  // Function to handle removing a program by index
  const removeProgram = (index) => {
    const newPrograms = [...programs];
    newPrograms.splice(index, 1);
    setPrograms(newPrograms);
  };

  // Function to handle input changes for a specific program
  const handleProgramInputChange = (index, fieldName, value) => {
    const newPrograms = [...programs];
    newPrograms[index][fieldName] = value;
    setPrograms(newPrograms);
  };


  // --------- IMAGE SECTION ----------
  const [uploads, setUploadImages] = useState([{ upload1: '', upload2: '' }]);

  const addUploadFields = () => {
    setUploadImages([...uploads, { upload1: '', upload2: '' }]);
  };

  const handleImageChange = (e, index, field) => {
    console.log("index:",index, "field:",field)
    const fileSizeLimit = 3 * 1024 * 1024; // 3 MB in bytes
    const file = e.target.files[0]
    if (file.size > fileSizeLimit) {
      setMessage('File size exceeds 3 MB. Please select a smaller file.');
      setIsErrorModalOpen(true)
      return;
    }
    const newUploads = [...uploads];
    newUploads[index][field] = file;
    setUploadImages(newUploads);
  };
  const removeImage = (index, field) => {
    const newUploads = [...uploads];
    newUploads[index][field] = '';
    setUploadImages(newUploads);
  };


  // check number of images uploaded
  const validateUploads = (uploadsArray) => {
    let totalImages = 0;
    uploadsArray.forEach(upload => {
      if (upload.upload1) {
        totalImages++;
      }
      if (upload.upload2) {
        totalImages++;
      }
    });
    // Check if the total number of images is at least 2 and is a multiple of 2
    return totalImages >= 2 && totalImages % 2 === 0;
  };



  const handleDateChange = (date) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      expiry_date: format(date, 'yyyy-MM-dd')
    }));
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.title.trim()) {
      errors.title = "Título is required.";
    }
    if (!data.content.trim()) {
      errors.content = "El contenido es obligatorio.";
    }
    // Validate each program in the programmes array
    data.programme.forEach((program, index) => {
      if (!program.programme_name.trim()) {
        errors[`programme_name_${index}`] = "Required.";
      }
      if (!program.total_days.trim()) {
        errors[`total_days_${index}`] = "Required.";
      }
      if (!program.start_time.trim()) {
        errors[`start_time_${index}`] = "Required.";
      }
      if (!program.end_time.trim()) {
        errors[`end_time_${index}`] = "Required.";
      }
    });
    // check upload images
    if (!validateUploads(uploads)) {
      errors.images = "Images must be at least 2 or multiples of 2";
    }
    return errors;
  };

  // Crear boletín Data
  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure programs are updated in formData before validating
    setFormData(prevFormData => ({
      ...prevFormData,
      programme: programs
    }));

    // Validate form data
    const validationErrors = validateForm({ ...formData, programme: programs });
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      let bulletinId = null;
      API.post(`bulletins/`, formData)
        .then((response) => {
          // Extract the bulletin ID or any other necessary data from the response
          bulletinId = response.data.id; // Replace with the actual field name from your response
          // Prepare data for the second API request (bulletimages/)
          const imageUploadPromises = [];
          uploads.forEach(upload => {
            Object.keys(upload).forEach(key => {
              const file = upload[key];
              if (file) {
                const imageData = new FormData();
                imageData.append('bullet', bulletinId);
                imageData.append('image', file);
                // Push each API post promise to the array
                imageUploadPromises.push(API.post('bulletimages/', imageData, { headers: { 'Content-Type': 'multipart/form-data', } }));
              }
            });
          });
          // Return a promise that resolves when all image upload promises have resolved
          return Promise.all(imageUploadPromises);
        })
        .then((imageResponse) => {
          // Second API request succeeded
          console.log('Bulletin and images created successfully.');
          return API.get(`bulletins/${bulletinId}/generate-pdf/`);
        })
        .then((pdfResponse) => {
          console.log('PDF generated successfully.');
          console.log(pdfResponse.data);
          setIsLoading(false);

          // redirect to Preview Page
          const bulletin_id = bulletinId;
          navigate('/admin/bulletin/preview', { state: { bulletin_id } });

        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true)

          setMessage(error.message);
        });

    }
  };







  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Crear boletín</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>
        <div className='title-cb'>
          <label className='lbl-cb'>Título del boletín</label>
          <input type='text' className='title-inp-cb' name='title' onChange={handleInputChange} />
          {errors.title && <p className='invalid-feedback'>{errors.title}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Contenido del boletín</label>
          <textarea type='text' className='title-inp-cb2' name='content' onChange={handleInputChange} />
          {errors.content && <p className='invalid-feedback'>{errors.content}</p>}
        </div>

        {programs.map((program, index) => (
          <div key={index} className='title-cb-combine'>
            <div className='innertitle-cb'>
              <label className='lbl-cb'>Nombre del programa</label>
              <input
                type='text'
                className={`smallinp-cb ${errors[`programme_name_${index}`] ? 'invalid-input' : ''}`}
                value={program.programme_name}
                onChange={(e) => handleProgramInputChange(index, 'programme_name', e.target.value)}
              />

            </div>
            <div className='timeof-cb'>
              <div className='innertitle-cb2'>
                <label className='lbl-cb'>Días totales</label>
                <input
                  type='number'
                  min={1}
                  className={`smallinp-cb ${errors[`total_days_${index}`] ? 'invalid-input' : ''}`}
                  value={program.total_days}
                  onChange={(e) => handleProgramInputChange(index, 'total_days', e.target.value)}
                />
              </div>
              <div className='innertitle-cb2'>
                <label className='lbl-cb'>Tiempo</label>
                <input
                  type='time'
                  className={`smallinp-cb ${errors[`start_time_${index}`] ? 'invalid-input' : ''}`}
                  value={program.start_time}
                  onChange={(e) => handleProgramInputChange(index, 'start_time', e.target.value)}
                />
                <img src="/images/admin/bulletin/Fill/Shape.svg" className='tim-img' alt='time icon' />
              </div>
              <div className='innertitle-cb2'>
                <label className='nomob'>Tiempo 2</label>
                <input
                  type='time'
                  className={`smallinp-cb ${errors[`end_time_${index}`] ? 'invalid-input' : ''}`}
                  value={program.end_time}
                  onChange={(e) => handleProgramInputChange(index, 'end_time', e.target.value)}
                />
                <img src="/images/admin/bulletin/Fill/Shape.svg" className='tim-img' alt='time icon' />
              </div>
              {/* Remove button for each program */}
              {/* <span className='d-flex align-items-center mt-3'  onClick={() => removeProgram(index)}>Remove</span> */}
            </div>

          </div>
        ))}


        <div className='addmore-cb'>
          <img src="/images/admin/bulletin/ADMIN PANEL -SAINT MARIA (22)/Ecommerce/Fill/Plus.svg" className='plus-cb' />
          <span className='am-cb color' onClick={addProgram}>Añadir más</span>
        </div>

        <div className='calender-div-cb color'>
          <label className='lbl-cb color'> Elige la fecha de caducidad para el boletín</label>
          <DatePicker
            selected={formData.expiry_date}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyyy'
            customInput={
              <div className='date-picker-custom-input'>
                <input type='text' className='inp-cal-cb' readOnly value={formData.expiry_date} />
                <img src="/images/admin/bulletin/Group 291853.svg" className='cal-img' />
              </div>
            }
            key="datepicker"
          />
        </div>

        <p className='thanku color'>Gracias por su continuo apoyo y participación en nuestra comunidad eclesiástica. ¡Esperamos verlo en nuestros próximos eventos!!</p>

        {/* <div className='hrdashed' /> */}

        <div className='btn-cb d-none'>
          <button className='btn-save-cb'  >
          Guardar boletín
          </button>
        </div>

        <div className='hrdashed' />

        <div className='upload-img-cb'>
          {uploads.map((upload, index) => (
            <div key={index} className='uplo2'>
             
             
              <div className='innertitle-cb color'>
                <label>Imágenes para subir</label>
                <input type='text' className='smallinp-cb' readOnly value={upload.upload1 && upload.upload1.name} onClick={() => !upload.upload1 && document.getElementById(`fileInput1-${index}`).click()}
                  style={{
                    paddingRight: '60px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }} />
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e, index, 'upload1')}
                  id={`fileInput1-${index}`}
                  accept=".png, .jpg, .jpeg"
                />
                {upload.upload1 ? (
                  <img src='/images/admin/bulletin/Shape.svg' alt='Shape' className='uplo-cb' onClick={() => removeImage(index, 'upload1')} />

                ) : (

                  <img src="/images/admin/bulletin/ADMIN PANEL -SAINT MARIA (23)/Interaction/Outline/Shape.svg" className='uplo-cb' onClick={() => document.getElementById(`fileInput1-${index}`).click()} />
                )}
              </div>

             
             
              <div className='innertitle-cb color'>
                <label className='nomob'>Imágenes para subir</label>
                <input type='text' className='smallinp-cb' readOnly value={upload.upload2 && upload.upload2.name} onClick={() => !upload.upload2 && document.getElementById(`fileInput2-${index}`).click()}
                  style={{
                    paddingRight: '60px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                />
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e, index, 'upload2')}
                  id={`fileInput2-${index}`}
                  accept=".png, .jpg, .jpeg"
                />
                {upload.upload2 ? (
                  <img src='/images/admin/bulletin/Shape.svg' alt='Shape' className='uplo-cb'
                    onClick={() => removeImage(index, 'upload2')} />

                ) : (

                  <img src="/images/admin/bulletin/ADMIN PANEL -SAINT MARIA (23)/Interaction/Outline/Shape.svg" className='uplo-cb' onClick={() => document.getElementById(`fileInput2-${index}`).click()} />
                )}
              </div>

              <div className='innertitle-cb20'>
                <label className='nomob'>Añadir más</label>
                <button className='plusbtn-cb' onClick={addUploadFields}>
                  <img src="/images/admin/bulletin/pluso/pluso.svg" className='pluso' />
                </button>
              </div>


            </div>

          ))}

          {errors.images && <p className='invalid-feedback'>{errors.images}</p>}


        </div>

        <div className='hrdashed' />

        <div className='btn-cb-savedis'>
          <Link to="/admin/bulletin" className="discard-cb">
          Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Previsualizar y enviar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default CreateBulletin;
