import React, { useEffect, useState } from 'react'
import "./Bulletins.css"
import Header from '../common-components/Header'
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import ErrorPopup from '../../ErrorPopup';
import { useNavigate } from 'react-router-dom';
import $ from "jquery"

function Bulletins() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])



    const navigate = useNavigate();
    const [newBulletinList, setNewBulletinList] = useState([]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const loadBulletins = () => {
        setIsLoading(true)
        API.get(`bulletins-list/`)
          .then(response => {
            setNewBulletinList(response.data.results);
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            console.log(error);
            setIsErrorModalOpen(true);
            setMessage(error.message);
          });
      };

      loadBulletins();

    },[])
    console.log(newBulletinList)


    //view pdf
    const handleView = (bulletin) => {
        console.log(bulletin);
        const bulletin_id = bulletin.id;
        const pdf_url = bulletin.pdf_file_url;
        console.log(pdf_url,bulletin_id);
        navigate('/bulletin/pdfview', { state: { pdf_url, bulletin_id } });
        
      }



      const downloadPDF = (pdf) => {
        let filename;
        let fileURL;
        if (pdf.subtitle) {
          filename = `${pdf.title}_${pdf.subtitle}.pdf`;
          fileURL = pdf.pdf;
        } else {
          filename = `${pdf.title}.pdf`;
          fileURL = pdf.pdf_file_url ;
        }
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = filename;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      
    
 

  return (
    <div>
         <div className='image-head-banner' id='banner' style={{ backgroundImage: `url("/images/espnew-edit/Bulletin -B.jpg")` }}>
        <Header />
      </div>

      <div className='sub-body-esp' >


<div className='text-box2-esp'>
  <span className='great-vibes-regular cursive-esp'>Boletines de la  <span className='poppins-regular1'> Iglesia </span></span>

</div>


<div className='bulletin-mainbody'>
    {newBulletinList.map((bulletin,index)=>(
        
        <div className='masstime-upcom poppins-regular2 '> 
        <p className='each-bullet'>{bulletin.title}</p>
        <p className='each-bullet1'><span className='mobhide-bul'> Publicado en:</span> {bulletin.created_at}</p>
        <span className='bodyhead-bul23'>
            
            <span className='logdiv' onClick={()=>handleView(bulletin)}><img src='\images\admin\bulletin\Eye_Open.svg' alt='Eye' /></span>
            <span className='logdiv'onClick={() => downloadPDF(bulletin)}><img src='\images\reshot-icon-download-2DU9YCZT5P.svg' alt='Eye' /></span>
           
          </span>
          <span className='bodyhead-bul3'>
            
            <span className='logdiv' onClick={()=>handleView(bulletin)}><img src='\images\admin\bulletin\Eye_Open.svg' alt='Eye' /></span>
            <span className='logdiv' onClick={() => downloadPDF(bulletin)}><img src='\images\reshot-icon-download-2DU9YCZT5P.svg' alt='Eye' /></span>
           
          </span>
        </div>
       


    ))}
   
</div>
</div>





{isLoading && <LoadingSpinner />}
    
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />   
    </div>
  )
}

export default Bulletins