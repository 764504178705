import React, { useEffect, useState } from 'react'
import "./Despacho.css"
import $ from 'jquery'
import Header from '../common-components/Header';
import API from '../../API';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';

function Despacho() {

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [despachoPdfList, setDespachoPdfList] = useState([]);
  const [colaboracionPdfList, setColaboracionPdfList] = useState([]);
  const [intencionesDeMisasPdfList, setIntencionesDeMisasPdfList] = useState([]);


  const [officeHrs, setOfficeHrs] = useState([]);

  
  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true)
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  // Load data from backend
  useEffect(() => {
    loadOfficeHours();
    loadPDFs('Despacho', 'Despacho', setDespachoPdfList);
    loadPDFs('Despacho', 'Colaboración', setColaboracionPdfList);
    loadPDFs('Despacho', 'Intenciones De Misas', setIntencionesDeMisasPdfList);

  }, []);

  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const loadOfficeHours = () => {
    setIsLoading(true)
    API.get(`office-hours/`)
      .then(response => {
        setOfficeHrs(response.data);
        setIsLoading(false);
        console.log(response, "inside response")
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  }


  // Utility function to convert time to 12-hour format
  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };



  const groupedOfficeHours = officeHrs.reduce((acc, curr) => {
    const timeSlot = `${convertTo12Hour(curr.start_time)}-${convertTo12Hour(curr.end_time)}`;
    if (!acc[timeSlot]) {
      acc[timeSlot] = [];
    }
    acc[timeSlot].push(curr.day);
    return acc;
  }, {});


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])




  const [isScrollingUp1, setIsScrollingUp1] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrollingUp1(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);
  // console.log(officeHrs)




  return (
    <div>
      <div className='banner-des' style={{ backgroundImage: `url('/images/despacho/Banner.jpg')` }}>
        <Header />

      </div>


      <div className='subbody2'>
        <p className='poppins-regular1 headingtext-des'>Despacho</p>
        <p className='dm-sans-regular  subtextc'>
          El Despacho Parroquial está accesible durante su horario que se puede ver abajo. Es un lugar de acogida y de atención personal para solucionar cuestiones relacionadas con los sacramentos, intenciones de misas, funerales, catequesis y otros asuntos eclesiales.

        </p>
        <div className='pdf-download-container liturgy'>
          {despachoPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>


      </div>


      <div className='bluebody-des'>
        <img src="\images\despacho\clock.svg" className='clock' />
        <p className='white-des'>
          <span className='great-vibes-regular'>La entrada por la calle</span> <br />
          <span className='poppins-regular1'>Manuel Uribe 1</span>
        </p>
        {officeHrs.map((time, index) => (
          <div key={index} className='time-desp dm-sans-regular'>
            <span className='eachtime-des'>{convertTo12Hour(time.start_time)}-{convertTo12Hour(time.end_time)}</span> {/* Display the entire time slot */}

            <span className={`eachtime-des1`}>
              {time.day.toLowerCase()} {/* Capitalizing the first letter */}
            </span>

          </div>
        ))}


        {/* <div className='time-desp dm-sans-regular'>
          <span className='eachtime-des' >12:00-13: 15</span>
          <span className='eachtime-des' >Miércoles</span>
        </div> */}
        <hr></hr>
        <p className=' lastcont dm-sans-regular'>Información: No habrá horario de oficina los días festivos, sábados y domingos.</p>

      </div>


      <div className='card1-desp'>
        <div className='forimg-desp'>
          <img src="images\despacho\Rectangle 703.png" className='cardjesus' />
        </div>
        <div className='forcont-desp'>
          <p className='poppins-regular1 headcard16-desp'>Colaboración</p>
          <div className='forimg-desp-mobonly'>
            <img src="images\despacho\Rectangle 703.png" className='cardjesus' />
          </div>
          <p className='dm-sans-regular innercardtext'>
            Santa María del Bosque es tu Parroquia y tu colaboración, en todo sentido, es importante para su crecimiento. Ahora, con tu aportación económica puedes ser parte de este grupo de ayuda a la Iglesia para su sostenimiento. Realizando una suscripción por una cantidad fija mensual, trimestral o anual es la mejor forma de colaborar económicamente. Aquí abajo tienes el formulario.
          </p>
          <div className='pdf-download-container liturgy'>
          {colaboracionPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>
        </div>

      </div>
      <div className='card1-desp'>
        <div className='forcont-desp'>
          <p className='poppins-regular1 headcard16-desp'>Intenciones de misas</p>
          <div className='forimg-desp-mobonly'>
            <img src="images\despacho\Rectangle 802.png" className='cardjesus' />
          </div>
          <p className='dm-sans-regular innercardtext'>
            En cada celebración de la Eucaristía se actualiza sacramentalmente el sacrificio de Cristo en la cruz, su muerte y resurrección, que, en el Espíritu Santo, se ofrece a Dios Padre por la salvación del mundo. Todo cristiano puede encargar al Sacerdote que aplique la Misa por una determinada intención particular. Es importante a dar gracias a Dios por sus bendiciones recibidas.
          </p>
          
          <div className='pdf-download-container liturgy'>
          {intencionesDeMisasPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>

        </div>
        <div className='forimg-desp'>
          <img src="images\despacho\Rectangle 802.png" className='cardjesus' />

        </div>


      </div>






      {isLoading && <LoadingSpinner />}
      {/* <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false) } /> */}


    </div>
  )
}

export default Despacho