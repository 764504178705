import React, { useEffect, useState } from 'react'
import "./Groupo.css"
import $ from 'jquery'
import Header from '../common-components/Header';
import API from '../../API';

function Groupo() {
  const [formData, setFormData] = useState([])
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [adocionNocturnaPdfList, setAdocionNocturnaPdfList] = useState([]);
  const [grupoCharlasFormativasPdfList, setGrupoCharlasFormativasPdfList] = useState([]);
  const [grupoDeLiturgiaPdfList, setGrupoDeLiturgiaPdfList] = useState([]);
  const [adoracionDeJovenesPdfList, setAdoracionDeJovenesPdfList] = useState([]);
  const [emausMujeresPdfList, setEmausMujeresPdfList] = useState([]);


  const loadData = () => {
    API.get(`/websitecontent/?page_name=groups&section_number=2`)
      .then(response => {
        if (response.data && response.data.results) {
          setFormData(response.data.results.sort((a, b) => a.id - b.id));

        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }

  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true)
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };



  useEffect(() => {
    loadData();
    loadPDFs('Groupos', 'Adoración Nocturna', setAdocionNocturnaPdfList);
    loadPDFs('Groupos', 'Grupo Charlas Formativas', setGrupoCharlasFormativasPdfList);
    loadPDFs('Groupos', 'Grupo De Liturgia', setGrupoDeLiturgiaPdfList);
    loadPDFs('Groupos', 'Adoración De Jóvenes', setAdoracionDeJovenesPdfList);
    loadPDFs('Groupos', 'Emaús Mujeres', setEmausMujeresPdfList);

  }, []);


  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <div>

      <div className='banner-groupo' id='grp' style={{ backgroundImage: `url('images/Groupo/Groupo Banner.jpg')` }}>
        <Header />

      </div>


      {formData && formData.length > 0 &&
        < div className='subbody-groupo' id='oracion'>
          <img src='images/Groupo/chr.svg' className='chr-logo' />
          <p className='heading-groupo great-vibes-regular  '>todos los jueves  <p className='poppins-regular1'>ADORACIÓN</p> </p>

          <div className='housediv'>
            <div className='img-house-logo'>
              <img src='images/Groupo/Group 613.svg' className='houselg' />
            </div>
            <div className='content-house'>
              <p className='dm-sans-bold content-head-grp'>{formData[0].title}</p>  {/* Los jueves*/}
              <p className='dm-sans-regular content-body-grp'>
                {formData[0].description} {/* Todos los jueves, después de la misa de 19,30, a continuación, tendremos adoración. */}
              </p>

            </div>

          </div>
          <div className='housediv'>
            <div className='img-house-logo'>
              <img src='images/Groupo/Group 613.svg' className='houselg' />
            </div>
            <div className='content-house'>
              <p className='dm-sans-bold content-head-grp'>{formData[1].title} {/* 20,00-20,30*/}</p>
              <p className='dm-sans-regular content-body-grp'>
                {formData[1].description}  {/* Cada jueves se encargará un grupo de la Parroquia.*/}
              </p>

            </div>

          </div>
          <div className='housediv'>
            <div className='img-house-logo'>
              <img src='images/Groupo/Group 613.svg' className='houselg' />
            </div>
            <div className='content-house'>
              <p className='dm-sans-bold content-head-grp'>{formData[2].title} {/* Comunidad parroquial*/}</p>
              <p className='dm-sans-regular content-body-grp'>
                {formData[2].description}  {/* En este rato de oración, la comunidad parroquial se junta para alabar y dar gracias a Dios.*/}
              </p>

            </div>

          </div>


        </div>
      }






      <div className='bluebody-groupo' id='ad-noct'>
        <img src="\images\Saint Maria Logo V.0.1-01.svg" className='logodespach' />
        <p className='heading-groupo great-vibes-regular  '>Adoración  <p className='poppins-regular1'>Nocturna</p> </p>

        <p className=' dm-sans-regular ashtext-groupo'>
          una asociación de fieles reconocida por la Iglesia Católica, que tiene como principal finalidad la adoración, durante la noche, del Santísimo Sacramento. Aquí en la iglesia parroquial se reúne un grupo de personas los últimos viernes de cada mes a las 22,00h. para la adoración nocturna.
        </p>

        <div className='pdf-download-container liturgy'>
          {adocionNocturnaPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>



      </div>



      <div className='coverlower' id='gcf'>

        <p className='great-vibes-regular headmark'>Grupo Charlas <p className='poppins-regular1 '>formativas</p></p>
        <p className='lowerbody dm-sans-regular '>
          además de otros grupos, la Parroquia ofrece, durante el curso pastoral, charlas formativas para los que las deseen. En cada año, elegirá y se tratará un tema dirigido por un sacerdote de la Parroquia para la formación de los fieles.
        </p>

        <div className='pdf-download-container liturgy'>
          {grupoCharlasFormativasPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>

      </div>




      <div className='fourimg-grp' id='gdl'>
        <div className='img-grp-grid'>
          <img src='images/Groupo/img1.jpg' className='lastbannergrp' />
        </div>
        <div className='text-grp-grid'>
          <p className='grid-head great-vibes-regular'>Grupo de<p className='poppins-regular1'>Liturgia</p></p>
          <p className='grid-body dm-sans-regular '>
            El grupo de Liturgia es un grupo constituido por personas creyentes que prestan generosamente su servicio a la comunidad en su aspecto celebrativo. Como nos dice el Concilio, están en orden a “trabajar para que florezca el sentido comunitario parroquial, sobre todo en la celebración común de la misa parroquial” (SC 42). Se reúne el grupo el último martes de cada mes a las 20,00h.
          </p>
          <div className='pdf-download-container liturgy'>
            {grupoDeLiturgiaPdfList.map((pdf, index) => (
              <div key={index} className='btn-cards-lit dm-sans-bold '>
                <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                </span>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className='fourimg-grp1' id='gdj'>

        <div className='text-grp-grid'>
          <p className='grid-head great-vibes-regular'>Adoración de<p className='poppins-regular1'>jóvenes</p></p>
          <p className='grid-body dm-sans-regular '>
            un pequeño grupo de jóvenes se reúnen todos los miércoles a las 20.30 para la Adoración al Santísimo que dura unos 45 minutos y luego aprovechan un rato más para conocerse mutuamente y formarse en el camino cristiano.
          </p>


          <div className='pdf-download-container liturgy'>
            {adoracionDeJovenesPdfList.map((pdf, index) => (
              <div key={index} className='btn-cards-lit dm-sans-bold '>
                <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                </span>
              </div>
            ))}
          </div>

        </div>
        <div className='img-grp-grid'>
          <img src='images/Groupo/img2.png' className='lastbannergrp' />
        </div>

      </div>




      <div className='bluebody-groupo2' id='em'>
        <img src='images/Groupo/crs.svg' className='crs-logo' />

        <p className='heading-ash poppins-regular1 '>Emaús Mujeres </p>
        <p className=' dm-sans-regular ashtext-groupo'>El retiro de Emaús, organizado por laicos católicos, tiene el objetivo de ayudar a la persona a través de testimonios y oraciones, a orientar su vida en el camino del Señor. Está dirigido a mujeres adultas a partir de 30 años, que estén cerca o lejos de la fe, que sean practicantes o no, católicos o ateos.</p>
        <div className='listofgrp dm-sans-regular'>
          <ul>
            <li>Support Services</li>
            <li>Community Engagement</li>
            <li>Empowerment Programs</li>
          </ul>
        </div>
        <div className='pdf-download-container liturgy'>
          {emausMujeresPdfList.map((pdf, index) => (
            <div key={index} className='btn-cards-lit dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className='forimgofgrp'>
        <img src="\images\Groupo\Rectangle 712.png" className='lastbannergrp' />
      </div>




    </div>
  )
}

export default Groupo