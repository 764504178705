import React, { useContext, useEffect, useRef, useState } from 'react'
import "./Espiritualidad.css"
import { Link, useParams } from 'react-router-dom'

import { UserContext } from '../../authentication/pages/UserContext';
import Header from '../common-components/Header';
import Slider from 'react-slick';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';


function Espiritualidad() {
  const [download, setShowdownload] = useState(false)
  const [download1, setShowdownload1] = useState(false)
  const [download2, setShowdownload2] = useState(false)
  const [download3, setShowdownload3] = useState(false)
  const [bulletinActiveIndex, setBulletinActiveIndex] = useState(null)

  const [priest, setPriest] = useState([]);
  const [churchOpeningTime, setChurchOpeningTime] = useState([]);
  // const Download1 = () => {
  //   setShowdownload1(!download1)
  //   setSupport1(false)
  // }
  // const Download2 = () => {
  //   setShowdownload2(!download2)
  //   setSupport2(false)
  // }
  // const Download3 = () => {
  //   setShowdownload3(!download3)
  //   setSupport3(false)
  // }
  // const Download = () => {
  //   setShowdownload(!download)
  //   setSupport(false)
  // }


  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [economiaList, setEconomiaList] = useState([]);
  const [pastroalList, setPastroalList] = useState([]);
  const [equipoSacerdotalPdfList, setEquipoSacerdotalPdfList] = useState([]);
  const [generalBulletins, setGeneralBulletins] = useState([]);
  const [parroquiaExtraData, setParroquiaExtraData]=useState({});
  // Load data from backend



  const loadParroquiaExtraData=()=>{
    API.get(`/websitecontent/?page_name=parroquia&section_number=2`)
    .then(response => {
      if (response.data && response.data.results) {
        setParroquiaExtraData(response.data.results[0]);
      } else {
        console.log('No result field in API response.');
        setMessage("No data to show");
        setIsErrorModalOpen(true);
      }
    })
    .catch(error => {
      console.log(error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
    });
  }

  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true)
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };
  const loadData = () => {
    loadPDFs('Parroquia', 'Economia', setEconomiaList);
    loadPDFs('Parroquia', 'Pastoral Parroquia', setPastroalList);
    loadPDFs('Parroquia', 'Equipo Sacerdotal Parroquial', setEquipoSacerdotalPdfList);
  };




  const loadChurchOpeningTime = () => {
    setIsLoading(true)
    API.get(`church-opening-time`)
      .then(response => {
// Array of days in correct order, starting from 'Lunes'
const weekdayOrder = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

// Sorting function
const sortedResults = response.data.results.sort((a, b) => {
  return weekdayOrder.indexOf(a.day) - weekdayOrder.indexOf(b.day);
});
setChurchOpeningTime(sortedResults);

        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  }
useEffect(()=>{
  console.log("cot",churchOpeningTime)
},[churchOpeningTime])
  const loadPriestData = () => {
    API.get(`/priests/?page_size=100`)
      .then(response => {
        if (response.data && response.data.results) {
          setPriest(response.data.results);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }


  const loadGeneralBulletins = () => {
    API.get(`/general-bulletines`)
      .then(response => {
        if (response.data && response.data.results) {
          setGeneralBulletins(response.data.results);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }
  useEffect(() => {
    loadParroquiaExtraData()
    loadData();
    loadChurchOpeningTime();
    loadPriestData();
    loadGeneralBulletins();
  }, []);

  const downloadPDF = (pdf) => {
    let filename;
    let fileURL;
    if (pdf.subtitle) {
      filename = `${pdf.title}_${pdf.subtitle}.pdf`;
      fileURL = pdf.pdf;
    } else {
      filename = `${pdf.title}.pdf`;
      fileURL = pdf.pdf_file_url;
    }
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',

        }
      },


      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',

        }
      }

    ],
  };




  //get API




  const convertTo12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(part => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }
    let ampm = 'AM';
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = 'PM';
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours = adjustedHours < 10 ? '0' + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  return (
    <div>

      <div className='image-head-esp' id='banner' style={{ backgroundImage: `url('/images/esp-img/Group 970.png')` }}>
        <Header />
      </div>


      <div className='sub-body-esp-update' id='pp'>

        <div className='content-esp-up'>
          <div className='text-box2-esp-update'>
            <span className='great-vibes-regular cursive-esp-update'>lema pastoral   <span className='poppins-regular1'>Parroquial</span></span>

          </div>
          <div className='sub-esp-update dm-sans-regular'>
           {parroquiaExtraData.description} {/* El lema de la opción preferente para este curso, 2024-2025, es “Anunciamos la Esperanza”. En el pdf adjuntando, puedes ver la justificación, los objetivos y medios para lograr este fin. */}
            <div className='pdf-download-container'>
              {pastroalList.map((pdf, index) => (
                <div key={index} className='btn-card3-esp dm-sans-bold'>
                  <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' alt='Download logo' />
                  <span onClick={() => downloadPDF(pdf)}>
                    Descargar PDF
                  </span>
                </div>
              ))}
            </div>


          </div>
        </div>
        <div className='forimg-esp'>

          <img src={parroquiaExtraData.image} className='img-esp-update' />

        </div>
      </div>



      <div className='sub-body-esp1' >
        <div className='time-esp-update'>
          <p className='great-vibes-regular head-esp43'>Apertura de la lglesia</p>


          {churchOpeningTime && churchOpeningTime.length > 0 &&
            churchOpeningTime.map((item, index) => {
              return(
                <div className='div1-sec1-esp'>
                  <div className='time-content'>{item.day}:</div>
                  <div className='in-esp-sec2'>
                    <div className='time-content2'>{convertTo12Hour(item.morning_start_time)} - {convertTo12Hour(item.morning_end_time)}</div>
                    <div className='time-content2'>{convertTo12Hour(item.afternoon_start_time)} - {convertTo12Hour(item.afternoon_end_time)}</div>
                  </div>
                </div>

              )
            })}




        </div>
        {/* <img src="\images\esp-img\ALV_9077.jpg" className='divimg1-esp' />
        <img src="\images\esp-img\ALV_9036-Mejorado-NR.jpg" className='divimg2-esp' /> 
        */}

        <img src="\images\esp-img\Rectangle 784.png" className='esp1-img' />
      </div>


      <div className='specialbody-esp' id='sa'>
        <img src='/images/esp-img/Group 1230.svg' className='necklace' />
        <img src='\images\espnew-edit\image 29.svg' className='saintlogoaug' />
        <div className='text-box2-esp'>
          <span className='great-vibes-regular cursive-esp32'>  La Orden   <span className='poppins-regular1'>de san Agustín </span></span>
        </div>
        <br />
        <p className='dm-sans-regular innertext2-esp'>Hablando de la Orden Agustiniana es pre­ciso distinguir dos momentos de particular interés en su historia: su origen como fundación monástica agustiniana y su reor­ganización en el s. XIII según el estilo moderno. Se resume en al amor a Dios sin condición, que une las almas y los corazones en convivencia comunitaria de hermanos y que se difunde hacia todos los hombres para ganarlos y unirlos en Cristo dentro de su Iglesia. Los rasgos característicos de su espiritualidad son: -La búsqueda de Dios por la vía de la interioridad.    </p>

        <p className='dm-sans-regular innertext2-esp'> La interioridad agustiniana es el Espíritu en el corazón: “no quieras ir afuera, entra en ti mismo; en el hombre interior mora la verdad, y, cuando vieres que tu naturaleza es mudable, trasciéndete a ti mismo”. (La verdadera religión 72) -Vida comunitaria en fraternidad. La búsqueda de Dios y la contemplación pasan por la experiencia y adoración de Dios en los hermanos: “Vivid, pues, todos unánimes y concordes y honrad los unos a los otros a Dios, de quien sois templos vivos”. <br/>(Regla 1,9).</p>

        <p className='dm-sans-regular innertext2-esp padbot' > Servicio a la iglesia en el apostolado. La comunidad agustiniana, atenta siempre a las necesidades de la iglesia, a ejemplo de San Agustín, busca el lugar y el modo de sea más útil al servicio de Dios. La orden agustiniana trabaja en distintos campos de la pastoral: misionera, educativa, parroquial, vocacional y universitaria.   </p>






        <Slider {...settings}>
          {priest.map((father, index) => (
            <div key={index}>
              <div className='imgfor-esp'>
                <div className='part1'>

                </div>
                <div className='img-part'>
                  <img src={father.image} className='fatherpic1' />
                  <div className='fathernames'>{father.name}<br/>{father.position}</div>
                </div>

              </div>

            </div>

          ))}




        </Slider>





      </div>



      <div className='sub-body-esp3' id='ep'>
        <img src='/images/esp-img/Vector.svg' className='vector' />
        <div className='text-box3-10'>
          <span className='great-vibes-regular cursive1'>Equipo sacerdotal   <span className='poppins-regular1'></span></span>
          <p className='poppins-regular1 cursive1'>Parroquial</p>

        </div>
        <p className='dm-sans-regular text2-home'>
          Junto con la Parroquia está la comunidad agustiniana ubicada en la calle Manuel Uribe, 1. La Parroquia, principalmente, está atendida por los responsables por su oficio y enriquecida con la ayuda fraterna de otros sacerdotes que viven en la misma comunidad. Recordemos que el carisma agustiniana es la vida comunitaria y expresa la fraternidad agustiniana hacia el exterior en las participaciones litúrgicas y actividades de la Parroquia.
          <div className='pdf-download-container'>
              {equipoSacerdotalPdfList.map((pdf, index) => (
                <div key={index} className='btn-card3-esp dm-sans-bold'>
                  <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' alt='Download logo' />
                  <span onClick={() => downloadPDF(pdf)}>
                    Descargar PDF
                  </span>
                </div>
              ))}
            </div>
        </p>


      </div>

      <div className='esp-lowerbody' id='pc'>

        <div className='text-box2-esp'>
          <p className='great-vibes-regular cursive-esp'>Mensual Boletín
            <p className='poppins-regular1 twoword'>
              parroquial    </p></p>
        </div>

        <p className='dm-sans-regular innertext2-esp'>
          Aquí se puede ver el boletín parroquial que sale mensualmente y contiene, entre otras informaciones, el calendario mensual.
        </p>
      </div>

      <div className='bulletin'>
        <p className='lower-heading dm-sans-bold' id='bm'>BOLETINES GENERALES</p>


        {generalBulletins && generalBulletins.length > 0 &&
          generalBulletins.map((item, index) => {
            return (
              <div className='divborder'>
                <div className='footer-link-esp'>
                  <div className='esp-radio dm-sans-regular' onClick={()=>setBulletinActiveIndex(index)} >
                    <div>
                      <img src='/images/esp-img/crosslogo.svg' className='holycross' />{item.title}
                    </div>
                    <img src={download2 ? '/images/esp-img/-.svg' : '/images/esp-img/+.svg'} className='updown' />
                  </div>

                  {bulletinActiveIndex == index && (
                    <div className='normal dm-sans-regular'>
                      {item.description}<br />
                      <a href={item.link}>{item.link}</a>
                    </div>
                  )}

                </div>
              </div>
            )
          })}




        {/* <div className='divborder'><Link className='footer-link-esp' onClick={(event) => { Download(); event.stopPropagation() }}>
          <div className='esp-radio dm-sans-regular'>
            <div>
              <img src='/images/esp-img/crosslogo.svg' className='holycross' />
              Download Bulletin PDF
            </div>
            <img src={download ? '/images/esp-img/-.svg' : '/images/esp-img/+.svg'} className='updown' />
          </div>


          {download && (
            <div className='content-radio-esp'>
              <div className='pdf-download-container bullet'>
                {bulletinList.map((pdf, index) => (
                  <div key={index} className='btn-card33-esp dm-sans-bold'>
                    <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' alt='Download logo' />
                    <span onClick={(event) => { downloadPDF(pdf); event.stopPropagation() }}>
                      Descargar PDF
                    </span>
                  </div>
                ))}
              </div>


            </div>
          )}

        </Link>
        </div> */}
      </div>

      <div className='footer-img-esp'>

        <img src="\images\esp-img\jescross.jpeg" className='footer-imgof-esp' />



      </div>


      <div className='foot-body-esp' id='ce'>
        <div className='poppins-regular1 headingbase '>Economía</div>
        <p className='sub-text-space2 dm-sans-regular'>La economía parroquial está llevado por un grupo de laicos encabezado por el Párroco. La colaboración económica es fundamental para mantenimiento de la Iglesia. Aquí abajo podéis ver más información sobre la economía parroquial.</p>



        <div className='pdf-download-container'>
          {economiaList.map((pdf, index) => (
            <div key={index} className='btn-card3-esp dm-sans-bold'>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' alt='Download logo' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>

      </div>




      {isLoading && <LoadingSpinner />}
    </div>
  )
}

export default Espiritualidad