import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap-icons/font/bootstrap-icons.min.css";


import { UserProvider } from "./authentication/pages/UserContext"



import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import UserLayout from './user-panel/common-components/UserLayout'
import AdminLayout from './admin-panel/common-components/AdminLayout'


import Login from './authentication/pages/Login'

import WelcomePage from "./authentication/pages/WelcomePage"

import Forgotpassword from './authentication/pages/Forgotpassword';
import NewPassword from './authentication/pages/NewPassword';
import PasswordChanged from './authentication/pages/PasswordChanged';
import Resetpassword from './authentication/pages/Resetpassword';




function App() {
  return (
    <div>
     
   
   <Router>
     <UserProvider>
          <Routes>

            <Route path="/*" element={<UserLayout />} />              
            <Route exact path="/admin/*" element={<AdminLayout />} />
            <Route exact path="/admin/*" element={
              <>
                {localStorage.setItem("userRoleRequest", "admin")}
                <Navigate to="/login" />
              </>
            } />
            <Route path="welcomepage" element={<WelcomePage/>} />

            <Route path="login" element={<Login />} />
            <Route path="reset-password/*" element={<Resetpassword/>}/>
            
           
            <Route path="/forgot-password" element={<Forgotpassword />} /> 
            <Route path="/password-reset/confirm/*" element={<Resetpassword />} />
            <Route path="/password-changed" element={<PasswordChanged />} />
            
          </Routes>
          </UserProvider> 
      </Router>


   


    </div>
  )
}

export default App