import React, { useContext, useEffect, useState } from 'react'
import "./Events.css"
import { Link } from 'react-router-dom';
import API from '../../API';
import { UserContext } from '../../authentication/pages/UserContext';
import ErrorPopup from '../../ErrorPopup';
import zIndex from '@mui/material/styles/zIndex';
import { useLocation } from 'react-router-dom';
function Events({ mode }) {
    const [message, setMessage] = useState("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [showfilter, setShowfilter] = useState(false)
    const [edit, setEdit] = useState({ display: 'block' });
    const { deletest, setDeletest, deletebtn } = useContext(UserContext)
    const [events, setEvents] = useState([]);

    const [searchKey, setSearchKey] = useState("");

    const location = useLocation();
    useEffect(() => {
        if(mode=="siguientes-actividades"){
            setSearchKey("upcoming")
        }
        else{
            setSearchKey("")
        }
      
    },[ location.pathname])

    useEffect(() => {
        console.log("searchKey", searchKey)
        const fetchEvents = async () => {
            try {
                const response = await API.get(`events/?search_key=${searchKey}`);

                // Convert the time format of events
                const updatedEvents = response.data.results.map(event => ({
                    ...event,
                    start_time: convertTo12Hour(event.start_time),
                    end_time: convertTo12Hour(event.end_time)
                }));
                setEvents(updatedEvents);
            } catch (error) {

                setMessage(error.message)
                setIsErrorModalOpen(true)
            }
        };
        fetchEvents();
    }, [searchKey]);

    

    const confirmDelete = async () => {
        const deleteId = localStorage.getItem("delete_id")
        try {
            // Perform deletion operation here
            await API.delete(`events/${deleteId}`);
            // Update events after deletion
            const response = await API.get(`events/?search_key=${searchKey}`);
            setEvents(response.data.results);
            setDeletest(false);
        } catch (error) {
            setDeletest(false);
            setMessage(error.message)
            setIsErrorModalOpen(true)
        }
    };

    // Utility function to convert time to 12-hour format
    const convertTo12Hour = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(part => parseInt(part, 10));

        if (isNaN(hours) || isNaN(minutes)) {
            return '';
        }
        let ampm = 'AM';
        let adjustedHours = hours;
        if (adjustedHours >= 12) {
            ampm = 'PM';
            if (adjustedHours > 12) {
                adjustedHours -= 12;
            }
        } else if (adjustedHours === 0) {
            adjustedHours = 12;
        }
        const formattedHours = adjustedHours < 10 ? '0' + adjustedHours : adjustedHours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };


    return (
        <div>
            <div className='headerofevent'>

                <div className='titlebox-ad dm-sans-regular'><span>{mode=="siguientes-actividades"?"Siguientes Actividades":"Eventos"}</span></div>
                <div className='filterbox-ad'>
                    
                {mode!=="siguientes-actividades"&&
                        <select className='sortby dm-sans-regular' onChange={(e)=>setSearchKey(e.target.value)} value={searchKey}>
                            <option value="" className='filteropt' label='Todos los Eventos'></option>
                            <option value={"upcoming"} className='filteropt' label='Próximo evento'></option>
                            <option value={"completed"} className='filteropt' label='Evento completado'></option>
                            <option value={"today"} className='filteropt' label='Evento de hoy'> </option>
                        </select>
}
                       
                  
                    <Link to="/admin/eventsadmin/create-event" className='create'>
                        <div className='createbox' >
                            <img src='\images\admin\eventad\Plus.svg' className='crtplus' />
                            Crear evento
                        </div>
                    </Link>
                </div>

            </div>
            <div className='cardbody-ad-yellow'>
                {events.length > 0 ? (
                    events.map((event, index) => (
                        <div className='yellowcard-admin' key={index}>
                            <p className='tlt-ad poppins-regular1'>{event.title}</p>
                            <hr />
                            <p className='widecard'>
                                <span className='subtlt1 dm-sans-regular'>Fecha</span>
                                <span className='subtlt2 dm-sans-regular'>{event.date}</span>
                            </p>
                            <p className='widecard'>
                                <span className='subtlt1 dm-sans-regular'>Tiempo</span>
                                <span className='subtlt2 dm-sans-regular'>{event.start_time} to {event.end_time}</span>
                            </p>
                            <p className='widecard'>
                                <span className='subtlt1 dm-sans-regular'>Ubicación</span>
                                <span className='subtlt2 dm-sans-regular'>{event.location}</span>
                            </p>
                            <p className='widecard'>
                                <span className='subtlt1 dm-sans-regular'>Descripción</span>
                            </p>
                            <p className='subtext-ad poppins-regular2'>{event.description}</p>
                            <div className='widecard1'>
                                <Link to="edit-event" onClick={() => { localStorage.setItem("selected_id", event.id) }} style={edit} className='edit'>
                                    <div className='createbox1'>
                                        <span className='dm-sans-regular'>Editar</span>
                                    </div>
                                </Link>
                                <div className='createbox1' onClick={() => { localStorage.setItem("delete_id", event.id); deletebtn() }}>
                                    <span className='dm-sans-regular'>Eliminar</span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className='no-events-message'>¡No hay eventos!</p>
                )}
            </div>
            {deletest ? <div className='backgrd-del'>
                <div className='delete-body'>
                    <img src="\images\admin\Group 292033.svg" className='delete-logo' />
                    <p className='poppins-regular1'>¿Estás seguro de que quieres eliminar esto ?</p>



                    <button className="delete-btn poppins-regular" onClick={confirmDelete}>Sí, eliminar</button>
                    <div className="delete-discard  poppins-regular" onClick={deletebtn}>Descartar</div>
                </div>
            </div> : ""}


            <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
        </div>
    )
}

export default Events