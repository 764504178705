import React, { useContext, useState } from 'react';
import "./authentication.css"
import { Link, Navigate } from 'react-router-dom';
import Header from '../../user-panel/common-components/Header';
import { UserContext } from './UserContext';
import API from '../../API';
import { Redirect } from 'react-router-dom';
import ErrorModal from '../../ErrorModal';

function Login() {




const [showpass,setShowpass]=useState(false);

const showeye= ()=>{
  setShowpass((prevShowpass) => !prevShowpass);

}


const {showerror,setShowerror,message,setMessage, isloading,setIsloading,isOTPInputShown,errorotp,showotp,login,redirecturl,setRedirecturl,startOtpTimer,otpTimeout,otpTimer,showOTPInput } = useContext(UserContext);

const [formData, setFormData] = useState({
  username_or_email: '',
  password: ''
  
});

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData(prevFormData => ({
    ...prevFormData,
    [name]: value
  }));
};


const handleLogin = async (e) => {
  e.preventDefault();
 
      
   
    if (!isOTPInputShown) {  // for login button
      // Validate the form fields
      const validationErrors = validateForm(formData);
      setErrors(validationErrors);
     

      if (Object.keys(validationErrors).length === 0) {



        login(formData); // Call the login function from UserContext

      }
    }
    else {   //for OTP SUBMIT BUTTON.
      if (OTP[0] && OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5]) {
        setErrors({})
        login(

          {
            "user_id": window.localStorage.getItem("userID"),
            "otp": `${OTP[0]}${OTP[1]}${OTP[2]}${OTP[3]}${OTP[4]}${OTP[5]}`
          }
        )
      }
      else {
        setErrors({ otp: "Please enter 6 digits" })
      }
    }

  }; // Call the login function from UserContext

    


const validateForm = (data) => {
  const errors = {};

  if (!data.username_or_email.trim()) {
    errors.username_or_email = "username or email is required.";
  }
  else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(data.username)) {
    errors.general = "Invalid username or password" //Username must start with a letter and can only contain letters and numbers. Error details are not showing to user because security reason
  }
  else if (/^\d+$/.test(data.username_or_email)) {
    errors.general = "Invalid username or password" //username or email cannot be full of numbers.  Error details are not showing to user because security reason
  }

  if (!data.password.trim()) {
    errors.password = "Se requiere una contraseña.";
  }


  return errors;
};

const [errors, setErrors] = useState({});

const [OTP, setOTP] = useState([null, null, null, null,null,null])

const handleOTPInputChange = (e, currentIndex) => {
  console.log("e", e)
  if (currentIndex < 6) {
    // Handle backspace
    if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {


      let temp = [...OTP];
      temp[currentIndex] = null;
      setOTP(temp);

      // Move to the previous input
      if (currentIndex > 0) {
        document.getElementById(`otp${currentIndex - 1}`).focus();
      }
    } else if (/[0-9]/.test(e.target.value)) {
      // Update the current input value
      const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
      let temp = [...OTP];
      temp[currentIndex] = inputValue;
      setOTP(temp);

      // Move to the next input
      if (currentIndex < 5) {
        document.getElementById(`otp${currentIndex + 1}`).focus();
      }
    }
  }
};





  return (
    
    <div className='background-body'>
      
      <form onSubmit={handleLogin} className='form-login'>
      <div className='welcometext1 manrope-text'>¡Bienvenido de nuevo!</div><br/>
      <div className='inp1'>
        <label className='username manrope-text'>Nombre de usuario/ ID de correo electrónico</label>
        <input type='text' className='username-ad'  name='username_or_email' onChange={handleInputChange} value={formData.username} />
        {errors.username_or_email && <div className="invalid-feedback">{errors.username_or_email}</div>}
      </div>
      <div className='inp1'>
        <label className='username manrope-text'>Ingresa la contraseña</label>
        <img onClick={showeye} src='/images/admin/mainlogin-img/eye-slash.svg' className='eye' style={{ display: showpass ? 'block' : 'none' }}/>
        <img onClick={showeye} src='images/admin/mainlogin-img/eye-Filled.svg'  className='eyeshow' style={{ display: showpass ? 'none' : 'block' }}/>
        <input type={showpass ? 'text' : 'password'}  name='password' className='username-ad' onChange={handleInputChange} value={formData.password} />
        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
      </div>

      <Link className='forgetpass manrope-text'>Olvidé la contraseña?</Link>
      {isloading&& <div className='loading'><div class="loader"></div></div>}
     {showotp ?
     <div>
      <span className='manrope-text mail-bold'>Ingresa el código de verificación</span>
          <div className="otp-form">
          {[0, 1, 2, 3, 4, 5].map((index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp${index}`}
                    name={`otp${index}`}
                    value={OTP[index] || ''}
                    onChange={(e) => handleOTPInputChange(e, index)}
                    maxLength={1}
                    className="otp-input"

                  />
                ))}
          </div>
          {otpTimeout ? <div className="otp-timer expired">OTP expired! <button type='submit' className="resend-bt" onClick={() => showOTPInput(false)}>Resend OTP</button></div> : <div className="otp-timer">El código OTP expirará en {Math.floor(otpTimer / 60)}:{(otpTimer % 60) < 10 && "0"}{(otpTimer % 60)} minutos.</div>}
         
          </div>
          
         
          :""}

      <button type='submit' className='login-btn manrope-text'>Iniciar sesión</button>
      
      {errors.general && <div className="text-danger">{errors.general}</div>}
      
      </form>

      {showerror ? <div className='errormsg-log'><ErrorModal/></div>:""}
     

    </div>
 
  );
}

export default Login;

