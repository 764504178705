import React, { useContext, useEffect, useRef, useState } from 'react';
import API from '../../API';
import { UserContext } from '../../authentication/pages/UserContext';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import './Gallery.scss'

function GalleryImages() {

  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { deletest, setDeletest, deletebtn } = useContext(UserContext);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const columnCountBreakPoints = { 350: 1, 750: 1, 900: 3 }


  const loadData = () => {
    API.get(`/gallery/?page_size=1000&page=1`)
      .then(response => {
        setData(response.data.results);
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
      });
  }

  //get API
  useEffect(() => {
    loadData();
  }, []);


  // Trigger the file input click event when the button is clicked
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    let error = '';

    // Check file type restriction, file size restriction, and image resolution restriction
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSizeInBytes = 6 * 1024 * 1024; // 3MB
    const maxResolution = { width: 800, height: 800 };
    const img = new Image();
    img.src = URL.createObjectURL(file);
    // const aspectRatio = img.width / img.height;
    // const validAspectRatio = aspectRatio >= 0.9 && aspectRatio <= 1.1;
    if (!allowedTypes.includes(file.type)) {
      error = 'Only JPEG and PNG images are allowed.';
      setMessage(error);
      setIsErrorModalOpen(true);
      return;
    }

    else if (file.size > maxSizeInBytes) {
      error = 'File size exceeds the maximum limit of 6MB.';
      setMessage(error);
      setIsErrorModalOpen(true);
      return;
    }
    // else if (!validAspectRatio) {
    //     error = 'File aspect ratio(width/height) should be between 0.9 to 1.1';
    //     setMessage(error);
    // }
    // else if (img.width > maxResolution.width || img.height > maxResolution.height) {
    //   error = 'image height and width should not exceed 800px';
    //   setMessage(error);
    // }

    else {
      const formData = new FormData();
      formData.append("image", file);
      // make a POST request to the File Upload API with the FormData object and Rapid API headers
      setIsLoading(true)
      API.post("/gallery/", formData, { headers: { "Content-Type": "multipart/form-data", }, })
        .then((response) => {
          setIsLoading(false)
          loadData();
        })
        .catch((error) => {
          setMessage(error.response?.data?.message || error.message)
          setIsLoading(false);
          setIsErrorModalOpen(true)
        });
    }
  };

  const handleDelete = () => {
    setIsLoading(false);
    API.delete(`/gallery/${selectedId}/`)
      .then(response => {
        setIsLoading(false);
        setIsDeleteConfModalOpen(false);
        loadData()
      })
      .catch(error => {
        setIsDeleteConfModalOpen(false)
        setMessage(error.response?.data?.message || error.message)
        setIsLoading(false);
        setIsErrorModalOpen(true)
      });

  };


  return (
    <div>
      <div className='gallery-panel'>
        <div className='header-gallery dm-sans-regular'>
          <button onClick={handleButtonClick}>
            <span>
              <img src='/images/admin/eventad/Plus.svg' className='crtplus' alt="Add" />
              Agregar Nuevo
            </span>
          </button>
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
        </div>
        <div className='body-gallery gallery-container'>
          {data && data.length > 0 ?
            <div className='row gallery-imgs'>
              <ResponsiveMasonry columnsCountBreakPoints={columnCountBreakPoints}>
                <Masonry gutter='20px'>
                  {data.map((image, i) => (
                    <div className='img-div'>
                      <img
                        key={i}
                        src={image.image}
                        style={{ width: '100%', display: 'block' }}
                        alt={`Image ${i+1}`}
                      />
                      <img className='trash' src="/images/admin/trash.svg" alt="Trash"
                        onClick={() => { setSelectedId(image.id); setIsDeleteConfModalOpen(true) }} />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
            :
            <p>No se encontraron imágenes</p>}
        </div>


      </div>


      {isDeleteConfModalOpen && (
        <div className='backgrd-del'>
          <div className='delete-body'>
            <img src="/images/admin/Group 292033.svg" className='delete-logo' alt="Delete" />
            <p className='poppins-regular1'>¿Estás seguro de que quieres eliminar esto?</p>
            <button className="delete-btn poppins-regular" onClick={handleDelete}>Sí, eliminar</button>
            <div className="delete-discard poppins-regular" onClick={() => setIsDeleteConfModalOpen(false)}>Descartar</div>
          </div>
        </div>
      )}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  );
}

export default GalleryImages;
