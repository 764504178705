import React, { useState } from 'react';
import "./authentication.css"
import $ from 'jquery';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import API from '../../API';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';


const Forgotpassword = () => {

  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = (data) => {
    const errors = {};
    if (!data.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email address.";
    }
    return errors;
  };
  const sendMail = (e) => {
    var email = $("#email").val();
    var data = { "email": email }

    e.preventDefault();

    // Validate the form fields
    const validationErrors = validateForm(data);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      API.post("/user/password-reset/", data)
        .then((response) => {
          setIsLoading(false);
          setIsSuccessModalOpen(true)
          setMessage('Check your mail. We have sent a confirmation email.')
        }
        )
        .catch((error) =>
          {
            setIsLoading(false);
            console.log(error);
            setErrors({ "email": "Not found!" });
          }
        )
    }
  }

  return (
    <div className="container-fluid mb-3 d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card p-4">
        <h2>Olvidé la contraseña</h2>
        <form>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Correo electrónico</label>
            <input type="email" className="form-control" id="email" />
          </div>
          {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
          {/* <Link className='btn-lnk' to="/confirm-mail">Verify</Link> */}
          <div className='btn-lnk' onClick={sendMail} style={{cursor:'pointer'}}>Verify</div>
        </form>
        <div className="mt-3">
          ¿Recordaste tu contraseña? <Link to="/login">Login</Link>
        </div>
      </div>

      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/login') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />

    </div>
  );
}

export default Forgotpassword;
