import React, { useEffect, useState } from 'react';
import "./CreateEvent.css";
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';

function CreateEvent({ mode }) {
  const navigate = useNavigate();
  const id = localStorage.getItem("selected_id");

  const [formData, setFormData] = useState({
    title: "",
    location: "",
    date: "",
    start_time: "",
    end_time: "",
    description: ""
  });

  const [wordCount, setWordCount] = useState(0);
  const maxWords = 30;

  useEffect(() => {
    if (mode === 'edit' && id) {
      const fetchEvent = async () => {
        try {
          const response = await API.get(`events/${id}/`);
          const eventData = response.data;
          console.log("this is success in fetching data from API");
          console.log("response", response);

          // Ensure the date format is corrected
          let formattedDate = "";
          if (eventData.date) {
            console.log(eventData.date);
            const [day, month, year] = eventData.date.split('/');
            formattedDate = `${year}-${month}-${day}`;
            console.log("formatteddate", formattedDate);
          }

          // Set the form data with the formatted date
          setFormData({
            ...eventData,
            date: formattedDate
          });
        } catch (error) {
          console.log("API error", error);
        }
      };
      fetchEvent();
    }
  }, [mode, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "description") {
      const words = value.split(/\s+/).filter(word => word.length > 0);
      if (words.length <= maxWords) {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
        setWordCount(words.length);
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: words.slice(0, maxWords).join(' ')
        }));
        setWordCount(maxWords);
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (mode === 'create') {
        await API.post('events/', formData);
      } else if (mode === 'edit' && id) {
        await API.put(`events/${id}/`, formData);
      }
      navigate('/admin/eventsadmin');
    } catch (error) {
      console.log("API error", error);
    }
  };

  return (
    <div className='createpage'>
      <div className='headerofevent2'>
        <div className='titlebox2-ad dm-sans-regular'>
          <Link to="/admin/eventsadmin"><img src='\images\admin\eventad\Arrow_Left.svg' alt="Back" /></Link>
          <span>{mode === 'create' ? 'Crear evento' : 'Actualizar evento'}</span>
        </div>
      </div>

      <form className='craeteform dm-sans-bold' onSubmit={handleSubmit}>
        <div className='names-box'>
          <div className='eachname-ad'>
            <label>Título de la actividad</label>
            <input
              type='text'
              className='namebox-ad1'
              name='title'
              maxLength="15"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='eachname-ad'>
            <label>Ubicación</label>
            <input
              type='text'
              className='namebox-ad1'
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className='names-box'>
          <div className='eachname-ad'>
            <label>Fecha</label>
            <input
              type="date"
              className="namebox-ad1"
              name="date"
              onChange={handleInputChange}
              value={formData.date}
              required
            />
          </div>
          <div className='time-ad'>
            <div className='eachname-ad'>
              <label>Tiempo</label>
              <input
                type="time"
                name="start_time"
                className='namebox-ad122'
                value={formData.start_time}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='eachname-ad'>
              <label className='hide-ad'>d</label>
              <input
                type="time"
                name="end_time"
                className='namebox-ad122'
                value={formData.end_time}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>

        <div className='names-box'>
          <div className='eachname-ad2'>
            <label>Descripción</label>
            <textarea
              id='desp'
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className='namebox-ad2'
            ></textarea>
            <div>Recuento de palabras: {wordCount}/{maxWords}</div>
          </div>
        </div>

        <div className='btns-ad'>
          <Link to="/admin/eventsadmin" className='trans-btn'>Descartar</Link>
          <button type='submit' className='solid-btn'>{mode === 'create' ? 'Crear evento' : 'Actualizar evento'}</button>
        </div>
      </form>
    </div>
  );
}

export default CreateEvent;
