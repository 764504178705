import React, {useState, useEffect } from 'react';
import './Bulletin.css';
import { Link,useNavigate } from 'react-router-dom';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';
import API from '../../API';
import Delete from '../common-components/Delete';
import PositiveModal from '../../PositiveModal';
import $ from "jquery"


function Bulletin() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(0);

  const [newBulletinList, setNewBulletinList] = useState([]);
  const [prevBulletinList, setPrevBulletinList] = useState([]);
  const [expiredBulletinList, setExpiredBulletinList] = useState([]);

  // Load data from backend
  const loadBulletins = (searchKey, setList) => {
    setIsLoading(true)
    API.get(`bulletins-list/?search_key=${searchKey}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };
  const loadData = () => {
    loadBulletins('today', setNewBulletinList);
    loadBulletins('previous', setPrevBulletinList);
    loadBulletins('expired', setExpiredBulletinList);
  };

  // Call loadData 
  useEffect(() => {
    loadData();
  }, []);

  const formatDate = (inputDateStr) => {
    const date = new Date(inputDateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    // Return the formatted date
    return `${year}-${month}-${day}`;
  }

  // Delete data
  const handleDelete = (id) => {
    setSelectedItemID(id);
    setIsDeleteModalOpen(true);
  }
  const deleteItem = (id) => {
    setIsLoading(true)
    API.delete(`bulletins/${id}/`)
      .then(response => {
        console.log(response.data);
        setIsLoading(false)
        setIsSuccessModalOpen(true);
        setMessage('Boletín eliminado exitosamente.')
      })
      .catch(error => {
        setIsLoading(false)
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
    ;
  }

  const handleView = (bulletin) => {
    console.log(bulletin);
    const bulletin_id = bulletin.id;
    const pdf_url = bulletin.pdf_file_url;
    console.log(pdf_url,bulletin_id);
    navigate('/admin/bulletin/preview', { state: { pdf_url, bulletin_id } });
    
  }


  return (
    <div className='bulletin-bdy'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Lista de boletines</span></div>
        <Link to='createbulletin' className='create3'>
          <div className='createbox-bl dm-sans-regular'>
            <img src='\images\admin\eventad\Plus.svg' className='crtplus' alt='Plus' />
            Crear boletín
          </div>
        </Link>
      </div>
      
      <p className='dm-sans-regular insidetitle'>Nuevo boletín</p>
      {newBulletinList.map((bulletin, index) => (
        <div className='cardbox-bul dm-sans-regular' style={{ backgroundColor: 'rgba(249, 235, 174, 1)' }} key={index}>
          <span className='bodyhead-bul head-bul dm-sans-regular'>{bulletin.title}</span>
          <span className='bodyhead-bul'>Publicado: {formatDate(bulletin.created_at)}</span>
          <span className='bodyhead-bul'>Expira: {bulletin.expiry_date}</span>
          <span className='bodyhead-bul2'>
            <span className='logdiv3'><img src='\images\admin\bulletin\Subtract.svg' alt='Subtract' /></span>
            <span className='logdiv' onClick={()=>handleView(bulletin)}><img src='\images\admin\bulletin\Eye_Open.svg' alt='Eye' /></span>
            <span className='logdiv' onClick={()=>handleDelete(bulletin.id)}><img src='\images\admin\bulletin\Shape.svg' alt='Shape' /></span>
          </span>
        </div>
      ))}
      {
        newBulletinList.length==0 && <div className='w-100 text-center'> ¡No se encontraron datos! </div>
      }

      <p className='dm-sans-regular insidetitle'>Boletín anterior</p>
      {prevBulletinList.map((bulletin, index) => (
        <div className='cardbox-bul dm-sans-regular' style={{ backgroundColor: 'white' }} key={index}>
          <span className='bodyhead-bul head-bul dm-sans-regular'>{bulletin.title}</span>
          <span className='bodyhead-bul'>Publicado: {formatDate(bulletin.created_at)}</span>
          <span className='bodyhead-bul'>Expira: {bulletin.expiry_date}</span>
          <span className='bodyhead-bul2'>
            <span className='logdiv3'><img src='\images\admin\bulletin\Subtract.svg' alt='Subtract' /></span>
            <span className='logdiv2' onClick={()=>handleView(bulletin)}><img src='\images\admin\bulletin\Eye_Open.svg' alt='Eye' /></span>
            <span className='logdiv2' onClick={()=>handleDelete(bulletin.id)}><img src='\images\admin\bulletin\Shape.svg' alt='Shape' /></span>
          </span>
        </div>
      ))}
      {
        prevBulletinList.length==0 && <div className='w-100 text-center'> ¡No se encontraron datos! </div>
      }

      <p className='dm-sans-regular insidetitle'>Boletín caducado</p>
      {expiredBulletinList.map((bulletin, index) => (
        <div className='cardbox-bul dm-sans-regular' style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} key={index}>
          <span className='bodyhead-bul head-bul dm-sans-regular'>{bulletin.title}</span>
          <span className='bodyhead-bul'>Publicado: {formatDate(bulletin.created_at)}</span>
          <span className='bodyhead-bul'> Expirado: {bulletin.expiry_date}</span>
          <span className='bodyhead-bul2'>
            <span className='logdiv3'><img src='\images\admin\bulletin\Subtract.svg' alt='Subtract' /></span>
            <span className='logdiv2' onClick={()=>handleView(bulletin)}><img src='\images\admin\bulletin\Eye_Open.svg' alt='Eye' /></span>
            <span className='logdiv2' onClick={()=>handleDelete(bulletin.id)}><img src='\images\admin\bulletin\Shape.svg' alt='Shape' /></span>
          </span>
        </div>
      ))}
      {
        expiredBulletinList.length==0 && <div className='w-100 text-center'> ¡No se encontraron datos! </div>
      }

      {isDeleteModalOpen ? <Delete onSubmit={() => deleteItem(selectedItemID)} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={loadData} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  );
}

export default Bulletin;
