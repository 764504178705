import React from 'react';
import "./authentication.css"
import { Link } from 'react-router-dom';

function PasswordChanged() {
  return (
    <div className="container-fluid mb-3 d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card p-4">
        <h2>Password Changed</h2>
        <p>Your password has been successfully changed.</p>
        <div className="mt-3">
        <Link to="/login" className='btn-lnk'>Login</Link> 
        </div>
      </div>
    </div>
  );
}

export default PasswordChanged;
