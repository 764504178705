import React, { useContext, useEffect, useState } from "react";
import "./Masstime.css";
import $, { error, event } from "jquery";
import { Link, useNavigate } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import API from "../../API";
import { UserContext } from "../../authentication/pages/UserContext";
import ErrorPopup from "../../ErrorPopup";
import LoadingSpinner from "../../LoadingSpinner";
import Delete from "../common-components/Delete";



import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';


function Masstime({type}) {
  const navigate = useNavigate();

  const [selectedTimes, setSelectedTimes] = useState([null, null, null, null, null, null, null]);

  const { deletest, setDeletest, deletebtn } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [allMassList, setAllMassList] = useState([]);
  const [regularMassList, setRegularMassList] = useState([]);
  const [confessions, setConfessions] = useState([]);
  const [EucharisticAdorationList, setEucharisticAdorationList] = useState([]);
  const [specialMasses, setSpecialMasses] = useState([]);
  const [liturgiaMasses, setLiturgiaMasses] = useState([]);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const handleTimeChange = (index, newTime) => {
    if (newTime && newTime.isValid()) {
      var temp = [...selectedTimes];
      temp[index] = dayjs(newTime)
      setSelectedTimes(temp);

    }
  };






  //convertion of data


  // Add function
  const add = async (repeatationDayType, value) => {
    let time = value.format('HH:mm:ss')
    console.log("repetation daytype and time time", repeatationDayType, time)
    setIsLoading(true);
    let data = {
      mass_type: "regular",
      mass_date: null,
      location: null,
      start_time: time,
      end_time: null,
      description: null,
      repeatation_day_type: repeatationDayType,
      day_shift: null
    }
    API.post(`mass/`, data)
      .then(response => {
        setIsLoading(false);
        loadData()
      })

      .catch(error => {
        setIsLoading(false);
        setMessage(error.message);
        setIsErrorModalOpen(true);
        console.error("Error updating time:", error);

      })
  };




  const handleDeleteMass = (id) => {

    localStorage.setItem("delete_id", id);
    setIsDeleteModalOpen(true)


  }

  const confirmDelete = async () => {
    setIsLoading(true)
    try {
      // Perform deletion operation here
      await API.delete(`mass/${localStorage.getItem("delete_id")}`);
      // Update events after deletion

      setDeletest(false);
      setIsLoading(false);
      loadData()
    } catch (error) {
      setDeletest(false);
      console.log("Delete error", error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
      setIsLoading(false)
    }
  };




  //officehours API



  // Utility function to convert time to 12-hour format
  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const convertToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  // Load data from backend
  const loadMassTimes = (mass_type, day_type, setList) => {
    setIsLoading(true);
    let queryParams = [];
    if (day_type) queryParams.push(`day_type=${day_type}`);
    if (mass_type) queryParams.push(`mass_type=${mass_type}`);
    let apiUrl = `mass/?${queryParams.join('&')}`;

    API.get(`${apiUrl}`)
      .then(response => {
        
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  const loadData = () => {
    loadMassTimes(null, null, setAllMassList);
    loadMassTimes("regular", null, setRegularMassList);
    loadMassTimes("confession", null, setConfessions);
    loadMassTimes('eucharistia', null, setEucharisticAdorationList);
    loadMassTimes("special", null, setSpecialMasses);
    loadMassTimes("liturgia", null, setLiturgiaMasses);
  };

  useEffect(() => {
    loadData()
  }, [])


  // Filter Shift Times (work days)
  const filterByDaytype = (dayType, massData) => {
    return massData
      .filter((mass) => mass.repeatation_day_type === dayType && mass.start_time)
      .sort((a, b) => a.start_time.localeCompare(b.start_time)) // sort by start_time
      
  };

useEffect(()=>{
  console.log("regular mas",regularMassList)
},[regularMassList])

  // Filter Sundays and Holidays






  //sunday
  const editMass = (id) => {
    localStorage.setItem("selected_id", id)

    if(type == "liturgia"){
      navigate('/admin/liturgia-de-cada-día/edit-event-masstime');

    }
    else{
      navigate('/admin/masstime/edit-event-masstime');

    }
  }



  const convertNewlinesToBreaks = (text) => {
    if (!text) {
      return text;
    }
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };


  return (
    <div>
      <div className="headerofevent33">
        <div className="titlebox-ad dm-sans-regular">
          <span>{type=="liturgia"?"Liturgia De Cada Dia":"Hora de Misa"}</span>
          <br/>
          <span className="small">{type=="liturgia"?"(Se requieren al menos 4 elementos para mostrar en el panel de usuario.)":""}</span>
        </div>

      </div>

      {/* Section 1 */}
      <div className="card-home-me dm-sans-regular">
        {/* Work Days */}


{type!="liturgia"&&
        <div className="yellowcard-me w-100 regular-mass">
          <div className="d-flex mb-3">
            <p className="subtext-wd dm-sans-bold">Horario:</p>
            <div className="inp-box">
              <select id="day_select">
                <option value={"monday"} label="LUNES"></option>
                <option value={"teusday"} label="MARTES"></option>
                <option value={"wednesday"} label="MIÉRCOLES"></option>
                <option value={"thursday"} label="JUEVES"></option>
                <option value={"friday"} label="VIERNES"></option>
                <option value={"saturday"} label="SÁBADO"></option>
                <option value={"sunday"} label="DOMINGO"></option>
              </select>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Seleccionar hora"
                  value={selectedTimes[0]}
                  onChange={(newTime) => handleTimeChange(0, newTime)}
                  renderInput={(params) => <TextField slotProps={{ textField: { size: 'small' } }} {...params} />}
                />
              </LocalizationProvider>


              <button className="solid-btn add-btn" onClick={() => { add($("#day_select").val(), selectedTimes[0]) }} type="button">+&nbsp;Agregar</button>
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">LUNES:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("monday",regularMassList).map((item, index) => (
                 
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                  
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">MARTES:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("teusday",regularMassList).map((item, index) => (
                 
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                  
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">MIÉRCOLES:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("wednesday",regularMassList).map((item, index) => (
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">JUEVES:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("thursday",regularMassList).map((item, index) => (
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">VIERNES:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("friday",regularMassList).map((item, index) => (
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">SÁBADO:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("saturday",regularMassList).map((item, index) => (
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">DOMINGO:</p>

            <div className="time-strip-box">

              {regularMassList && regularMassList.length > 0 &&
                filterByDaytype("sunday",regularMassList).map((item, index) => (
                    <div key={index} className="time-strip">
                      <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                      <svg onClick={() => handleDeleteMass(item.id)}
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 512 512"
                      >
                        <path
                          d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                          fill="#ffffff"
                          className="fill-000000"
                        ></path>
                      </svg>
                    </div>
                ))}
            </div>
          </div>
        </div>
}
        <div className="yellowcard-me w-100 special-mass">
          <div className="d-flex mb-3">
            <p className="subtext-wd dm-sans-bold">{type!="liturgia"&&"Especial:"}</p>
            <div className="inp-box">
              <Link to="create-event-masstime" className="create3">
                <div className="createbox dm-sans-regular">
                  <img src="\images\admin\eventad\Plus.svg" className="crtplus" />
                  Agregar
                </div>
              </Link>



            </div>
          </div>

          {type!="liturgia"&&
          <div className="segment w-100">
            <div className="title">CONFESIONES</div>
            {confessions && confessions.length > 0 ?
              confessions.map((item, index) => (

                <div className="card">
                  <div className="fst-div dm-sans-regular">
                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Fecha</span>
                      <span className="mk">{item.mass_date}</span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Tiempo</span>
                      <span className="mk">
                        {item.start_time}-{item.end_time}
                      </span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold">Ubicación</span>
                      <span className="mk" >{item.location}</span>
                    </div>
                    <div className="d-flex justify-content-end">

                      <svg onClick={() => editMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                        <path d="M38.657 18.536l2.44-2.44c2.534-2.534 2.534-6.658 0-9.193-1.227-1.226-2.858-1.9-4.597-1.9s-3.371.675-4.597 1.901l-2.439 2.439L38.657 18.536zM27.343 11.464L9.274 29.533c-.385.385-.678.86-.848 1.375L5.076 41.029c-.179.538-.038 1.131.363 1.532C5.726 42.847 6.108 43 6.5 43c.158 0 .317-.025.472-.076l10.118-3.351c.517-.17.993-.463 1.378-.849l18.068-18.068L27.343 11.464z"></path>
                      </svg>


                      <svg onClick={() => handleDeleteMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>

                    </div>
                    
                  </div>
                  <div className="hr-me2"></div>
                  <div className="content-masstime">
                    <p className="font-head">Información adicional</p>
                    <p>{convertNewlinesToBreaks(item.description)}</p>
                  </div>
                </div>
              ))
              :
              <div className="empty">Esta lista está vacía</div>
            }

          </div>
}

          {/* {type!="liturgia"&&
          <div className="segment w-100">
            <div className="title">EUCHARISTIA</div>
            {EucharisticAdorationList && EucharisticAdorationList.length > 0 ?
              EucharisticAdorationList.map((item, index) => (

                <div className="card">
                  <div className="fst-div dm-sans-regular">
                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Fecha</span>
                      <span className="mk">{item.mass_date}</span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Tiempo</span>
                      <span className="mk">
                        {item.start_time}-{item.end_time}
                      </span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold">Ubicación</span>
                      <span className="mk" >{item.location}</span>
                    </div>

                    <div className="d-flex justify-content-end">

                      <svg onClick={() => editMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                        <path d="M38.657 18.536l2.44-2.44c2.534-2.534 2.534-6.658 0-9.193-1.227-1.226-2.858-1.9-4.597-1.9s-3.371.675-4.597 1.901l-2.439 2.439L38.657 18.536zM27.343 11.464L9.274 29.533c-.385.385-.678.86-.848 1.375L5.076 41.029c-.179.538-.038 1.131.363 1.532C5.726 42.847 6.108 43 6.5 43c.158 0 .317-.025.472-.076l10.118-3.351c.517-.17.993-.463 1.378-.849l18.068-18.068L27.343 11.464z"></path>
                      </svg>


                      <svg onClick={() => handleDeleteMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>

                    </div>
                  </div>
                  <div className="hr-me2"></div>
                  <div className="content-masstime">
                    <p className="font-head">Información adicional</p>
                    <p>{convertNewlinesToBreaks(item.description)}</p>
                  </div>
                </div>
              ))
              :
              <div className="empty">Esta lista está vacía</div>}

          </div>
} */}

          {type!="liturgia"&&
          <div className="segment w-100">
            <div className="title">MISAS ESPECIALES</div>
            {specialMasses && specialMasses.length > 0 ?
              specialMasses.map((item, index) => (

                <div className="card">
                  <div className="fst-div dm-sans-regular">
                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Fecha</span>
                      <span className="mk">{item.mass_date}</span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Tiempo</span>
                      <span className="mk">
                        {item.start_time}-{item.end_time}
                      </span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold">Ubicación</span>
                      <span className="mk" >{item.location}</span>
                    </div>

                    <div className="d-flex justify-content-end">

                      <svg onClick={() => editMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                        <path d="M38.657 18.536l2.44-2.44c2.534-2.534 2.534-6.658 0-9.193-1.227-1.226-2.858-1.9-4.597-1.9s-3.371.675-4.597 1.901l-2.439 2.439L38.657 18.536zM27.343 11.464L9.274 29.533c-.385.385-.678.86-.848 1.375L5.076 41.029c-.179.538-.038 1.131.363 1.532C5.726 42.847 6.108 43 6.5 43c.158 0 .317-.025.472-.076l10.118-3.351c.517-.17.993-.463 1.378-.849l18.068-18.068L27.343 11.464z"></path>
                      </svg>


                      <svg onClick={() => handleDeleteMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>

                    </div>

                    
                  </div>
                  <div className="hr-me2"></div>
                  <div className="content-masstime">
                    <p className="font-head">Información adicional</p>
                    <p>{convertNewlinesToBreaks(item.description)}</p>
                  </div>
                </div>
              ))
              :
              <div className="empty">Esta lista está vacía</div>}

          </div>
}

          {type=="liturgia"&&
            <div className="segment w-100">
            <div className="title">Liturgia De Cada Dia</div>
            {liturgiaMasses && liturgiaMasses.length > 0 ?
              liturgiaMasses.map((item, index) => (

                <div className="card">
                  <div className="fst-div dm-sans-regular">
                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Fecha</span>
                      <span className="mk">{item.mass_date}</span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold"> Tiempo</span>
                      <span className="mk">
                        {item.start_time}-{item.end_time}
                      </span>
                    </div>

                    <div className="fst-each">
                      <span className="mk2 dm-sans-bold">Ubicación</span>
                      <span className="mk" >{item.location}</span>
                    </div>

                    <div className="d-flex justify-content-end">

                      <svg onClick={() => editMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                        <path d="M38.657 18.536l2.44-2.44c2.534-2.534 2.534-6.658 0-9.193-1.227-1.226-2.858-1.9-4.597-1.9s-3.371.675-4.597 1.901l-2.439 2.439L38.657 18.536zM27.343 11.464L9.274 29.533c-.385.385-.678.86-.848 1.375L5.076 41.029c-.179.538-.038 1.131.363 1.532C5.726 42.847 6.108 43 6.5 43c.158 0 .317-.025.472-.076l10.118-3.351c.517-.17.993-.463 1.378-.849l18.068-18.068L27.343 11.464z"></path>
                      </svg>


                      <svg onClick={() => handleDeleteMass(item.id)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>

                    </div>

                    
                  </div>
                  <div className="hr-me2"></div>
                  <div className="content-masstime">
                    <p className="font-head">Título: &nbsp;{item.title}</p> 
                  </div>

                  <div className="content-masstime">
                    <p className="font-head">Información adicional</p>
                    <p>{convertNewlinesToBreaks(item.description)}</p>
                  </div>
                </div>
              ))
              :
              <div className="empty">Esta lista está vacía</div>}

          </div>
}
        </div>






      </div>




      {isDeleteModalOpen ? <Delete onSubmit={confirmDelete} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup
        message={message}
        state={isErrorModalOpen}
        setterFunction={setIsErrorModalOpen}
        okClickedFunction={() => {
          window.location.reload();
        }}
      />
    </div>
  );
}

export default Masstime;
