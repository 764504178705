import React, { useEffect, useRef, useState } from 'react';
import './Accion.css';
import Horarios from './Horarios';
import $ from 'jquery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../common-components/Header';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import { useNavigate } from 'react-router-dom';

import { HashLink as Linker } from 'react-router-hash-link';

function Accion() {

  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ParishCaritasList, setParishCaritasList] = useState([]);
  const [MisionesVoluntarList, setMisionesVoluntarList] = useState([]);
  const [CostureroList, setCostureroList] = useState([]);
  const [formattedarray, setFormattedarray] = useState([]);
  const [regularMassList, setRegularMassList] = useState([]);
  const [confessions, setConfessions] = useState([]);
  const [EucharisticAdorationList, setEucharisticAdorationList] = useState([]);
  const [specialMassess, setSpecialMasses] = useState([]);
  const [churchOpeningTimes, setChurchOpeningTimes]=useState()
  const [informacionData, setInformacionData] = useState({
    page_name: "informacion",
    section_number: "1",
    title: "",
    description: "",

})
const sliderRef = useRef(null);

  const loadMassTimes = (mass_type, day_type, setList) => {
    setIsLoading(true);
    let queryParams = [];
    if (day_type) queryParams.push(`day_type=${day_type}`);
    if (mass_type) queryParams.push(`mass_type=${mass_type}`);
    let apiUrl = `mass/?${queryParams.join('&')}`;

    API.get(apiUrl)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true);
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  const loadChurchOpeningTimes = async () => {
    try {
      const response = await API.get("church-opening-time");
      // console.log(response, "officehours");
      // const updatedOfficeHours = response.data.map((hoursdata) => ({
      //   ...hoursdata,
      //   start_time: hoursdata.start_time,
      //   end_time: hoursdata.end_time,
      // }));
       // Array of days in correct order, starting from 'Lunes'
       const weekdayOrder = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

       // Sorting function
       const sortedResults = response.data.results.sort((a, b) => {
         return weekdayOrder.indexOf(a.day) - weekdayOrder.indexOf(b.day);
       });
       setChurchOpeningTimes(sortedResults);
    } catch (error) {
      console.log(error);
    }
  };
  const loadInformacionData = async () => {
    try {
        const response = await API.get(`websitecontent/?page_name=informacion&section_number=1`);
        const eventData = response.data.results[0];
        console.log("this is success in fetching data from masstime");
        console.log("response", response);



        // Set the form data with the formatted date
        setInformacionData(eventData);
    } catch (error) {
        console.log("API error", error);
    }
};
  const loadData = () => {
    loadPDFs('Accion Social', 'Cáritas Parroquial', setParishCaritasList);
    loadPDFs('Accion Social', 'Costurero misionero parroquial', setCostureroList);
    loadPDFs('Accion Social', 'Voluntariado misionero agustiniano', setMisionesVoluntarList);
    loadMassTimes("regular", null, setRegularMassList);
    loadMassTimes("confession", null, setConfessions);
    loadMassTimes('eucharistia', null, setEucharisticAdorationList);
    loadMassTimes("special", null, setSpecialMasses);
    loadChurchOpeningTimes();
    loadInformacionData()
  };



  useEffect(() => {
    loadData();
  }, []);

  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30px',
    responsive: [
      {
        breakpoint: 1033,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnHover: true,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  const fetchEvents = async (search_key) => {
    try {
      const response = await API.get(`events/?search_key=${search_key}`);
      console.log("API success", response);
      return response.data.results.map(event => ({
        ...event,
        start_time: convertTo12Hour(event.start_time),
        end_time: convertTo12Hour(event.end_time),
        status: search_key === 'completed' ? 'completed' : 'upcoming'
      }));
    } catch (error) {
      console.log("API error", error);
      return [];
    }
  };

  useEffect(() => {
    const loadEvents = async () => {
      const upcomingEvents = await fetchEvents('upcoming');
      if (upcomingEvents.length < 5) {
        const completedEvents = await fetchEvents('completed');
        const additionalEvents = completedEvents.slice(0, 5 - upcomingEvents.length);
        setFormattedarray([...upcomingEvents, ...additionalEvents]);
      } else {
        setFormattedarray(upcomingEvents);
      }
    };
    loadEvents();
  }, []);

  const slideLeft = () => {
    sliderRef.current.slickPrev();
  };

  const slideRight = () => {
    sliderRef.current.slickNext();
  };

  const convertTo12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(part => parseInt(part, 10));
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }
    let ampm = 'AM';
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = 'PM';
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours = adjustedHours < 10 ? '0' + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  // Toggle function
  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };



  return (
    <div>
      <div className='banner-acc' id='accionsocial321' style={{ backgroundImage: `url("/images/socialaccion/Banner Update.jpg")` }}>
        <Header />
      </div>

      <div className='subbodyacc' id='caritas'>
        <p className='poppins-regular1 headingtext-acc'>Cáritas parroquial</p>
        <p className='dm-sans-regular subtextacc'>
          Cáritas son la confederación oficial de entidades de acción caritativa y social de la Iglesia católica, instituida en 1947 por la Conferencia Episcopal Española, Cáritas Española tiene por objeto la realización de la acción caritativa y social de la Iglesia en España, a través de sus miembros confederados. Vinculada a la Parroquia, promovemos el desarrollo integral de las personas y los pueblos, especialmente de los más pobres y excluidos.
        </p>
        <div className='pdf-download-container'>
          {ParishCaritasList.map((pdf, index) => (
            <div key={index} className='btn-card3-esp dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
              <span onClick={() => downloadPDF(pdf)}>Descargar PDF</span>
            </div>
          ))}
        </div>
      </div>

      <Horarios regularMassList={regularMassList} confessions={confessions} specialMassess={specialMassess} EucharisticAdorationList={EucharisticAdorationList} churchOpeningTimes={churchOpeningTimes} informacionData={informacionData}/>

      <div className='poppins-regular1  heading-horo'>Siguiente Actividad</div>
      <div className="slider-container1">
        <Slider ref={sliderRef} {...settings}>
          {formattedarray.map((event, index) => (
            <div key={index}>
              <div className='card-acc'>


                <div className='img-card-acc'>
                  <img src='/images/socialaccion/Event -Bible.svg' className='booklog' />
                </div>
                <div className='cardhead-acc dm-sans-bold '>{event.title}</div>



                <div className='paracard'>
                  {expandedIndex === index ? event.description : `${event.description.slice(0, 30)}...`}
                  <span className='read-more-acc' onClick={() => toggleReadMore(index)}>
                    {expandedIndex === index ? 'Leer menos' : 'Leer más'}
                  </span>
                </div>



                <div className='cardbody-acc'>
                  <div className='cardline-acc'>
                    <span className='sp2'>Fecha </span>
                    <span className='sp3'>{event.date}</span>
                  </div>
                  <div className='cardline-acc'>
                    <span className='sp2'>Tiempo
                    </span>
                    <span className='sp3'>{event.start_time} - {event.end_time}</span>
                  </div>
                  <div className='cardline-acc'>
                    <span className='sp2'>Ubicación</span>
                    <span className='sp3'> {event.location}</span>
                  </div>
                </div>
                <hr className='hr2' />
                <div className={`btn-card3-acc dm-sans-bold ${event.status === 'completed' ? 'completed' : ''}`} onClick={() => {
                  if (event.status !== 'completed') {
                    localStorage.setItem("event_id", event.id);
                    window.scrollTo(0, 0);
                    navigate("/reg")
                  }
                }}>
                  {event.status === 'completed' ? 'COMPLETADO' : 'ÚNETE'}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='arrows-slide'>
          <div className='arleft' onClick={slideLeft}>
            <img src="\images\socialaccion\Left arrow.svg" className='ar' />
            <img src="\images\socialaccion\Left arrow.svg" className='arvlt' />
          </div>
          <div className='arright' onClick={slideRight}>
            <img src="\images\socialaccion\Right Arrow 1.svg" className='arvlt2' />
            <img src="\images\socialaccion\Right Arrow 1.svg" className='ar2' />
          </div>
        </div>
      </div>

      <div className='first-body-acc' id='costurero'>
        <div className='first-body-text-acc'>
          <p className='great-vibes-regular heading-lit'>Costurero<p className='width55 poppins-regular1'> misionero parroquial</p></p>
          <div className='disp-social'>
            <div className='img-disp'>
              <img src='images/socialaccion/Rectangle 670.png' className='disp-img' />
            </div>
            <div className='disp-text'>
              <p className='dm-sans-regular innerparagraph subtext-lit1'>
                se trata de un equipo de mujeres de la Parroquia que se reúne una vez por semana para darle a la aguja, al hilo y a la tela y al recaudar el dinero vendiendo los materiales elaborados colaboran con diferentes proyectos de las misiones.
              </p>
              <div className='pdf-download-container costurero'>
                {CostureroList.map((pdf, index) => (
                  <div key={index} className='btn-card3-esp dm-sans-bold '>
                    <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
                    <span onClick={() => downloadPDF(pdf)}>Descargar PDF</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='first-body-acc' id='voluntariado'>
        <div className='first-body-text-acc'>
          <p className='great-vibes-regular heading-lit'>Voluntariado<p className='width556 poppins-regular1'>  misionero agustiniano </p><p className='width556 poppins-regular1'>  </p></p>
          <div className='disp-social2'>
            <div className='disp-text2'>
              <p className='dm-sans-regular innerparagraph subtext-lit18'>
                El Voluntariado Misionero se trata de tener una experiencia misionera al estilo agustiniano y es un tiempo en el que las personas participantes tienen una experiencia de vida común en grupo en contacto directo con una comunidad agustiniana en una Misión de cualquier de los países que está presente la Provincia Agustiniana de San Juan de Sahagún.<br />
                <a href='https://agustinos.es/ongs-y-voluntariado/ '>agustinos.es.ongs-y-voluntariado </a>
              </p>
              <div className='pdf-download-container volunteer'>
                {MisionesVoluntarList.map((pdf, index) => (
                  <div key={index} className='btn-card3-esp dm-sans-bold '>
                    <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
                    <span onClick={() => downloadPDF(pdf)}>Descargar PDF</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='img-disp'>
              <img src='images/socialaccion/Rectangle 670 (1).png' className='disp-img' />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default Accion;
