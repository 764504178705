import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
import Navbar from './Navbar';
import { UserContext } from '../../authentication/pages/UserContext';
import { Link } from 'react-router-dom';

function Header() {
  const [btnenglish, setBtnenglish] = useState({ color: '#D9D9D9', fontcolor: 'black' });
  const [btnspanish, setBtnspanish] = useState({ color: '#751423', fontcolor: 'white' });
  const { tooglemenu, setTooglemenu,isFlipped,setIsFlipped } = useContext(UserContext);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      
    };
  }, []);

  const handleClickOutside = (event) => {
    const navbarBody = document.getElementById('navbar-body');
    if (navbarBody && !navbarBody.contains(event.target) && !event.target.classList.contains('ham-button')) {
      setTooglemenu(false);
    }
  };

  const english = () => {
    setBtnenglish({ color: '#751423', fontcolor: 'white' });
    setBtnspanish({ color: '#D9D9D9', fontcolor: 'black' });
  };

  const espanol = () => {
    setBtnspanish({ color: '#751423', fontcolor: 'white' });
    setBtnenglish({ color: '#D9D9D9', fontcolor: 'black' });
  };

  const toggleMenu = () => {
    setTooglemenu(!tooglemenu);
  };

  const handleScroll = () => {
    setTooglemenu(false); // Close menu on scroll
  };

  const closeMenu = () => {
    setTooglemenu(false);
  };

  
  

  return (
    <div className='header-main'>
      <Link to="/home">
      <img src='/images/saintlogo.svg' className={`logo-header ${isFlipped ? 'flipped' : ''}`}
 alt='logo' />
 </Link>
      <div className='tail-button-user'>
        <img src='/images/burger-list-menu-navigation-svgrepo-com 1.svg' alt='user' height='50px' className='ham-button' onClick={toggleMenu} />

        
      </div>
      {tooglemenu ? (
  <div className='menu-position' id='navbar-body'>
    <Navbar />
  </div>
) : null}
    </div>
  );
}

export default Header;
