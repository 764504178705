import React, { useState, useEffect } from 'react'
import "./Permission.css"
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../../API';
import ErrorPopup from '../../ErrorPopup';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';


function Permission() {
  const navigate = useNavigate();
  const location = useLocation();

  const { userData, isEdit } = location.state || {};
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [permissionList, setPermissionList] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setIsLoading(true);
      try {
        const response = await API.get(`user/assign_permissions/${userData.id}/`);
        setPermissionList(response.data.permissions);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching permissions:', error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      }
    };

    if (isEdit && userData && userData.id) {
      fetchPermissions();
    }
  }, [isEdit, userData]);

  // const handleCheckBoxChange = (permission) => {
  //   if (permissionList.includes(permission)) {
  //     setPermissionList(permissionList.filter(p => p !== permission));
  //   } else {
  //     setPermissionList([...permissionList, permission]);
  //   }
  // };

  const permissionMap = {

    modify_websitecontent: "view_websitecontent",
    modify_pdf: "view_pdf",
    modify_mass: "view_mass",
    modify_events: "view_event",
    modify_reservation: "view_reservation",
    modify_messages: "view_contactmessage",
    modify_bulletin: "view_bulletin"
  };



  // Function to update permission list
  const updatePermissionList = (permissions, map) => {
    const updatedPermissions = [...permissions]; // Create a copy of the original permissions array
    Object.entries(map).forEach(([modifyPermission, viewPermission]) => {
      if (permissions.includes(modifyPermission) && !permissions.includes(viewPermission)) {
        updatedPermissions.push(viewPermission); // Add the missing view permission
      }
    });
    updatedPermissions.sort(); // Sort the updated permissions
    return updatedPermissions;
  };

  // Handle checkbox change
  const handleCheckBoxChange = (permission) => {
    let updatedPermissions = [...permissionList];

    if (permission.startsWith('modify_')) {
      // Toggle modify permission
      if (updatedPermissions.includes(permission)) {
        updatedPermissions = updatedPermissions.filter(p => p !== permission);
      } else {
        updatedPermissions.push(permission);
      }

      // Toggle corresponding view permission
      const viewPermission = permissionMap[permission];
      if (viewPermission) {
        if (updatedPermissions.includes(permission)) {
          // If modify permission is selected, ensure view permission is also selected
          if (!updatedPermissions.includes(viewPermission)) {
            updatedPermissions.push(viewPermission);
          }
        } else {
          // If modify permission is deselected, deselect view permission if no other modify is selected
          const otherModifyPermissions = Object.keys(permissionMap).filter(key => key.startsWith('modify_') && key !== permission);
          const isOtherModifySelected = otherModifyPermissions.some(modify => updatedPermissions.includes(modify));
          if (!isOtherModifySelected) {
            updatedPermissions = updatedPermissions.filter(p => p !== viewPermission);
          }
        }
      }
    }

    if (permission.startsWith('view_')) {
      // Toggle view permission
      if (updatedPermissions.includes(permission)) {
        updatedPermissions = updatedPermissions.filter(p => p !== permission);
      } else {
        updatedPermissions.push(permission);
      }
    }
    updatedPermissions.sort();
    setPermissionList(updatedPermissions);
  };


  const handleSubmit = () => {
    const formData = { permissions: updatePermissionList(permissionList, permissionMap) }
    const method = isEdit ? 'put' : 'post';
    // console.log(formData,method);
    setIsLoading(true);
    API[method](`user/assign_permissions/${userData.id}/`, formData)
      .then((response) => {
        setIsLoading(false);
        setIsSuccessModalOpen(true);
        setMessage(isEdit ? 'Permisos de usuario actualizados exitosamente.' : 'Permisos de usuario agregados exitosamente.')
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true)
        if (error.response && error.response.data) {
          setMessage(error.response.data);
        } else {
          setMessage(error.message);
        }
      });

  }

  return (
    <div className='permissionbody'>

      <div className='headerofevent11'>
        <div className='titlebox-ad dm-sans-regular'><span>Permisos de Página</span></div>
      </div>

      <div className='whitebox-adlist'>
        <div className='heading-adlist'>
          <div className='name-adlist dm-sans-bold '>
            <span>{userData && `${userData.first_name} ${userData.last_name}`}</span>
            <span>{userData && `${userData.email}`}</span>
          </div>


          {/* <a href='https://mail.google.com/' className='create3'>
            <div className='createbox-adlist dm-sans-regular'>
              <img src='\images\admin\eventad\Plus.svg' className='crtplus' />
              Send Message
            </div>
          </a> */}


        </div>


        <div className='ashback-adlist  dm-sans-regular'>
          <div className='head-adlist'>
            <p className='page'>Páginas</p>
            <span className='vm'>Ver</span>
            <span className='vm'>Modificar</span>
          </div>
          <div className='hr55'></div>

          <div className='content-adlist'>
            <p className='page1'>PDFs del Sitios Web</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_pdf')}
                  onClick={() => handleCheckBoxChange('view_pdf')} />
                <span class="checkmark"></span>
              </label>
            </span>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_pdf')}
                  onChange={() => handleCheckBoxChange('modify_pdf')} />
                <span class="checkmark"></span>
              </label>
            </span>

          </div>
        
          <div className='hr55'></div>

          
          <div className='content-adlist'>
            <p className='page1'>Páginas del Sitio Web</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_websitecontent')}
                  onClick={() => handleCheckBoxChange('view_websitecontent')} />
                <span class="checkmark"></span>
              </label>
            </span>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_websitecontent')}
                  onChange={() => handleCheckBoxChange('modify_websitecontent')} />
                <span class="checkmark"></span>
              </label>
            </span>

          </div>
        
          <div className='hr55'></div>

          <div className='content-adlist'>
            <p className='page1'>Hora de Misa</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_mass')}
                  onChange={() => handleCheckBoxChange('view_mass')} />
                <span class="checkmark"></span>
              </label>
            </span>


            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_mass')}
                  onChange={() => handleCheckBoxChange('modify_mass')} />
                <span class="checkmark"></span>
              </label>

            </span>

          </div>
          <div className='hr55'></div>


          <div className='content-adlist'>
            <p className='page1'>Hora del evento</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_event')}
                  onChange={() => handleCheckBoxChange('view_event')} />
                <span class="checkmark"></span>
              </label>
            </span>


            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_events')}
                  onChange={() => handleCheckBoxChange('modify_events')} />
                <span class="checkmark"></span>
              </label>

            </span>

          </div>
          <div className='hr55'></div>


          <div className='content-adlist'>
            <p className='page1'>Lista de usuarios</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_reservation')}
                  onChange={() => handleCheckBoxChange('view_reservation')} />
                <span class="checkmark"></span>
              </label>
            </span>


            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_reservation')}
                  onChange={() => handleCheckBoxChange('modify_reservation')} />
                <span class="checkmark"></span>
              </label>

            </span>

          </div>
          <div className='hr55'></div>



          <div className='content-adlist'>
            <p className='page1'>Mensajes</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_contactmessage')}
                  onChange={() => handleCheckBoxChange('view_contactmessage')} />
                <span class="checkmark"></span>
              </label>
            </span>


            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_messages')}
                  onChange={() => handleCheckBoxChange('modify_messages')} />
                <span class="checkmark"></span>
              </label>

            </span>

          </div>
          <div className='hr55'></div>

          <div className='content-adlist'>
            <p className='page1'>Boletín</p>
            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('view_bulletin')}
                  onChange={() => handleCheckBoxChange('view_bulletin')} />
                <span class="checkmark"></span>
              </label>
            </span>


            <span className='vm1'>
              <label class="custom-checkbox">
                <input type="checkbox" checked={permissionList.includes('modify_bulletin')}
                  onChange={() => handleCheckBoxChange('modify_bulletin')} />
                <span class="checkmark"></span>
              </label>

            </span>

          </div>




        </div>
        <div className='btn-adlist dm-sans-regular'>

          <Link to="/admin/adminlist" className='trnsbtn-adlist'>

            Descartar

          </Link>
          <div className='solidbtn-adlist' onClick={handleSubmit}>
            Dar Permisos
          </div>


        </div>

      </div>


      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/adminlist') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />


    </div>
  )
}

export default Permission