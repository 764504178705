import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import BulletinPreview from './BulletinPreview';
import { Description } from '@mui/icons-material';
import axios from 'axios';


function OrdenSacerdotalYLaVocaciónAgustiniana() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [isImgLoading, setIsImgLoading] = useState(false);


  const [data, setData]=useState({});
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);


  const loadData=()=>{
    API.get(`/websitecontent/?page_name=liturgy&section_number=6`)
    .then(response => {
      if (response.data && response.data.results) {
        setData(response.data.results[0]);
      } else {
        console.log('No result field in API response.');
        setMessage("No data to show");
        setIsErrorModalOpen(true);
      }
    })
    .catch(error => {
      console.log(error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
    });
  }
  useEffect(() => {
    loadData()
  }, []);

  

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));

  };
 

 

  const validateForm = (data) => {
    const errors = {};
    
    if (!data.description.trim()) {
      errors.description = "El contenido es obligatorio.";
    }
     
    return errors;
  };

  // Crear boletín Data
  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure programs are updated in formData before validating
   
    // Validate form data
    const validationErrors = validateForm(data);
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
     
      API.put(`/websitecontent/${data.id}/`, data)
        .then((response) => {
          setIsLoading(false);
         navigate("/admin/home") 
          
          // Return a promise that resolves when all image upload promises have resolved
          
        })
        
        
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true)

          setMessage(error.message);
        });

    }
  };



 



  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Orden Sacerdotal Y La Vocación Agustiniana</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>
        

        <div className='title-cb'>
          <label className='lbl-cb'>Descripción</label>
          <textarea type='text' className='title-inp-cb2' name='description' onChange={handleInputChange} value={data.description} />
          {errors.description && <p className='invalid-feedback'>{errors.description}</p>}
        </div>

     

        <div className='btn-cb d-none'>
          <button className='btn-save-cb'  >
          Guardar
          </button>
        </div>

        <div className='hrdashed' />

   
        <div className='btn-cb-savedis'>
          <Link to="/admin/boletines-generales" className="discard-cb">
          Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Guardar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default OrdenSacerdotalYLaVocaciónAgustiniana;
