import React, { useContext, useEffect, useRef, useState } from 'react'
import "./WebsitePDFs.css"
import "./WebsiteContent.css"
import $ from "jquery"
import { UserContext } from '../../authentication/pages/UserContext';
import Delete from "../common-components/Delete"

import { Document, Page } from 'react-pdf';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import { useNavigate } from 'react-router-dom';


function Inicilar() {
  const navigate = useNavigate();
  const maxSize = 20 * 1024 * 1024;

  const [pageData, setPageData] = useState([
    {
      page_name: 'Inicio',
      sub_pages: [
        {
          sub_name: ' Inicio sección 3',
          link: "/admin/home-sec-3"
        },
        {
          sub_name: ' Oración a la Virgen María',
          link: "/admin/st-marria-prayer"
        },
        {
          sub_name: ' Oración a la Santa Rita',
          link: "/admin/st-rita-prayer"
        },
        {
          sub_name: 'Siguientes Actividades',
          link: "/admin/siguientes-actividades"
        },
        {
          sub_name: 'Liturgia de cada día',
          link: "/admin/liturgia-de-cada-día"
        },
        {
          sub_name: 'Reflexiones sobre Lecturas del Domingo ',
          link: "/admin/lectura-del-domingo"
        },
        {
          sub_name: 'Galería',
          link: "/admin/galleria"
        },
        {
          sub_name: 'PALABRAS DEL MES',
          link: "/admin/palabras-del-mes"
        },
      ]
    },
    {
      page_name: 'Parroquia',
      sub_pages: [
        {
          sub_name: 'lema pastoral Parroquial',
          link: "/admin/parroquia/pastroal-parroquia"
        },
        {
          sub_name: 'Apertura de la lglesia',
          link: "/admin/apertura-de-la-iglesia"
        },
        {
          sub_name: 'Miembros',
          link: "/admin/members"
        },
        {
          sub_name: 'BOLETINES GENERALES',
          link: "/admin/boletines-generales"
        },
      ]
    },
    {
      page_name: 'Liturgia',
      sub_pages: [
        {
          sub_name: 'Eucaristía',
          link: "/admin/eucaristia"
        },
        {
          sub_name: 'Confesión',
          link: "/admin/Confesión"
        },
        {
          sub_name: 'Unción de los Enfermos',
          link: "/admin/uncion-de-los-enfermos"
        },
        {
          sub_name: 'Orden sacerdotal y la Vocación Agustiniana',
          link: "/admin/oden-sacerdotal-y-la-vocación-agustiniana"
        },
        
       
      ]
    },
    {
      page_name: 'Pastoral Social',
      sub_pages: [
        {
          sub_name: 'Horario',
          link: "/admin/masstime"
        },
        {
          sub_name: 'Atención',
          link: "/admin/masstime"
        },
        {
          sub_name: 'Información',
          link: "/admin/informacion"
        },
        {
          sub_name: 'Siguiente Actividad',
          link: "/admin/eventsadmin"
        }
      ]
    },

    {
      page_name:"Groupos",
      sub_pages:[
        {
          sub_name: 'todos los jueves ADORACIÓN',
          link: "/admin/todos-los-jueves-adoracion"
        },
        
      ]
    },
    {
      page_name: 'Horario de misas',
      sub_pages: [
        {
          sub_name: 'Horario de misas',
          link: "/admin/masstime"
        },

        {
          sub_name: 'Actividades',
          link: "/admin/eventsadmin"
        },
       
      ]
    },
    {
      page_name: 'Despacho',
      sub_pages: [
        {
          sub_name: 'La entrada por la calle Manuel Uribe 1',
          link: "/admin/office-hours/list"
        },
       
      ]
    },
    {
      page_name: 'Eventos',
      sub_pages: [
        {
          sub_name: 'Horario Eventos',
          link: "/admin/masstime"
        },
        
        
      ]
    },
   
    
      {
        page_name: 'Galleria',
        sub_pages: [
          {
            sub_name: 'Galleria',
            link: "/admin/galleria"
          },
          
        ]
      },
     
      
     
  ])


  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(0);

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  
  

 


  // to toggle section
  const [openPages, setOpenPages] = useState(Array(pageData.length).fill(true));
  const togglePageSection = (index) => {
    setOpenPages(prevOpenPages =>
      prevOpenPages.map((isOpen, i) =>
        i === index ? !isOpen : isOpen
      )
    );
  };

  


 


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])





  return (
    <div className={`in-body`}>
      {/* Iterate through Page divisions */}
      {pageData.map((page, pageIndex) => (
        <>
          <p className=' gal dm-sans-regular'>{page.page_name}  <img src="\images\admin\inicilar\Vector (4).svg" className='downar-par' onClick={() => togglePageSection(pageIndex)} /></p>
         
            <div className='main-gal-in'>
              {/* Iterate through Sub divisions */}
              {page.sub_pages.map((sub_page, subPageIndex) => (

                <div className='gallery1'>

                  <div className='gal1' key={subPageIndex}>
                    <div className='firstwide50'>
                      <p className='dm-sans-regular insidetitle'>{sub_page.sub_name}</p>
                    </div>
                    <div className='first2wide50'>
                    <button onClick={()=>navigate(sub_page.link)} className='go-and-edit-btn'  >
                    editar
                      </button>

                     
                    </div>

                  </div>

                  


                </div>
              ))}

            </div>
        

        </>
      ))}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  )
}

export default Inicilar