// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-pdf{
    width: 100%;
    padding: 0 20px;
}
.website-pdf .top-sec{
    padding-bottom: 30px;
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 31.25px;
    letter-spacing: -0.03em;
    display: flex;
    gap: 10px;
}
.website-pdf .top-sec span{
    font-weight: 400;
}
.back-btn{
    cursor: pointer;
}

.go-and-edit-btn{
    
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/PreviewPDF.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,uBAAuB;IACvB,aAAa;IACb,SAAS;AACb;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;;AAEA;;AAEA","sourcesContent":[".website-pdf{\n    width: 100%;\n    padding: 0 20px;\n}\n.website-pdf .top-sec{\n    padding-bottom: 30px;\n    font-family: DM Sans;\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 31.25px;\n    letter-spacing: -0.03em;\n    display: flex;\n    gap: 10px;\n}\n.website-pdf .top-sec span{\n    font-weight: 400;\n}\n.back-btn{\n    cursor: pointer;\n}\n\n.go-and-edit-btn{\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
