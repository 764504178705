import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';

function Resetpassword() {
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch token from URL
  const location = useLocation();
  const { hash, pathname, search } = location;
  const url = new URL(window.location.href);

  // Get the 'token' parameter from the URL
  // const pswdResetTocken = url.searchParams.get('token');
  const pathSegments = pathname.split('/');
  const pswdResetTocken = pathSegments[pathSegments.length - 2];

  // Validation
  const validateForm = (data) => {
    const errors = {};
    if (!data.password.trim()) {
      errors.password = "Se requiere una contraseña.";
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters.";
    } else {
      // Password must contain at least one letter, one number, and one special character
      if (!/[a-zA-Z]/.test(data.password)) {
        errors.password = "Password must contain at least one letter.";
      } else if (!/\d/.test(data.password)) {
        errors.password = "Password must contain at least one number.";
      } else if (!/[!@#$%^&*]/.test(data.password)) {
        errors.password = "Password must contain at least one special character (!@#$%^&*).";
      }
    }
    if (!data.confirm_password.trim()) {
      errors.password = "Confirmar contraseña is required.";
    } else if (data.password !== data.confirm_password) {
      errors.password = "Passwords do not match.";
    }
    return errors;
  };

  const [showpass, setShowpass] = useState(false);
  const [showpass2, setShowpass2] = useState(false);

  const showeye = () => {
    setShowpass((prevShowpass) => !prevShowpass);
  }
  const showeye2 = () => {
    setShowpass2((prevShowpass2) => !prevShowpass2);
  }
  const [formData, setFormData] = useState({

    password: '',
    confirm_password: ''

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleReset = (e) => {
    e.preventDefault();
    // Validate the form fields
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      API.put(`/user/password-reset/confirm/${pswdResetTocken}/`, { password: formData.password })
        .then((response) => {
          setIsLoading(false);
          navigate('/password-changed')
        }
        )
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setMessage("something went wrong, try again")
          setIsErrorModalOpen(true)
        }
        )
    }
  }



  return (
    <div className='background-body'>
      <form onSubmit={handleReset} className='form-login'>
        <div className='welcometext1 manrope-text'>Reset Password</div><br />

        <div className='inp1'>

          <label className='username manrope-text'>Ingresa la contraseña</label>
          <img onClick={showeye} src='/images/admin/mainlogin-img/eye-slash.svg' className='eye' style={{ display: showpass ? 'block' : 'none' }} />
          <img onClick={showeye} src='images/admin/mainlogin-img/eye-Filled.svg' className='eyeshow' style={{ display: showpass ? 'none' : 'block' }} />
          <input type={showpass ? 'text' : 'password'} name='password' className='username-ad' onChange={handleInputChange} value={formData.password} required />

        </div>
        <div className='inp1'>
          <label className='username manrope-text'>Confirmar contraseña</label>
          <img onClick={showeye2} src='/images/admin/mainlogin-img/eye-slash.svg' className='eye' style={{ display: showpass2 ? 'block' : 'none' }} />
          <img onClick={showeye2} src='images/admin/mainlogin-img/eye-Filled.svg' className='eyeshow' style={{ display: showpass2 ? 'none' : 'block' }} />
          <input type={showpass2 ? 'text' : 'password'} name='confirm_password' className='username-ad' onChange={handleInputChange} value={formData.confirm_password} required />
        </div>
        {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}

        <button type='submit' className='login-btn manrope-text'>Reset</button>



      </form>

      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/login') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />


    </div>
  )
}

export default Resetpassword