import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import BulletinPreview from './BulletinPreview';
import { Description } from '@mui/icons-material';
import axios from 'axios';


function CreateBulletin() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [isImgLoading, setIsImgLoading] = useState(false);


  const [parroquiaExtraData, setParroquiaExtraData] = useState({
    "id": 4,
    "page_name": "parroquia",
    "section_number": 2,
    "title": "",
    "description": "sample text",
    "image": null
  });
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);


  const loadParroquiaExtraData = () => {
    API.get(`/websitecontent/?page_name=parroquia&section_number=2`)
      .then(response => {
        if (response.data && response.data.results) {
          setParroquiaExtraData(response.data.results[0]);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }
  useEffect(() => {
    loadParroquiaExtraData()
  }, []);





  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setParroquiaExtraData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));

  };




  const validateForm = (data) => {
    const errors = {};

    if (!data.description.trim()) {
      errors.description = "El contenido es obligatorio.";
    }

    return errors;
  };

  // Crear boletín Data
  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure programs are updated in formData before validating

    // Validate form data
    const validationErrors = validateForm(parroquiaExtraData);
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
let descriptionOnlyData={"description":parroquiaExtraData.description}
      API.put(`/websitecontent/${parroquiaExtraData.id}/`, descriptionOnlyData)
        .then((response) => {
          setIsLoading(false);
          navigate("/admin/home")

          // Return a promise that resolves when all image upload promises have resolved

        })


        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true)

          setMessage(error.message);
        });

    }
  };



  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    // Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const validAspectRatio = aspectRatio >= 0.5 && aspectRatio <= .9;

      console.log("image dimentions",img.width,img.height)

      if (img.width == 399 && img.height == 583 && validAspectRatio) {
        console.log("image dimentions",img.width,img.height)
        if (file.size <= 300 * 1024) { // Max size in bytes (300 KB)
          setImageError(null)
          setIsImgLoading(true);
          setPreviewImage(URL.createObjectURL(file));

          const formData = new FormData();
          formData.append('image', file);

          API.put(`websitecontent/${parroquiaExtraData.id}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
            .then((res) => {
              setMessage("¡Imagen de perfil actualizada con éxito!")
              setIsMessageModalOpen(true)
              setIsImgLoading(false);
              loadParroquiaExtraData();
            })
            .catch((error) => {
              setIsImgLoading(false);
              console.error('Error al subir la imagen:', error);
            });
        }
        else {
          setImageError('El tamaño del archivo de imagen es demasiado grande');
        }
      } else {
        setImageError('Las dimensiones de la imagen no están dentro de los límites especificados');
      }
    };
  };



  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Lema Pastoral parroquial</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>


        <div className='title-cb'>
          <label className='lbl-cb'>Descripción</label>
          <textarea type='text' className='title-inp-cb2 mb-3'  name='description' onChange={handleInputChange} value={parroquiaExtraData && parroquiaExtraData.description}   maxLength={200}/>
          {errors.description && <p className='invalid-feedback'>{errors.description}</p>}
<img src={parroquiaExtraData.image}></img>
          <input type='file' onChange={(e) => handleImageSelect(e)} />
          <div className='text-success'>Tamaño máximo de archivo: 300KB, Ancho = 399px, Altura = 583px, Tipo de archivo = png</div>
          {imageError && <div className='text-danger'>{imageError}</div>}
        </div>



        <div className='btn-cb d-none'>
          <button className='btn-save-cb'  >
          Guardar
          </button>
        </div>

        <div className='hrdashed' />


        <div className='btn-cb-savedis'>
          <Link to="/admin/boletines-generales" className="discard-cb">
            Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Guardar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default CreateBulletin;
