import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import { Navigate, Route, Routes } from 'react-router-dom'

import "./AdminLayout.css"
import { UserContext } from '../../authentication/pages/UserContext'
import WebsitePDFs from '../pages/WebsitePDFs'
import WebsiteContent from '../pages/WebsiteContent'

import Events from '../../admin-panel/pages/Events'
import CreateEvent from '../pages/CreateEvent'
import EditEvents from '../pages/EditEvents'
import Eucaristia from '../pages/Eucaristia'
import Masstime from '../pages/Masstime'
import CreateMasstime from '../pages/CreateMasstime'
import HomeSection3 from '../pages/HomeSection3'
import OracionALaSantaMaria from '../pages/OracionALaSantaMaria'
import OracionASantaRita from '../pages/OracionASantaRita'
import LecturaDelDomingo from '../pages/LecturaDelDomingo'
import PalabrasDelMes from '../pages/PalabrasDelMes'
import Sunday from '../pages/Sunday'
import Informacion from '../pages/Informacion'

import OfficeHour from '../pages/OfficeHour'
import AperturaDeLaIglesia from '../pages/AperturaDeLaIglesia'
import Userlist from '../pages/Userlist'
import Message from '../pages/Message'
import  Settings  from '../pages/Settings'
import AdminList from '../pages/AdminList'
import Permission from '../pages/Permission'

import Bulletin from '../pages/Bulletin'
import CreateBulletin from '../pages/CreateBulletin'

import BulletinGeneral from '../pages/BulletinGeneral'
import CreateBulletinGeneral from '../pages/CreateBulletinGeneral'

import { Link } from 'react-router-dom'
import Registration from '../pages/Registration'
import Resetpassword from '../../authentication/pages/Resetpassword'
import WebsitePdf from "../pages/PreviewPDF"
import BulletinPreview from '../pages/BulletinPreview'
import Eucaristic from '../pages/Eucaristic'
import Members from '../pages/Members'
import TodosLosJuevesAdoracion from '../pages/TodosLosJuevesAdoracion'

import PastroalParroquia from '../pages/PastroalParroquia'
import Gallery from '../pages/Gallery'

import Confesion from '../pages/Confesion'
import UncionDeLosEnfermos from '../pages/UncionDeLosEnfermos'
import OrdenSacerdotalYLaVocacionAgustiniana from '../pages/OrdenSacerdotalYLaVocacionAgustiniana'



function AdminLayout() {

  const {gotologin,isLoggedIn,isMobileMenuOpen, setMobileMenuOpen } =useContext(UserContext);
  

  
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };


 
  const handleClickOutside = (event) => {
    const sideNavbar = document.getElementById("sideNavbar");
    if (sideNavbar && !sideNavbar.contains(event.target) && !event.target.classList.contains("nav-toggle")) {
      setMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  useEffect(() => {
    const sideNavbar = document.getElementById("sideNavbar");
    if (sideNavbar){
      if (window.innerWidth < 1025) {
        if (isMobileMenuOpen) {
          setTimeout(() => {
            sideNavbar.classList.add("open");
          }, 100); // Add a 100ms delay before adding the class
        } else {
          setTimeout(() => {
            sideNavbar.classList.remove("open");
          }, 100); // Add a 100ms delay before removing the class
        }
      }
    }
    
  }, [isMobileMenuOpen]);



  return (
  <>
  {
    isLoggedIn ?
   
  
   <div className='main-layout-sturcture'> 
   
   <div className='nav-side'>
  <AdminSidebar/>
  </div>



  <div className='app-content'>
  <div className='nav-fixed'>
    <AdminHeader toggleMobileMenu={toggleMobileMenu}/></div>

       <Routes>
         
         <Route index element={<Navigate to="home"/>} />   //to assign path to index 1
        <Route path="home" element={<WebsitePDFs/>}/>
        <Route path="website-content" element={<WebsiteContent/>}/>
        <Route path="eventsadmin" element={<Events/>}/>
        <Route path="home-sec-3" element={ <HomeSection3/> }/>
        <Route path="st-marria-prayer" element={ <OracionALaSantaMaria/> }/>
        <Route path="st-rita-prayer" element={ <OracionASantaRita/> }/>
        <Route path="lectura-del-domingo" element={ <LecturaDelDomingo/> }/>
        <Route path="palabras-del-mes" element={ <PalabrasDelMes/> }/>
        <Route path="siguientes-actividades" element={<Events mode={"siguientes-actividades"}/>}/>
        <Route path="eventsadmin/create-event" element={<CreateEvent mode={"create"} />}/>
        <Route path="eventsadmin/edit-event" element={<CreateEvent mode={"edit"} />}/>
        <Route path="masstime" element={<Masstime/>}/>
        <Route path="eucaristia" element={<Eucaristia/>}/>
        <Route path="liturgia-de-cada-día" element={<Masstime type={"liturgia"}/>}/>
        <Route path="masstime/create-event-masstime" element={<CreateMasstime  mode={"create"}/>}/>
        <Route path="masstime/edit-event-masstime" element={<CreateMasstime  mode={"edit"}/>}/>
        <Route path="liturgia-de-cada-día/create-event-masstime" element={<CreateMasstime  mode={"create"} type={"liturgia"}/>}/>
        <Route path="liturgia-de-cada-día/edit-event-masstime" element={<CreateMasstime  mode={"edit"} type={"liturgia"}/>}/>
        <Route path="informacion" element={<Informacion/>}/>
       
        <Route path="masstime/create-event-sunday" element={<Sunday/>}/>
        <Route path="masstime/create-event-eu" element={<Eucaristic/>}/>
        <Route path="website/pdf" element={ <WebsitePdf/> }/>
        

        <Route path="office-hours/list" element={<OfficeHour/>}/>
        <Route path="apertura-de-la-iglesia" element={<AperturaDeLaIglesia/>}/>
        <Route path="userlist" element={<Userlist/>}/>
        <Route path="messageadmin" element={<Message/>}/>
        <Route path="settings" element={<Settings/>}/> 
        <Route path="adminlist" element={<AdminList/>}/> 
        <Route path="adminlist/permission" element={<Permission/>}/> 
        <Route path="adminlist/regpage" element={<Registration/>}/>
        
        <Route path="bulletin" element={<Bulletin/>}/> 
        <Route path="bulletin/createbulletin" element={<CreateBulletin/>}/>
        <Route path="bulletin/preview" element={<BulletinPreview/>}/> 

        <Route path="boletines-generales" element={<BulletinGeneral/>}/> 
        <Route path="boletines-generales/createbulletin" element={<CreateBulletinGeneral/>}/>

        <Route path="members" element={<Members/>}/> 

        <Route path="todos-los-jueves-adoracion" element={<TodosLosJuevesAdoracion/>}/> 
        
        <Route path="parroquia/pastroal-parroquia" element={<PastroalParroquia/>}/> 
       
        <Route path="galleria" element={<Gallery/>}/> 

        <Route path="uncion-de-los-enfermos" element={<UncionDeLosEnfermos/>}/> 
        <Route path="Confesión" element={<Confesion/>}/> 
        <Route path="oden-sacerdotal-y-la-vocación-agustiniana" element={<OrdenSacerdotalYLaVocacionAgustiniana/>}/> 

       </Routes>
     
    </div>

   </div>
   
   
:
<div className='loggedoutpage'>
<img src="\images\Group (1).svg" className='logo-admin-login'/>
<div className='loading'>
<h2 className='poppins-regular2'>Por favor inicie sesión para acceder al panel de administración<span>.</span><span>.</span><span>.</span></h2>
          </div>
<Link to='/login' onClick={gotologin} className='manrope-text-bolder  login-button btn btn-success'> Ir a Iniciar sesión</Link>
</div>
}
  
  
    </>
  )
}

export default AdminLayout