import React, { useEffect, useState } from 'react'
import "./Masstime.css"
import Header from '../common-components/Header'
import API from '../../API';
import { HashLink as Linker } from 'react-router-hash-link';
import PositiveModel from '../../PositiveModel';
import $ from "jquery"

function Masstime() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])
    const [eventsmass, setEventsmass] = useState([]);
    const [message, setMessage] = useState("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
useEffect(() => {
  const fetchEvents = async () => {
    try {
      const response = await API.get(`mass/?day_type=normal`);
      console.log("API success", response);
      const updatedEvents = response.data.results.map((event) => ({
        ...event,
        start_time: convertTo12Hour(event.start_time),
        end_time: convertTo12Hour(event.end_time),
        mass_date: convertToDDMMYYYY(event.mass_date),
         // Convert mass_date here
        description:convertNewlinesToBreaks(event.description),
        }));
      setEventsmass(updatedEvents);
     
    } catch (error) {
      console.log("API error", error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
    }
  };
  fetchEvents();
}, []);


function convertNewlinesToBreaks(text) {
    if (!text) return text;
    return text.split('\n').join('<br />');
  }
  

const [completedeventsmass, setCompletedeventsmass] = useState([]);
useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await API.get(`mass/?day_type=normal`);
        // console.log("API success", response);
        const updatedEvents = response.data.results.map((event) => ({
          ...event,
          start_time: convertTo12Hour(event.start_time),
          end_time: convertTo12Hour(event.end_time),
          mass_date: convertToDDMMYYYY(event.mass_date), // Convert mass_date here
        }));
        setEventsmass(updatedEvents);
      } catch (error) {
        console.log("API error", error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      }
    };
    fetchEvents();
  }, []);

const convertTo12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(part => parseInt(part, 10));
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }
    let ampm = 'AM';
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = 'PM';
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours = adjustedHours < 10 ? '0' + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const convertToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };



  return (
    <div>
         <div className='image-head-mass' id='banner' style={{ backgroundImage: `url("/images/espnew-edit/Mass -B.jpg")` }}>
        <Header />
      </div>


        <div className='sub-body-esp'>


         <div className='text-box2-esp gradient-text ' >
          <span className='great-vibes-regular cursive-esp' > Horario  <span className='poppins-regular1'>Eventos</span></span>
        <div>
        </div></div>



        
       
            {eventsmass.map((event)=>(
                 <div className='masstime-upcom poppins-regular2 '>
                 <span className='each-eventmass b6'>{event.mass_type}</span>
                 <span className='each-eventmass'>Fecha: {event.mass_date}</span>
                 <span className='each-eventmass'>Tiempo: {event.start_time}-{event.end_time}</span> 
                 <span className='each-eventmass'>Ubicación: {event.location}</span>
                 <span className='btnrule'>
                 
                 <img src='\images\more.svg' className='btnout ' onClick={() => openModal(event)}/>
                 
                 </span>

                
               <img src='\images\icons8-info.svg' onClick={() => openModal(event)} className='knwmore'/>
        
        
                 </div>

            ))}
        

        
       
        </div>







        {isModalOpen && selectedEvent && (
        <PositiveModel
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedEvent.name}
          content={selectedEvent.description}
        />
      )}


        
    </div>
  )
}

export default Masstime