import React, { useEffect, useState } from 'react'
import "./Liturgy.css"
import $ from "jquery"
import Header from '../common-components/Header';
import API from '../../API';


function Liturgy() {

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [bautismoList, setBautismoList] = useState([]);
  const [confirmacionList, setConfirmacionList] = useState([]);
  const [confesionList, setConfesionList] = useState([]);
  const [uncionDeLosEnfermosList, setUncionDeLosEnfermosList] = useState([]);
  const [ordenSacerdotalList, setOrdenSacerdotalList] = useState([]);
  const [matrimonialList, setMatrimonialList] = useState([]);

  const [EucharisticAdorationList, setEucharisticAdorationList] = useState([]);


  const [confesionTextData, setConfesionTextData] = useState([]);
  const [UncionDeLosEnfermosTextData, setUncionDeLosEnfermosTextData] = useState([]);
  const [ordenSacerdotalYLaVocaciónAgustinianaTextData, setOrdenSacerdotalYLaVocaciónAgustinianaTextData] = useState([]);

  // Load PDF data from backend
  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true)
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  // Load Mass time
  // Load data from backend
  const loadMassTimes = (mass_type, day_type, setList) => {
    setIsLoading(true);
    let queryParams = [];
    if (day_type) queryParams.push(`day_type=${day_type}`);
    if (mass_type) queryParams.push(`mass_type=${mass_type}`);
    let apiUrl = `mass/?${queryParams.join('&')}`;

    API.get(`${apiUrl}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };


  const loadConfirmacionTextData = () => {
    API.get(`/websitecontent/?page_name=liturgy&section_number=4`)
      .then(response => {
        if (response.data && response.data.results) {
          setConfesionTextData(response.data.results[0]);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }


  const loadUncionDeLosEnfermosTextData = () => {
    API.get(`/websitecontent/?page_name=liturgy&section_number=5`)
      .then(response => {
        if (response.data && response.data.results) {
          setUncionDeLosEnfermosTextData(response.data.results[0]);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }


  const loadOrdenSacerdotalYLaVocaciónAgustinianaTextData = () => {
    API.get(`/websitecontent/?page_name=liturgy&section_number=6`)
      .then(response => {
        if (response.data && response.data.results) {
          setOrdenSacerdotalYLaVocaciónAgustinianaTextData(response.data.results[0]);
        } else {
          console.log('No result field in API response.');
          setMessage("No data to show");
          setIsErrorModalOpen(true);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }


  const loadData = () => {
    loadPDFs('Liturgia', 'Bautismo', setBautismoList);
    loadPDFs('Liturgia', 'Confirmación', setConfirmacionList);
    loadPDFs('Liturgia', 'Confesión', setConfesionList);
    loadPDFs('Liturgia', 'Unción de los Enfermos', setUncionDeLosEnfermosList);
    loadPDFs('Liturgia', 'Orden sacerdotal y la Vocación Agustiniana', setOrdenSacerdotalList);
    loadPDFs('Liturgia', 'Matrimonio', setMatrimonialList);
    loadMassTimes('eucharistia', null, setEucharisticAdorationList);
    loadConfirmacionTextData()
    loadUncionDeLosEnfermosTextData()
    loadOrdenSacerdotalYLaVocaciónAgustinianaTextData();
  };
  useEffect(() => {
    loadData();
  }, []);

  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };


    // Filter Shift Times (work days)
    const filterByDaytype = (dayType, massData) => {
      return massData
        .filter((mass) => mass.repeatation_day_type === dayType && mass.start_time)
        .sort((a, b) => a.start_time.localeCompare(b.start_time)) // sort by start_time
  
    };


  return (
    <div>
      <div className='img-top-lit' id='lit' >
        <Header />
        <div className='text-box2-lit'>
          <span className='great-vibes-regular cursive-lit '>los siete   <p className='poppins-regular1 largefont'>Sacramentos</p> <span className='poppins-regular1 largefont'>
          </span></span>

        </div>

        <img src='/images/Liturgy/crosslit.svg' className='cross-lit' />

      </div>



      <div className='first-divbody-lit' id='sac'>
        <div className='firstlitdiv'>
          <div className='card-front' >
            <img src="\images\lit-new\image 26.png" className='width50' />
            <div className='cardcontent-lit-card'>
              <p className='poppins-regular1 yellowcard-lit-head'>Bautismo</p>
              <p className='dm-sans-regular card-body-lit'> es un sacramento de iniciación, mediante el cual la persona recibe la gracia del Espíritu Santo, inicia el seguimiento del Evangelio y se incorpora a la Iglesia cristiana.</p>
              <div className='pdf-download-container liturgy'>
                {bautismoList.map((pdf, index) => (
                  <div key={index} className='btn-card34-lit dm-sans-bold'>
                    <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                    <span onClick={() => downloadPDF(pdf)}>
                      Descargar PDF
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>

        <div className='firstlitdiv'>
          <div className='card-front'>
            <img src="\images\lit-new\Frame 290110.png" className='width50' />
            <div className='cardcontent-lit-card'>
              <p className='poppins-regular1 yellowcard-lit-head'>Confirmación</p>
              <p className='dm-sans-regular card-body-lit'> consiste en la renovación de las promesas bautismales y el aumento de la gracia del Espíritu Santo. </p>
              <div className='pdf-download-container liturgy'>
                {confirmacionList.map((pdf, index) => (
                  <div key={index} className='btn-card34-lit dm-sans-bold'>
                    <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                    <span onClick={() => downloadPDF(pdf)}>
                      Descargar PDF
                    </span>
                  </div>
                ))}
              </div>
            </div>

          </div>


        </div>


      </div>

      <div className='second-divbody-lit' id='euc'>
        <div className='firstlitdiv1'>
          <div className='firsttimerbox'>
            <p className=' poppins-regular1 headtime'>Eucaristía</p>


           
              <div className='onetime'>
                <p className='timerp'>
                  <img src='/images/Liturgy/cal.svg' className='cal' />
                  <span className='dm-sans-bold  time-lit'>Lunes a Sábado</span>
                </p>
                <div className='timebox-lit'>
                {EucharisticAdorationList && EucharisticAdorationList.length > 0 && filterByDaytype("monday-to-saturday", EucharisticAdorationList).map((mass, index) => {
                  return(
               
                    <span key={index} className={`dm-sans-regular ${index === 2 ? 'time-lit' : 'time-lit'}`}>
                    {convertTo12Hour(mass.start_time)} </span>
                  
                 
                  
  )})}

                </div>
              </div>
              <div className='onetime'>
              <p className='timerp'>
                <img src='/images/Liturgy/cal.svg' className='cal' />
                <span className='dm-sans-bold  time-lit'>Domingo</span>
              </p>
              <div className='timebox-lit'>
              {EucharisticAdorationList && EucharisticAdorationList.length > 0 && filterByDaytype("sunday", EucharisticAdorationList).map((mass, index) => {
                return(
                 <span key={index} className={`dm-sans-regular ${index === 2 ? 'time-lit' : 'time-lit'}`}>
                  {convertTo12Hour(mass.start_time)}
                </span>
              
              )})}

              </div>
            </div>
         


          </div>
        </div>
        <div className='firstlitdiv1'>
          <div className='firsttimerimg'>
            <img src="\images\lit-new\Rectangle 698.png" className='firsttimerimg1' />

          </div>
        </div>


      </div>


      <div className='body2-lit' id='con'>
        <p className='poppins-regular1 heading-b1'>Confesión</p>
        <p className='dm-sans-regular body-b1'> {confesionTextData.description}{/*15 minutos antes de las misas y siempre que lo soliciten (timbre del confesionario)*/}.</p>
        <div className='pdf-download-container liturgy'>
              {confesionList.map((pdf, index) => (
                <div key={index} className='btn-card34-lit dm-sans-bold'>
                  <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                  <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                  </span>
                </div>
              ))}
            </div>
      </div>
      <div className='body3-lit'>
        <p className='poppins-regular1 heading-b2'>Unción de los Enfermos</p>
        <p className='dm-sans-regular body-b1'>{UncionDeLosEnfermosTextData.description}{/*Siempre que lo soliciten en el despacho o por teléfono (913 002 949).*/}</p>
        <div className='pdf-download-container liturgy'>
              {uncionDeLosEnfermosList.map((pdf, index) => (
                <div key={index} className='btn-card34-lit dm-sans-bold'>
                  <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                  <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                  </span>
                </div>
              ))}
            </div>
      </div>
      <div className='body2-lit'>
        <p className='poppins-regular1 heading-b2'>Orden sacerdotal y la Vocación Agustiniana</p>
        <p className='dm-sans-regular body-b1'>{ordenSacerdotalYLaVocaciónAgustinianaTextData.description}{/*La vocación sacerdotal es una llamada a servir al Pueblo de Dios. La vida religiosa agustiniana invita a seguir a Jesús con un alma y con un corazón en comunidad.*/}</p>
        <a style={{ fontSize: "20px" }} href='https://agustinos.es/itinerario-para-ser-agustino/'>agustinos.es</a>
        <div className='pdf-download-container liturgy'>
              {ordenSacerdotalList.map((pdf, index) => (
                <div key={index} className='btn-card34-lit dm-sans-bold'>
                  <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                  <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                  </span>
                </div>
              ))}
            </div>
      </div>


      <div className='weddingbox'>

        <img src='/images/Liturgy/ring.svg' className='ring' />


        <div className='content'>
          <p className='poppins-regular1 heading-b3'>Matrimonio</p>
          <div className='hidden'>
            <img src="\images\lit-new\Frame 290108.png" className='mobview-lit' />
          </div>
          <p className='dm-sans-regular wed-font'>El matrimonio se define como la alianza por la cual, - el hombre y la mujer - se unen libremente para toda la vida con el fin de ayudarse mutuamente, procrear y educar a los hijos. Esta unión - basada en el amor – que implica un consentimiento interior y exterior, estando bendecida por Dios, al ser sacramental hace que el vínculo conyugal sea para toda la vida. Nadie puede romper este vínculo. (Cfr. CIC can. 1055).</p>

          <div className='pdf-download-container matrimony'>
            {matrimonialList.map((pdf, index) => (
              <div key={index} className='btn-card3-lit dm-sans-bold '>
                <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo22' />
                <span onClick={() => downloadPDF(pdf)}>
                  Descargar PDF
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className='img-wed'>
          <img src="\images\lit-new\Frame 290108.png" className='imgwed' />
        </div>
      </div>

    </div>
  )
}

export default Liturgy