import React, { useContext, useEffect, useRef, useState } from 'react'
import"./registration.css"
import { UserContext } from '../../authentication/pages/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import ErrorPopup from '../../ErrorPopup';

function Registration() {

 
   const navigate =useNavigate(); 
  const {submit3,showpopup, setShowpopup}= useContext(UserContext)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);


 const id = localStorage.getItem("event_id")
  const [formData,setFormData]=useState(
    {
      username: "",
      email: "",
      phone_number: "",
      guests: "",
      comments: "",
      event:""
  }
  )

  useEffect(() => {
    if (id) {
      setFormData(prevFormData => ({
        ...prevFormData,
        event: id
      }));
    }
  }, [id]);
  console.log(id);

  const handleInputChange = (e) => {
    console.log(e)
    const { name, value } = e.target;

    if (name === "comments") {
      const words = value.split(/\s+/).filter(word => word.length > 0);
      if (words.length <= maxWords) {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
        setWordCount(words.length);
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: words.slice(0, maxWords).join(' ')
        }));
        setWordCount(maxWords);
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }

    // Convert date format if the input is the date field
  
       
    
};




const [errors, setErrors] = useState({});
  // ------- Validate ---------
  const validateForm = (data) => {
    let errors = {};

    if (!data.phone_number) {
      errors.phone_number = 'Por favor introduce el número de móvil';
    } else if (!/^\d{10}$/.test(data.phone_number)) {
      errors.phone_number = 'Ingrese un número de teléfono móvil válido de 10 dígitos';
    }
    if (!data.username) {
      errors.username = 'Por favor ingrese el nombre';
    } else if (data.username.length > 20) {
      errors.username = 'El nombre no debe tener más de 20 caracteres.';
    }


    if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Por favor ingresa una dirección de email válida';
    }

    if (!(data.comments)) {
      errors.comments = 'Por favor ingrese el mensaje';
    }else if (data.comments.length> 500){
        errors.comments="por favor introduce menos palabras"
      
    }
    if (!data.guests) {
      errors.guests = 'Por favor introduce el número de invitados.';
    } else if (parseInt(data.guests) <= 0) {
      errors.guests = 'El número de invitados debe ser mayor que cero.';
    }

    return errors;
  };





const handleSubmit =  (e) => {
e.preventDefault();
  const validationErrors = validateForm(formData);
  setErrors(validationErrors)
  if (Object.keys(validationErrors).length === 0) {
    setIsLoading(true);
 
 
   API.post('reservations/', formData)
   .then((response)=>{

   
    console.log(formData,"success")
    setIsLoading(false);
    setIsSuccessModalOpen(true);
    console.log("sented")

    setFormData({
      username: "",
      email: "",
      phone_number: "",
      guests: "",
      comments: "",
      event:""
  })
  navigate("/")
}) 
   
   .catch ((error)=> {
     
    setIsLoading(false);
    console.log(error);
    setIsErrorModalOpen(true);
    setMessage(error.message);

  })
}
};


const [wordCount, setWordCount] = useState(0);
const maxWords = 30;





  
    
  
  
  return (
    <div className='reg-main-body' >   

<div className='image-head-reg'  style={{ backgroundImage: `url('/images/registration/Rectangle 810.png')` }}>
 
</div>


     <form className='form-reg-box-saint' onSubmit={handleSubmit} id='reg' >
       <p className=' poppins-regular1 headofform' >Formulario de inscripción</p> 
       <div className='inp-eachbox-reg'>
        <p className='subhead-reg dm-sans-bold'>Introduzca su nombre</p>
        <input type='text' name='username'  value={formData.username} onChange={handleInputChange} className='eachbox1'/>
        {errors.username && <p className="invalid-feedback">{errors.username}</p>}

       </div>
       <div className='inp-eachbox-reg'>
        <p className='subhead-reg dm-sans-bold'>Ingrese la dirección de email</p>
        <input type='mail' name='email' value={formData.email} onChange={handleInputChange} className='eachbox1' />
        {errors.email && <p className="invalid-feedback">{errors.email}</p>}
       </div>
       <div className='inp-eachbox-reg'>
        <p className='subhead-reg dm-sans-bold'>Ingrese el número de móvil</p>
        <input type='number' name='phone_number' value={formData.phone_number} onChange={handleInputChange} className='eachbox1' />
        {errors.phone_number && <p className="invalid-feedback">{errors.phone_number}</p>}
       </div>
       <div className='inp-eachbox-reg'>
        <p className='subhead-reg dm-sans-bold'>Número de invitados (incluido usted mismo)</p>
        <input type='number' name='guests' value={formData.guests} onChange={handleInputChange} className='eachbox1' />
        {errors.guests && <p className="invalid-feedback">{errors.guests}</p>}
       </div>
       <div className='inp-eachbox-reg'>
        <p className='subhead-reg dm-sans-bold'>Comentarios adicionales o solicitudes especiales</p>
        <textarea type='text' name='comments' value={formData.comments} onChange={handleInputChange} className='eachbox2' ></textarea>
        {errors.comments && <p className="invalid-feedback">{errors.comments}</p>}
        <div>El recuento de palabras: {wordCount}/{maxWords}</div>
       </div>
       <label className='terms-div'>
    <input type="checkbox" name="terms"  value="agree" className='rdo' required/><span className='agree dm-sans-regular'>Reconozco que al enviar este formulario, acepto recibir comunicaciones relacionadas con el evento a través de la dirección de correo electrónico proporcionada.</span>
  </label>

<button type="submit" className='btnof-form dm-sans-bold' >
Entregar
</button>
<Link to="/home" className='backto' onClick={submit3}> volver a la pagina</Link>

     </form>


     
     {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => setIsSuccessModalOpen(false)} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />


    </div>
  )
}

export default Registration