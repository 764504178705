import React from 'react'
import "./Horarios.css"

function Horarios({ regularMassList, confessions, specialMassess, EucharisticAdorationList, churchOpeningTimes, informacionData }) {

  const getShiftMassTimes = (shift, massData) => {
    return massData
      .filter((mass) => mass.day_shift === shift && mass.start_time)
      .map((mass) => convertTo12Hour(mass.start_time)) // format time to HH:MM
      .join(' - ');
  };
  const groupSundayHolyDaysByShift = (masses) => {
    return masses.reduce((acc, mass) => {
      const shift = acc[mass.day_shift] || [];
      shift.push(mass);
      acc[mass.day_shift] = shift;
      return acc;
    }, {});
  };
  const formatTime = (time) => {
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
  };

  // Utility function to convert time to 12-hour format
  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  // Filter Shift Times (work days)
  const filterByDaytype = (dayType, massData) => {
    return massData
      .filter((mass) => mass.repeatation_day_type === dayType && mass.start_time)
      .sort((a, b) => a.start_time.localeCompare(b.start_time)) // sort by start_time

  };

  return (
    <div className='hor'>
      <div className='heading-hor poppins-regular1 '>Horarios</div>
      <div className='upperbody-hor'>
        <div className='part1-upper'>
          <div className='table1'>
            <div className='poppins-regular1  table-head'>HORARIO</div>
            <div className='dm-sans-regular table-body1'>

              <span className='dm-sans-bold '>LUNES</span>


              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("monday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>

              <hr />


              <span className='dm-sans-bold '>MARTES</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("teusday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>
              <hr />


              <span className='dm-sans-bold '>MIÉRCOLES</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("wednesday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>
              <hr />


              <span className='dm-sans-bold '>JUEVES</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("thursday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>
              <hr />


              <span className='dm-sans-bold '>VIERNES</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("friday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>
              <hr />



              <span className='dm-sans-bold '>SÁBADO</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("saturday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>
              <hr />



              <span className='dm-sans-bold '>DOMINGO</span>
              <div className='d-flex flex-wrap w-100 justify-content-center'>
                {regularMassList && regularMassList.length > 0 &&
                  filterByDaytype("sunday", regularMassList).map((item, index) => (
                    <>
                      <p>{convertTo12Hour(item.start_time)}&nbsp;&nbsp;&nbsp;</p>
                    </>
                  ))}
              </div>

              <hr />



            </div>
          </div>


          <div className='table2'>
            <div className='poppins-regular1  table-head'>Atención</div>
            <div className='dm-sans-regular table-body2'>


              <span className='dm-sans-bold  mb-3'>CONFESIONES</span>
              <span className='mb-3'>Media hora antes de cada Misa</span>

              {confessions.map(mass => (
                <span key={mass.id} className='text-center mb-3'>
                  {new Date(mass.mass_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}

                  <br />
                  {convertTo12Hour(mass.start_time)} - {convertTo12Hour(mass.end_time)}
                </span>
              ))}
              <hr />



              <span className='dm-sans-bold  mb-3'>MISASE ESPECIALE</span>

              {specialMassess && specialMassess.length > 0 && specialMassess.map(mass => (
                <span key={mass.id} className='text-center mb-3'>
                  {new Date(mass.mass_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}

                  <br />
                  {convertTo12Hour(mass.start_time)} - {convertTo12Hour(mass.end_time)}

                </span>
              ))}
              <hr />

              <span className='dm-sans-bold mb-3'>EUCHARISTIC ADORATIONS</span>


              <span className='text-center mb-3'>Lunes a Sábado</span>
              <div className='d-flex '>
             
              {EucharisticAdorationList && EucharisticAdorationList.length > 0 && filterByDaytype("monday-to-saturday", EucharisticAdorationList).map(mass => {
                return (
                  
                    <span key={mass.id} className='text-center mb-3'>

                      {convertTo12Hour(mass.start_time)}&nbsp;&nbsp;
                    </span>
                 
                )
              }
              )}
  </div>

              <span className='text-center mb-3'>Domingo</span>
              <div className='d-flex '>
                 {EucharisticAdorationList && EucharisticAdorationList.length > 0 && filterByDaytype("sunday", EucharisticAdorationList).map(mass => {
             
                  return (
                  
                    <span key={mass.id} className='text-center mb-3'>

                      {convertTo12Hour(mass.start_time)}&nbsp;&nbsp;
                    </span>
                 
                )
              
              }
              )}
                </div>
              <hr />



            </div>
          </div>




        </div>


      </div>

      <div className='lowerbody-hor'>
        <div className='table-full-lower'>
          <div className='poppins-regular1 table-head'>Información</div>
          <div className='dm-sans-regular table-body1'>
          <span className='mb-4'>{informacionData.description}</span>
            <span className='dm-sans-bold  mb-3'>Apertura De La Iglesia</span>

            <div className='d-flex justify-content-between mb-3'>
              <span className='me-5' >mañana</span>
              <span>tarde</span>
            </div>
            {churchOpeningTimes && churchOpeningTimes.length > 0 && churchOpeningTimes.map(item => (
              <div className='d-flex justify-content-between mb-3'>
                <span className='me-5' key={item.id}>{convertTo12Hour(item.morning_start_time)} - {convertTo12Hour(item.morning_end_time)}</span>
                <span key={item.id}>{convertTo12Hour(item.afternoon_start_time)} - {convertTo12Hour(item.afternoon_end_time)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='lowerbody-hor'>

        <div className='table1-lower'>
          <div className='poppins-regular1  table-head1'>Reuniones comunitarias</div>
          <div className='dm-sans-regular table-body-left'>
            <div className='innertable-content'>
              <span className='sp1'>1st Friday</span>
              <span className='sp1'>Potluck Dinner</span>
              <span className='sp1'>06:00 PM</span>
            </div>
            <hr className='hr1' />
            <div className='innertable-content'>
              <span className='sp'>3rd Saturday</span>
              <span className='sp'>Clean-up Day</span>
              <span className='sp'>10: 00 AM</span>
            </div>
            <hr className='hr1' />
            <div className='innertable-content'>
              <span className='sp'>Last Sunday</span>
              <span className='sp'>Picnic</span>
              <span className='sp'>10:00 PM</span>
            </div>



          </div>

        </div>
        <div className='table1-lower'>
          <div className='poppins-regular1  table-head'>Oportunidades para voluntarios</div>
          <div className='dm-sans-regular table-body-right'>
            <div className='innertable-content'>
              <span className='sp1'>Food Bank Distribution</span>
              <span className='sp1'>Every Friday</span>
              <span className='sp1'>8:00 AM - 12:00 PM</span>
            </div>
            <hr className='hr1' />
            <div className='innertable-content'>
              <span className='sp'>Homeless Shelter</span>
              <span className='sp'>Every 2nd Saturday</span>
              <span className='sp'>8:00 AM - 12:00 PM</span>
            </div>
            <hr className='hr1' />
            <div className='innertable-content'>
              <span className='sp'>Nursing Home Visits</span>
              <span className='sp'>Every Sunday</span>
              <span className='sp'>8:00 AM - 12:00 PM</span>
            </div>


          </div>
        </div>

      </div> */}




    </div>
  )
}

export default Horarios