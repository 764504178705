import React, { createContext, useContext, useEffect, useState } from 'react'
import API from '../../API';
import { Navigate, useNavigate } from 'react-router-dom';


export const UserContext = createContext();
export const UserProvider = ({ children }) => {
 const navigate =useNavigate();

  const [showpopup, setShowpopup]=useState(false);
  const submit3=()=>{
    setShowpopup(false)
  }

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [tooglemenu, setTooglemenu] = useState(false);

  const [isScrollingUp, setIsScrollingUp] = useState(false);
 const [isFlipped,setIsFlipped]=useState(false);

 const handleFlip=()=>{
  setIsFlipped(!isFlipped)
 }
 const [deletest,setDeletest]=useState(false);
 const deletebtn=()=>{

  setDeletest(!deletest);
  setDeleteuser(!deleteuser)
 } 

 const [title1, setTitle1] = useState('');




  const [isloading,setIsloading]=useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAskedcustomer, setIsAskedcustomer] = useState(false);
  const [showMasstime, setShowMasstime] = useState(false);
  const [showWebpage, setShowWebpage] = useState(false);
  const [title, setTitle]=useState({
    title:"Iniclar",
    
  })

  const gotologin=()=>{
    navigate('/login');
    window.location.reload();
  }

  
  const logout = () => {
   
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    
  }

  const asked = () => {
    setIsAskedcustomer(true);
    
  }
  const notasked = () => {
    setIsAskedcustomer(false);
    
  }

//for admin permission
  const [deleteuser,setDeleteuser]=useState(false);
  
  const deletebtn2=()=>{
    setDeleteuser(!deleteuser)
}






  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
  }, [isLoggedIn]);




  const [sendreply,setSendreply]=useState(false)
  const sendreplybtn=()=>{
      setSendreply(!sendreply);
     
  }






//admin

const [otp_required, setOtp_required] = useState(false);
const [redirecturl, setRedirecturl] = useState("/login");
const [isOTPInputShown, showOTPInput] = useState(false);
const [message,setMessage]=useState("")
const [showerror,setShowerror]=useState(false)
const [otpTimer, setOtpTimer] = useState(5*60)
const [otpTimeout, setOtpTimeout] = useState(false)

//otp
const [showotp,setShowotp]=useState(false)
const [errorotp,seterrorotp]=useState(false)

const startOtpTimer = () => {
  setOtpTimeout(false);
  setOtpTimer(5 * 60);

  let intervalId = setInterval(() => {
    setOtpTimer((prevTimer) => {
      
      if (prevTimer === 0) {
        setOtpTimeout(true);
        clearInterval(intervalId);
        return 0;
        
      } else {
        return prevTimer - 1;
       
      }
      
    });
  }, 1000);

  return () => {
    clearInterval(intervalId);
  };
};




// //refresh token
const refreshIntervalTime = 15*60*1000 //15*60*1000//15 minutes.
useEffect(() => {
  // Check if the authentication token exists in the cookie
  //const accessToken = Cookies.get('accessToken'); use this for more secure login. the token will be removed after page refresh


  if (!!localStorage.getItem('accessToken')) {
    setIsLoggedIn(true);
  }
  if(localStorage.getItem("refreshToken")){
  updateToken();// to do a refresh when the app loadding for the first time. other wise it will do only after the first 4 minutes. this is for loging out just after opening the app after a closing the tab from the user side.
  let intervalId = setInterval(() => {
    updateToken();
  }, refreshIntervalTime);  //on 4 minutes 4*60*2000

  return () => {
    clearInterval(intervalId);
  };
}
}, [isLoggedIn]);




const closeError =()=>{
  setShowerror(false)
}

const otp =()=>{
  setOtp_required(!otp_required);
}

const login = (formdata) => {
  
  setIsloading(true);
  let url;
  if(isOTPInputShown)
    {
    url="user/submit_otp/"
    }
  else
  {
    url="/user/login/"
  }

  API.post(url,formdata).then(response=>{
    console.log("this is API  success ",response)
    
    if(response.data.access_token === "otp_required"){
      localStorage.setItem("userID",response.data.user_id)
      
      setShowotp(true)
      setIsloading(false)
      showOTPInput(true)
      startOtpTimer()
      setRedirecturl('/Welcomepage')
     
    }
    else{
    
      console.log("login success")
      navigate('/Welcomepage')
      localStorage.setItem('accessToken', response.data.access_token);
      localStorage.setItem('userID', response.data.user_id);
      localStorage.setItem('refreshToken', response.data.refresh_token)
      setIsLoggedIn(true);

    }
    console.log(response)
    
    

  })
  .catch(error=>{
    console.log("this is API error:", error );
    setIsloading(false)
    setShowerror(true)
    setMessage(error.response?error.response.data.error:"No connection!")
    console.log(formdata)
   

  })
}

  const updateToken = () => {
    console.log(localStorage.getItem('refreshToken'))

    API.post(`/user/token/refresh/`, {
      "refresh": localStorage.getItem('refreshToken')
    })
      .then(response => {
        localStorage.setItem('accessToken', response.data.access);
        console.log(response)
      })
      .catch(error => {
        
        logout()
        console.log(error)
        setRedirecturl("/login")
        navigate(redirecturl)
       
      });
  }
  
  

  








  return (
    <UserContext.Provider value={{showOTPInput,startOtpTimer,otpTimeout,otpTimer,gotologin,closeError,showerror,setShowerror,message,setMessage,isloading,setIsloading,errorotp,showotp,isOTPInputShown, showOTPInput,otp,otp_required, setOtp_required,redirecturl, setRedirecturl,deletebtn2,deleteuser,setDeleteuser,sendreplybtn,sendreply,setSendreply,submit3,deletest,setDeletest,deletebtn, showpopup, setShowpopup,isLoggedIn , login, logout , isAskedcustomer , asked , notasked,showMasstime,setShowMasstime,showWebpage,setShowWebpage,title,setTitle,tooglemenu, setTooglemenu,handleFlip,isFlipped,setIsFlipped,isScrollingUp, setIsScrollingUp,isMobileMenuOpen, setMobileMenuOpen,title1, setTitle1}}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () =>
  useContext(UserContext);
