import React, { useEffect, useState } from 'react'
import "./CreateMasstime.css"
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { error } from 'jquery';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';



function CreateMasstime({ mode, type }) {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const navigate = useNavigate();

    const id = localStorage.getItem("selected_id")

    const [formData, setFormData] = useState({
        title:"",
        mass_type:type=="liturgia"?"liturgia": "",
        mass_date: "",
        location: "",
        start_time: "",
        end_time: "",
        description: "",
        repeatation_day_type: "normal",
        day_shift: "normal"
    })


    useEffect(() => {
        if (mode === 'edit' && id) {
            const fetchEvent = async () => {
                try {
                    const response = await API.get(`mass/${id}/`);
                    const eventData = response.data;
                    console.log("this is success in fetching data from masstime");
                    console.log("response", response);

                    // Ensure the date format is corrected
                    let formattedDate = "";
                    if (eventData.date) {
                        console.log(eventData.date)
                        const [day, month, year] = eventData.date.split('/');
                        formattedDate = `${year}-${month}-${day}`;
                        console.log("formatteddate", formattedDate)
                    }

                    // Set the form data with the formatted date
                    setFormData({
                        ...eventData,
                        date: formattedDate
                    });
                } catch (error) {
                    console.log("API error", error);
                }
            };
            fetchEvent();
        }
    }, [mode, id]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(e)
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));

        console.log(formData)

    };



    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault(); // Prevent the default form submission
        // Handle form submission logic here
        if (mode === "create") {
            API.post('mass/', formData).then(response => {
                console.log(response, "success")
                setFormData({
                    mass_type: "",
                    mass_date: "",
                    location: "",
                    start_time: "",
                    end_time: "",
                    description: "",
                    repeatation_day_type: "normal",
                    day_shift: "normal"
                })
                setIsLoading(false);
                if(type == "liturgia"){
                navigate('/admin/liturgia-de-cada-día');

                }
                else{
                    navigate('/admin/masstime');

                }

            })
                .catch(error => {
                    console.log('api failed', error)
                    setMessage(error.message)
                    setIsErrorModalOpen(true)
                    setIsLoading(false)
                })
        }
        else if (mode === "edit" && id) {
            API.put(`mass/${id}/`, formData).then(response => {
                console.log("edited masstime", response)
                navigate('/admin/masstime');
            })
                .catch(error => {
                    console.log('edited masstime failed', error)
                    setMessage(error.message)
                    setIsErrorModalOpen(true)
                })

        }

    };



    const dateInputs = document.querySelectorAll('.namebox-ad1 input[type="date"]');


    dateInputs.forEach(function (input) {
        input.addEventListener('input', function () {

            if (this.value !== '') {
                // Set opacity to 1 if the input has a value
                this.style.color = "red";
            } else {
                // Set opacity to 0.5 if the input is empty
                this.style.opacity = 0.5;
            }
        });
    });



    return (
        <div className='createpage'>
            <div className='headerofevent2'>
                <div className='titlebox2-ad dm-sans-regular'><Link to={type == "liturgia" ? "/admin/liturgia-de-cada-día" : "/admin/masstime"}><img src='\images\admin\eventad\Arrow_Left.svg' /></Link><span>{mode === 'create' ? 'Crear' : 'Actualizar'} {type === 'liturgia' ? 'Liturgia De Cada Dia' : 'Misase Especiale'}</span>
                </div>
            </div>


            <form className='craeteform dm-sans-bold' onSubmit={handleSubmit}>

                <div className='names-box'>
                   
                   {type=="liturgia"?
                   <div className='eachname-ad'>
                   <label>Título</label>
                   <input type='text'
                       className='namebox-ad1'
                       name='title'
                       value={formData.title}
                       onChange={handleInputChange} required />     
               </div>

                   :
                    <div className='eachname-ad'>
                        <label>Tipo de Misa</label>
                        <select type='text'
                            className='namebox-ad1'
                            name='mass_type'
                            value={formData.mass_type}
                            onChange={handleInputChange} required >
                            <option value={null} label='Elija el tipo'></option>
                            <option value={'Confesión'} label='Confesión'></option>
                            <option value={'Especial'} label='Especial'></option>

                        </select>
                    </div>
}

                    <div className='eachname-ad'>

                        <label>Ubicación</label>
                        <select type='text'
                            className='namebox-ad1'
                            value={formData.location}
                            name='location'
                            onChange={handleInputChange} required >
                            <option value={null} label='Seleccionar'></option>
                            <option value={"parish"} label='Parish'></option>
                            <option value={"Hall 1"} label='Hall 1'></option>
                            <option value={"Hall 2"} label='Hall 2'></option>
                            <option value={"Other"} label='Other'></option>
                        </select>

                    </div>
                </div>




                <div className='names-box'>
                    <div className='eachname-ad'>
                        <label>Fecha</label>
                        <input control
                            type="date"
                            className="namebox-ad1"
                            name='mass_date'
                            value={formData.mass_date}
                            onChange={handleInputChange}
                            required
                        />
                    </div>


                    <div className='time-ad'>
                        <div className='eachname-ad'>

                            <label>Tiempo</label>
                            <input type="time"
                                name='start_time'
                                value={formData.start_time}
                                onChange={handleInputChange}
                                className='namebox-ad122' required />
                        </div>
                        <div className='eachname-ad'>

                            <label className='hide-ad'>d</label>

                            <input
                                type="time"
                                value={formData.end_time}
                                name='end_time'
                                className='namebox-ad122'
                                onChange={handleInputChange}
                                required />
                        </div>
                    </div>
                </div>


                <div className='names-box'>
                    <div className='eachname-ad2'>
                        <label>Información adicional</label>
                        <textarea id='desp' onChange={handleInputChange}
                            name='description'
                            className='namebox-ad2'
                            value={formData.description} >
                        </textarea>
                    </div>
                </div>



                <div className='btns-ad'>
                    <Link to="/admin/masstime" className='trans-btn'>Descartar</Link>
                    <button className='solid-btn'>{mode === 'create' ? 'Guardar' : 'Actualizar'}</button>

                </div>
            </form>

            {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/adminlist') }} />}
            {isLoading && <LoadingSpinner />}
            <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
        </div>
    )
}

export default CreateMasstime
