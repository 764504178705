import React, { useContext, useEffect, useState } from "react";
import "./Masstime.css";
import $, { error, event } from "jquery";
import { Link, useNavigate } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import API from "../../API";
import { UserContext } from "../../authentication/pages/UserContext";
import ErrorPopup from "../../ErrorPopup";
import LoadingSpinner from "../../LoadingSpinner";
import Delete from "../common-components/Delete";



import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';


function Masstime({ type }) {
  const navigate = useNavigate();

  const [selectedTimes, setSelectedTimes] = useState([null, null, null, null, null, null, null]);

  const { deletest, setDeletest, deletebtn } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [allMassList, setAllMassList] = useState([]);
  const [regularMassList, setRegularMassList] = useState([]);
  const [confessions, setConfessions] = useState([]);
  const [EucharisticAdorationList, setEucharisticAdorationList] = useState([]);
  const [specialMasses, setSpecialMasses] = useState([]);
  const [liturgiaMasses, setLiturgiaMasses] = useState([]);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const handleTimeChange = (index, newTime) => {
    if (newTime && newTime.isValid()) {
      var temp = [...selectedTimes];
      temp[index] = dayjs(newTime)
      setSelectedTimes(temp);

    }
  };






  //convertion of data


  // Add function
  const add = async (repeatationDayType, value) => {
    let time = value.format('HH:mm:ss')
    setIsLoading(true);
    let data = {
      mass_type: "eucharistia",
      mass_date: null,
      location: null,
      start_time: time,
      end_time: null,
      description: null,
      repeatation_day_type: repeatationDayType,
      day_shift: null
    }
    API.post(`mass/`, data)
      .then(response => {
        setIsLoading(false);
        loadData()
      })

      .catch(error => {
        setIsLoading(false);
        setMessage(error.message);
        setIsErrorModalOpen(true);
        console.error("Error updating time:", error);

      })
  };




  const handleDeleteMass = (id) => {

    localStorage.setItem("delete_id", id);
    setIsDeleteModalOpen(true)


  }

  const confirmDelete = async () => {
    setIsLoading(true)
    try {
      // Perform deletion operation here
      await API.delete(`mass/${localStorage.getItem("delete_id")}`);
      // Update events after deletion

      setDeletest(false);
      setIsLoading(false);
      loadData()
    } catch (error) {
      setDeletest(false);
      console.log("Delete error", error);
      setMessage(error.message);
      setIsErrorModalOpen(true);
      setIsLoading(false)
    }
  };




  //officehours API



  // Utility function to convert time to 12-hour format
  const convertTo12Hour = (timeString) => {
    if (!timeString) {
      return ("")
    }
    const [hours, minutes] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }
    let ampm = "AM";
    let adjustedHours = hours;
    if (adjustedHours >= 12) {
      ampm = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    } else if (adjustedHours === 0) {
      adjustedHours = 12;
    }
    const formattedHours =
      adjustedHours < 10 ? "0" + adjustedHours : adjustedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const convertToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  // Load data from backend
  const loadMassTimes = (mass_type, day_type, setList) => {
    setIsLoading(true);
    let queryParams = [];
    if (day_type) queryParams.push(`day_type=${day_type}`);
    if (mass_type) queryParams.push(`mass_type=${mass_type}`);
    let apiUrl = `mass/?${queryParams.join('&')}`;

    API.get(`${apiUrl}`)
      .then(response => {

        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };

  const loadData = () => {

    loadMassTimes('eucharistia', null, setEucharisticAdorationList);

  };

  useEffect(() => {
    loadData()
  }, [])


  // Filter Shift Times (work days)
  const filterByDaytype = (dayType, massData) => {
    return massData
      .filter((mass) => mass.repeatation_day_type === dayType && mass.start_time)
      .sort((a, b) => a.start_time.localeCompare(b.start_time)) // sort by start_time

  };





  const editMass = (id) => {
    localStorage.setItem("selected_id", id)

    if (type == "liturgia") {
      navigate('/admin/liturgia-de-cada-día/edit-event-masstime');

    }
    else {
      navigate('/admin/masstime/edit-event-masstime');

    }
  }



  const convertNewlinesToBreaks = (text) => {
    if (!text) {
      return text;
    }
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };


  return (
    <div>
      <div className="headerofevent33">
        <div className="titlebox-ad dm-sans-regular">
          <span>Eucaristía</span>
          <br />
        </div>

      </div>

      {/* Section 1 */}
      <div className="card-home-me dm-sans-regular">
        {/* Work Days */}



        <div className="yellowcard-me w-100 regular-mass">
          <div className="d-flex mb-3">
            <p className="subtext-wd dm-sans-bold">Eucaristía</p>
            <div className="inp-box">
              <select id="day_select">
                <option value={"monday-to-saturday"} label="Lunes a Sábado"></option>

                <option value={"sunday"} label="DOMINGO"></option>
              </select>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Seleccionar hora"
                  value={selectedTimes[0]}
                  onChange={(newTime) => handleTimeChange(0, newTime)}
                  renderInput={(params) => <TextField slotProps={{ textField: { size: 'small' } }} {...params} />}
                />
              </LocalizationProvider>


              <button className="solid-btn add-btn" onClick={() => { add($("#day_select").val(), selectedTimes[0]) }} type="button">+&nbsp;Agregar</button>
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">Lunes a Sábado:</p>

            <div className="time-strip-box">

              {EucharisticAdorationList && EucharisticAdorationList.length > 0 &&
                filterByDaytype("monday-to-saturday", EucharisticAdorationList).map((item, index) => (

                  <div key={index} className="time-strip">
                    <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                    <svg onClick={() => handleDeleteMass(item.id)}
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 512 512"
                    >
                      <path
                        d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                        fill="#ffffff"
                        className="fill-000000"
                      ></path>
                    </svg>
                  </div>

                ))}
            </div>
          </div>


          <div className="d-flex align-items-center segment">
            <p className="title">Domingo:</p>

            <div className="time-strip-box">

              {EucharisticAdorationList && EucharisticAdorationList.length > 0 &&
                filterByDaytype("sunday", EucharisticAdorationList).map((item, index) => (

                  <div key={index} className="time-strip">
                    <span>{convertTo12Hour(item.start_time.substring(0, 5))}</span>
                    <svg onClick={() => handleDeleteMass(item.id)}
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 512 512"
                    >
                      <path
                        d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                        fill="#ffffff"
                        className="fill-000000"
                      ></path>
                    </svg>
                  </div>

                ))}
            </div>
          </div>
        </div>
      </div>




      {isDeleteModalOpen ? <Delete onSubmit={confirmDelete} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup
        message={message}
        state={isErrorModalOpen}
        setterFunction={setIsErrorModalOpen}
        okClickedFunction={() => {
        window.location.reload();
        }}
      />
    </div>
  );
}

export default Masstime;
