import { useContext, useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import "./AdminHeader.css";
import { UserContext } from "../../authentication/pages/UserContext";
import { useNavigate } from "react-router-dom";
import API from "../../API";

function AdminHeader({ toggleMobileMenu }) {
  const { logout } = useContext(UserContext);

  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showProfile = () => {
    setProfile(!profile);
  };

  const [formData, setFormData] = useState({
    username: "",
    profile_image: null,
    role: "",
    created_at: "",
  });

  useEffect(() => {
    const userId = localStorage.getItem("userID");
    API.get(`/user/edit_profile/${userId}/`)
      .then((response) => {
        const user = response.data;
        setFormData({
          username: user.username,
          profile_image: user.profile_image,
          role: user.role,
          created_at: convertDateFormat(user.created_at)
        });
        setProfileImage(user.profile_image);

        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  }, []);

  function convertDateFormat(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Debugging: Log the profile image URL
  // console.log("Profile Image URL:", formData.profile_image);

  return (
    <div>
      <div className="navbar-main">
        <div className="head-button-mobile">
          <div className="nav-body">
            <div className="hamburger-button" onClick={toggleMobileMenu}>
              <i className="bi bi-list"></i>
            </div>
            <div className="search-container d-none">
              <div className="searchbox-logo">
                <img src="/images/admin/header/Vector (3).svg" className="searchlog" />
              </div>
              <input type="text" className="searchbar" placeholder="Type here..." />
            </div>
            <div className="round-bell d-none">
              <img src="/images/admin/sidebar/bellicon.svg" height="30px" />
              <div className="red"></div>
            </div>
          </div>

          <div className="profilebox">
            <div className="profilepic">
            
            <div
                className="pr-pic"
                style={{
                  backgroundImage: `url(${formData.profile_image || "/images/307ce493-b254-4b2d-8ba4-d12c080d6651.jpg"})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                 
                }}
              ></div>
              
            </div>
            <div className="pr-back">
                <img src="/images/admin/header/Vector 10.svg" className="down" onClick={showProfile} />
              </div>

            <div className="tail-button-mobile">
              <div className="round-bell">
                <img src="/images/comment-dots-Filled.svg" className="bell" />
                <div className="red"></div>
              </div>
            </div>
            {profile ? (
              <div className="cardofprofile">
                <div className="name-pr">
                  <p className="poppins-regular1 name-pr1">{formData.username}</p>
                  <p className="poppins-regular2 subname-pr">{formData.role}</p>
                </div>
                <div className="logoutbox">
                  <p className="dm-sans-bold date-pr">Se unió el {formData.created_at}</p>
                  <div className="btn-pr dm-sans-bold" onClick={logout}>
                  Cerrar sesión
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
