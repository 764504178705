import React from 'react'
import "./UserLayout.css"
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Home from '../../user-panel/pages/Home'


import Espiritualidad from '../pages/Espiritualidad'
import Liturgy from '../pages/Liturgy'
import Catechism from '../pages/Catechism'
import Despacho from '../pages/Despacho'
import Accion from '../pages/Accion'

import Groupo from '../pages/Groupo'
import Contacto from '../pages/Contacto'
import FooterSaintMaria from './FooterSaintMaria'
import Masstime from '../pages/Events'
import Registration from '../pages/Registration'
import Bulletins from '../pages/Bulletins'
import PdfViewer from "../pages/Pdfview"
import Events from '../pages/Masstime'
import Gallery from "../pages/Gallery"
import Prayerpage1 from '../pages/Prayerpage1'
import Prayerpage2 from '../pages/Prayerpage2'
import MonthPrayer from '../pages/MonthPrayer'






function UserLayout() {
  return (
    <div className='app-container'>
    
    
       <Routes>
         
         <Route index element={<Navigate to="home"/>} />   //to assign path to index 1
         <Route path="home" element={<Home/>} />
         <Route path="espiritualidad" element={<Espiritualidad/>} />
        
         <Route path="liturgy" element={<Liturgy/>} />
         <Route path="catechism" element={<Catechism/>} />
         <Route path="despacho" element={<Despacho/>} />
         <Route path="accion" element={<Accion/>}/>
         <Route path="groupo" element={<Groupo/>}/>
         <Route path="contacto" element={<Contacto/>}/>
         <Route path="bulletin" element={<Bulletins/>}/>
         <Route path="bulletin/pdfview" element={<PdfViewer/>}/>
         <Route path="events" element={<Events/>}/>
         <Route path="reg" element={<Registration/>}/>
         <Route path="mass" element={<Masstime/>}/>
         <Route path="gallery" element={<Gallery/>}/>

         <Route path="prayer-virginmary" element={<Prayerpage1/>}/>
         <Route path="prayer-saintrita" element={<Prayerpage2/>}/>
         <Route path="month-prayer"  element={<MonthPrayer/>}/>
         
       

        
       </Routes>
       <FooterSaintMaria/>
    
    





    </div>
  )
}

export default UserLayout