import React, { useEffect, useState } from 'react'
import "./Registration.css"
import 'react-phone-number-input/style.css'
import PhoneInput, { PhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import ErrorModal from '../../ErrorModal';
import { isValidPhoneNumber } from 'libphonenumber-js';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import ErrorPopup from '../../ErrorPopup';
import { useNavigate } from 'react-router-dom';


function Registration() {
  const navigate = useNavigate();

  const [showpass, setShowpass] = useState(false);
  const [showpass2, setShowpass2] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);


 
  const showeye = () => {
    setShowpass((prevShowpass) => !prevShowpass);

  }
  const showeye2 = () => {
    setShowpass2((prevShowpass2) => !prevShowpass2);

  }
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    country_code: '',
    phonenumber: '',
    password: '',
    confirmPassword: '',
    country: '',
  });

  const handleInputChange = (e) => {
    // console.log(e)
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));

  };
  const handlePhoneChange = (value) => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        setFormData(prevFormData => ({
          ...prevFormData,
          country_code: phoneNumber.countryCallingCode,
          phonenumber: phoneNumber.nationalNumber,
          country:phoneNumber.country
        }));
      }
    }
  };


  // validate phone number
  const validatePhoneNumber = (phoneNumber, countryCode) => {
    try {
      console.log(phoneNumber,countryCode);
      const parsedPhoneNumber = isValidPhoneNumber(`+${countryCode}${phoneNumber}`, countryCode);
      return parsedPhoneNumber ? null : 'Invalid phone number';
    } catch (error) {
      return 'Invalid phone number';
    }
  };

  // validate form
  const validateForm = (data) => {
    const errors = {};
    // Validate each field and add errors if necessary
    if (!data.first_name.trim()) {
      errors.first_name = "Se requiere el nombre.";
    }
    if (!data.last_name.trim()) {
      errors.last_name = "Se requiere el apellido.";
    }
    if (!data.phonenumber.trim()) {
      errors.phonenumber = "Se requiere un número de teléfono.";
    } 
    else if(validatePhoneNumber(data.phonenumber, data.country_code)){
      errors.phonenumber = 'Número de teléfono no válido'
    }
    if (!data.email.trim()) {
      errors.email = "Se requiere un correo electrónico.";
    }
    else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Dirección de correo electrónico no válida.";
    }

    if (!data.password.trim()) {
      errors.password = "Se requiere una contraseña.";
    } 
    else if (data.password.length < 8) {
      errors.password = "La contraseña debe tener al menos 8 caracteres..";
    } 
    else if (data.password.length > 127) {
      errors.password = "La longitud máxima permitida de la contraseña es de 127 caracteres.";
    } 
    else {
      // Password must contain at least one letter, one number, and one special character
      if (!/[a-zA-Z]/.test(data.password)) {
        errors.password = "Password must contain at least one letter.";
      } else if (!/\d/.test(data.password)) {
        errors.password = "Password must contain at least one number.";
      } else if (!/[!@#$%^&*]/.test(data.password)) {
        errors.password = "Password must contain at least one special character (!@#$%^&*).";
      }
    }


    if (!data.confirmPassword.trim()) {
      errors.confirmPassword = "Se requiere confirmar la contraseña.";
    } else if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Las contraseñas no coinciden.";
    }
    // if(!tnc){
    //   errors.tnc = "Please accept our Terms & Conditions to proceed.";
    // }
    return errors;
  };



  const handleLogin = async (e) => {
    e.preventDefault();
    // Validate the form fields
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      API.post(`user/register/`, formData)
        .then((response) => {
          setIsLoading(false);
          setIsSuccessModalOpen(true);
          setMessage('Usuario creada con éxito.')
          
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setIsErrorModalOpen(true)
          setFormData({
            email: '',
            first_name: '',
            last_name: '',
            country_code: '',
            phonenumber: '',
            password: '',
            confirmPassword: '',
            country: '',
          })
          if (error.response && error.response.data) {
            setMessage(error.response.data);
          } else {
            setMessage(error.message);
          }
        });
    }
  };
  
  
  return (
    <div className='regbody-admin'>

      <form className='regmainbody-admin poppins-regular' onSubmit={handleLogin}>

        <div className='reg-ad poppins-regular1'>Formulario de inscripción</div>
        <div className='fname-lname'>
          <div className='fname'>
            <label>Nombre</label>
            <input type="text" onChange={handleInputChange} name='first_name' className='inp_fname' value={formData.first_name}  />
            {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
          </div>
          <div className='lname'>
            <label>Apellido</label>
            <input type="text" onChange={handleInputChange} name='last_name' className='inp_fname' value={formData.last_name}  />
            {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
          </div>


        </div>
        <div className='address'>
          <label>Dirección de correo electrónico</label>
          <input type="mail" onChange={handleInputChange} name='email' className='username-ad-reg' value={formData.email}  />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className='address'>
          <label>Número de teléfono</label>

          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="GB"
            country={'gb'}
            name='phnumber'
            onChange={(value) => handlePhoneChange(value)}
            className='username-ad-reg'
            value={formData.phnumber}
            required />
            {errors.phonenumber && <div className="invalid-feedback">{errors.phonenumber}</div>}
        </div>
        <div className='inp1'>
          <label className=''>Contraseña</label>
          <img onClick={showeye} src='/images/admin/mainlogin-img/eye-slash.svg' className='eye-reg' style={{ display: showpass ? 'block' : 'none' }} />
          <img onClick={showeye} src='\images\admin\mainlogin-img\eye-Filled.svg' className='eyeshow-reg' style={{ display: showpass ? 'none' : 'block' }} />
          <input type={showpass ? 'text' : 'password'} name='password' className='username-ad-reg' onChange={handleInputChange} value={formData.password}  />
          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        </div>

        <div className='inp1'>
          <label className=''>Confirmar contraseña</label>
          <img onClick={showeye2} src='/images/admin/mainlogin-img/eye-slash.svg' className='eye-reg' style={{ display: showpass2 ? 'block' : 'none' }} />
          <img onClick={showeye2} src='\images\admin\mainlogin-img\eye-Filled.svg' className='eyeshow-reg' style={{ display: showpass2 ? 'none' : 'block' }} />
          <input type={showpass2 ? 'text' : 'password'} name='confirmPassword' className='username-ad-reg' onChange={handleInputChange} value={formData.confirmPassword}  />
          {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
        </div>



        <button type="submit" className='reg-btn-ad'>
        Registrar
        </button>


      </form>
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/adminlist') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  )
}

export default Registration