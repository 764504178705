import React from 'react';
import "./authentication.css"
import { Link } from 'react-router-dom';

function NewPassword() {
  return (
    <div className="container-fluid mb-3 d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card p-4">
        <h2>Set Nueva contraseña</h2>
        <form>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Nueva contraseña</label>
            <input type="password" className="form-control" id="password" />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">Confirm Nueva contraseña</label>
            <input type="password" className="form-control" id="confirmPassword" />
          </div>
          <Link className='btn-lnk' to="/password-changed"> Confirm </Link>
        </form>
       
      </div>
    </div>
  );
}

export default NewPassword;
