import React, { useRef, useState } from 'react'
import "./EditEvents.css"
import { Link } from 'react-router-dom'
import $ from 'jquery';


function EditEvents() {
   

    const [statedate,setStatedate]=useState(false)

    const [time, setTime] = useState();
    const [time2, setTime2] = useState();


    function handleChange(event) {
        let { value } = event.target;
        value = value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length > 2) {
            value = value.substring(0, 2) + ':' + value.substring(2);
        }
        setTime(value);
    }
    function handleChange2(event) {
        let { value } = event.target;
        value = value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length > 2) {
            value = value.substring(0, 2) + ':' + value.substring(2);
        }
        setTime2(value);
    }
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        // Handle form submission logic here
    };
   

   

  return (
    <div className='createpage'>
    <div className='headerofevent2'>
    <div className='titlebox2-ad dm-sans-regular'><Link to="/admin/eventsadmin" className='editevent'><img src='\images\admin\eventad\Arrow_Left.svg'/></Link><span>Crear evento</span>
    </div>
    </div>


    <form className='craeteform dm-sans-bold' onSubmit={handleSubmit}>

        <div className='names-box'>
            <div className='eachname-ad'>
                <label>Título de la actividad</label>
                <input type='text' className='namebox-ad1'/>
                

            </div>
            <div className='eachname-ad'>

            <label>Ubicación</label>
                <input type='text' className='namebox-ad1'/>

            </div>
           
        </div>

        <div className='names-box'>
            <div className='eachname-ad'>
                <label>Fecha</label>
                <input
    type="date"
    placeholder='date'
    className="namebox-ad1"
    onFocus={() => setStatedate({ isFocused: true })}
    onBlur={() => setStatedate({ isFocused: false })}
/>
                

            </div>
            <div className='time-ad'>
            <div className='eachname-ad'>

            <label>Tiempo</label>
            <input type="time"
            value={time}
           
             className='namebox-ad122'/>
                    
            </div>
            <div className='eachname-ad'>

            <label className='hide-ad'>d</label>
            <input type="time"
            value={time2}
           
             className='namebox-ad122'/>
                  

            </div>
            </div>
           
        </div>
        <div className='names-box'>
        <div className='eachname-ad2'>

        <label>Descripción</label>
                <textarea  id='desp'  className='namebox-ad2' >
                   
                    </textarea>
</div>
        </div>

<div className='btns-ad'>
<Link to="/admin/eventsadmin" className='trans-btn'>Descartar</Link>
<button className='solid-btn'>Crear evento</button>

</div>
       

    </form>

</div>
  )
}

export default EditEvents