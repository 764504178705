// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-wd-box2{
    width: 49% ;
    height: fit-content;
    position: relative;
    margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/Sunday.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".time-wd-box2{\r\n    width: 49% ;\r\n    height: fit-content;\r\n    position: relative;\r\n    margin-right: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
