import React, { useEffect, useState } from 'react';
import './Userlist.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import API from '../../API';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorPopup from '../../ErrorPopup';


function Userlist() {

  const[message,setMessage]=useState("");
  const[isErrorModalOpen,setIsErrorModalOpen]=useState(false);
  const [view, setView] = useState(false);
  const [events, setEvents] = useState([]);
  const [showcal, setShowcal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [viewedIndex, setViewedIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  

  


  const viewbtn = (index) => {
    setView(true);
    setViewedIndex(index);
  }

  const itemsPerPage = 6;
 
  const totalPages = Math.ceil(events.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = events.slice(startIndex, endIndex);

  const [showfilter, setShowfilter] = useState(false);
  const fltshow = () => {
    setShowfilter(!showfilter);
  }
  
  const [filters,setFilters]=useState({
    date:null,
    search_key:null
  })
 
  const handleclickdate = () => {
    setShowcal((prev) => !prev)
  }

  

  useEffect(() => {
    const fetchEvents = async () => {
      let apiUrl=`/reservations/?page=${1}`;
      
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    
      try {
       
        const response = await API.get(apiUrl);
        console.log("API success in userlist", response);
        setEvents(response.data.results);
      } catch (error) {
        console.log("API error in userlist", error);
        setMessage(error.message)
        setIsErrorModalOpen(true)
       
      }
    };
    fetchEvents();
  }, [filters]);

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };


  

  const handleDatechage = (option) => {
    const formattedDate = format(option, 'dd/MM/yyyy');
    setFilters((filters) => ({
      ...filters,
      date: formattedDate
    }));
    setStartDate(option)
    setShowfilter(!showfilter);
    console.log(option, "option")
  }




  return (
    <div className='createpage7'>
      <div className='headerofevent-ul'>
        <div className='titlebox-ad2 dm-sans-regular'><span>Lista de reservas</span></div>
        <div className='searchandfilter'>
        <div className='search-message-box'>
          <input type='text' className='search-message' placeholder='Buscar por nombres' name='search_key' onChange={handleInputChange} />
          <img src="\images\admin\message\Shape.svg" className='srlogo-ul' />
        </div>
        <div className='filterbox-ad-ul'>
          <div className='sortby-ul dm-sans-regular daterel'  onClick={fltshow}>
            <span className='dm-sans-regular create3' onClick={handleclickdate}>Filtrar :Ingrese la fecha</span>
            {showcal &&
            <div className='datepick'>
      <DatePicker className='hiddencal' selected={startDate} onChange={handleDatechage}/>
        </div>
            }
          </div>
         
        </div>
        </div>
      </div>

    

      <div className='scrollx'>
        <table className='table-userlist'>
          <thead>
            <tr className='tablerow-userlist'>
              <th className='tablesubhead-userlist'>Fecha</th>
              <th className='tablesubhead-userlist'>Nombre</th>
              <th className='tablesubhead-userlist'>Correo electrónico</th>
              <th className='tablesubhead-userlist'>Número de teléfono</th>
              <th className='tablesubhead-userlist'>Número de invitados</th>
              <th className='tablesubhead-userlist'>Nombre del evento</th>
              <th className='tablesubhead-userlist'>Comentarios</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((event, index) => (
              <tr key={index} className='tr-of-userlist'>
                <td className='userlist-content'>{event.created_at}</td>
                <td className='userlist-content'>{event.username}</td>
                <td className='userlist-content'>{event.email}</td>
                <td className='userlist-content'>{event.phone_number}</td>
                <td className='userlist-content'>{event.guests}</td>
                <td className='userlist-content'>{event.title}</td>
                <td className='userlist-content1' onClick={() => viewbtn(index)}>view</td>
              </tr>
            ))}
          </tbody>
          <tr className='btn-table-userlist'>
            <td colSpan="7" className='btn-userlist2'>
              <div className='btn-userlist'>
                <div onClick={prevPage} className='divarr'>
                  <img src="/images/admin/userlist/left.svg" className='pagearr' alt="Previous" />
                </div>
                <span className='pagination'>
                  <span className='numberpage'>{currentPage}</span>
                  <span className='numberpage1'>/ {totalPages}</span>
                </span>
                <div onClick={nextPage} className='divarr'>
                  <img src="/images/admin/userlist/Vector (5).svg" className='pagearr' alt="Next" />
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      {view && viewedIndex !== -1 && (
        <div className='backgrd-del'>
          <div className='delete-body'>
            <span className='cross-ad-ul'>
              <img src="/images/admin/userlist/Vector (4).svg" onClick={() => setViewedIndex(-1)} alt="Close" />
            </span>
            <span className='content-body-ul'>{events[viewedIndex].comments}</span>
          </div>
        </div>
      )}

<ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  );
}

export default Userlist;
