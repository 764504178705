import React, { useContext } from 'react'
import { UserContext } from './authentication/pages/UserContext'
import "./Errormodal.css"

function ErrorModal() {

  const {closeError,showerror,setShowerror,message,setMessage}=useContext(UserContext);
  
  

  return (
    <div className='errorbackground d-flex align-items-center justify-content-center'>

    <div className=' text-bg-dark p-3 errormodal d-flex align-items-center justify-content-center flex-column warningbox'>
        <p>{message}</p>
        
        <button  className="btn-error btn btn-danger" onClick={closeError}>close</button>
    </div>
    </div>
  )
}

export default ErrorModal