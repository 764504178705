import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PdfViewer from '../common-components/PdfViewer';
import $ from "jquery"
import './BulletinPreview.css'
import LoadingSpinner from '../../LoadingSpinner';
import API from '../../API';
import ErrorPopup from '../../ErrorPopup';
import Delete from '../common-components/Delete';
import PositiveModal from '../../PositiveModal';

function BulletinPreview() {
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])
  const navigate = useNavigate();
  const location = useLocation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { bulletin_id } = location.state || {};
  useEffect(() => {
    API.get(`bulletins/${bulletin_id}/`)
      .then(response => {
        setPdfUrl(response.data.pdf_file_url)
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  }, [bulletin_id]);

  // Delete data
  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  }
  const deleteItem = (id) => {
    setIsLoading(true);
    API.delete(`bulletins/${id}/`)
      .then(response => {
        setIsLoading(false);
        console.log(response.data);
        setMessage('Boletín eliminado exitosamente.')
        navigate('/admin/bulletin/')
      })
      .catch(error => {
        setIsLoading(false);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });

  }

  const sendBulletin = (id) => {
    setIsLoading(true);
    API.get(`bulletins/${id}/send-email/`)
      .then(response => {
        setIsLoading(false);
        console.log(response.data);
        setMessage('Boletín enviado exitosamente.')
        setIsSuccessModalOpen(true)
      })
      .catch(error => {
        setIsLoading(false);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });

  }



  return (
    <div className='bullet-pdf'>
      <div className='top-sec'>
        <div className='titlebox-ad dm-sans-regular'>
        Ver Boletín
        </div>
        <div className='btns'>
          <button className="discard-cb" onClick={handleDelete}>
          Eliminar
          </button>
          <button className="solid-cb" onClick={()=>sendBulletin(bulletin_id)}>
            Enviar Boletín
          </button>

        </div>

      </div>
      <div className='bulletin-pdf'>
        <PdfViewer file={pdfUrl} />
      </div>

      {isDeleteModalOpen ? <Delete onSubmit={() => deleteItem(bulletin_id)} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => () => setIsErrorModalOpen(false)} />
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={()=> navigate('/admin/bulletin/') } />}
    </div>
  );
}

export default BulletinPreview;
