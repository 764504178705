import React, { useContext, useEffect, useState } from 'react';
import "./AdminSidebar.css";
import { UserContext } from '../../authentication/pages/UserContext';
import { Link, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';
import API from '../../API';

function AdminSidebar() {

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { isMobileMenuOpen, setMobileMenuOpen, setMobilemenu, showMasstime, setShowMasstime, showWebpage, setShowWebpage, setTitle, title } = useContext(UserContext);
  const [selectedRadio, setSelectedRadio] = useState('');

  // Active Menu
  const location = useLocation();
  const isActiveMenu = (url) => {
    return location.pathname.startsWith(url);
  };
  const hasActiveSubItem = (items) => {
    return items.some(item => isActiveMenu(item.frontend_url));
  };




  const handleTitle = () => {
    const path = window.location.pathname;
    let selectedRadio = null;
    switch (path) {
      case '/admin/eventsadmin':
        selectedRadio = 'Evento';
        break;
      case '/admin/masstime':
        selectedRadio = 'Mass Time';
        break;
      case '/admin/userlist':
        selectedRadio = 'Users List';
        break;
      default:
        break;
    }
    setSelectedRadio(selectedRadio);
    if (!selectedRadio){
      setShowMasstime(false);
    }

  };

  useEffect(() => {
    handleTitle();
  }, [location.pathname]);

  const handleRadioChange = (radioId) => {
    setSelectedRadio(radioId);
  };

  const toggleShowWebsitepage = () => {
    setShowWebpage(!showWebpage); // Toggle the value of 'showWebpage'
    setShowMasstime(false); // Close the mass time radio
    setMobileMenuOpen(false);
  };

  const toggleShowMasstime = () => {
    setShowMasstime(!showMasstime); // Toggle the value of 'showMasstime'
    setShowWebpage(false); // Close the website pages radio
    setTitle({ title: "Hora del evento y misa" });
  };

  const event = () => {
    setMobileMenuOpen(false);
    document.getElementById("Event").checked = true;
  };

  const masstime = () => {
    setMobileMenuOpen(false);
    document.getElementById("Mass Time").checked = true;
  };

  const userlist = () => {
    setMobileMenuOpen(false);
    document.getElementById("Users List").checked = true;
  };

  const [navList, setNavList] = useState([]);



  // Load data from backend
  const loadNavData = () => {
    setIsLoading(true);
    API.get(`frontend-categories/`)
      .then(response => {
        // Add extra object to the response data
        const updatedNavList = [
          ...response.data,
          {
            id: 6, label: "Configuración", frontend_url: "/admin/settings",
            icon_image_url: "/images/admin/sidebar/settings-Filled.svg", position: 7,
          }
        ];
        setNavList(updatedNavList);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };
  // Call loadData 
  useEffect(() => {
    loadNavData();
  }, []);



  return (
    <div className='container-fluid-sidebar' id='sideNavbar'>
      <div className='logo-box'>
        <img src="/images/admin/sidebar/logo-spanish 1 1.svg" className='churchlogo' alt="Church Logo" />
      </div>
      <br /><br />

      <div className='button-body'>
        <p className='poppins-regular1 general'>GENERAL</p>
        {navList.map((item) => (
          <React.Fragment key={item.id}>
            {item.items && item.items.length > 0 ? (
              <>
                <button
                  type="button"
                  className={`btn ${hasActiveSubItem(item.items) ? 'active-menu' : ''}`}
                  onClick={toggleShowMasstime}
                >
                  <span className='opt-div'>
                    <img className="logos-in-nav" src={item.icon_image_url} alt={item.label} />
                    <span className='opt dm-sans-regular'>{item.label}</span>
                  </span>
                  <i className={`bi bi-chevron-${showMasstime ? 'down' : 'right'}`}></i>
                </button>
                <br />
                {showMasstime && (
                  <div className='rdo-opt-main'>
                    {item.items.map((subItem) => (
                      <div className='radio-btn' key={subItem.id}>
                        <Link to={subItem.frontend_url} className='rdo-link dm-sans-regular' onClick={() => {
                          if (subItem.label === "Event") event();
                          else if (subItem.label === "Mass Time") masstime();
                          else if (subItem.label === "Users List") userlist();
                          setMobileMenuOpen(false);
                        }}>
                          <label className="form-check-label" htmlFor={subItem.label} onClick={() => {
                            if (subItem.label === "Event") event();
                            else if (subItem.label === "Mass Time") masstime();
                            else if (subItem.label === "Users List") userlist();
                            setMobileMenuOpen(false);

                          }}>
                            <input
                              className="form-check-input"
                              id={subItem.label}
                              type="radio"
                              name="flexRadioDefault"
                              checked={selectedRadio === subItem.label}
                              onChange={() => handleRadioChange(subItem.label)}

                            />
                            &nbsp;
                            <span className="radio-dot"></span>{subItem.label}
                          </label>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <Link to={item.frontend_url} className='rdo-mainhead' key={item.id}>
                <button
                  type="button"
                  className={`btn ${isActiveMenu(item.frontend_url) ? 'active-menu' : ''}`}
                >
                  <span className='opt-div'>
                    <img
                      src={item.icon_image_url}
                      className='logos-in-nav'
                      alt={item.label}
                    />
                    <span className='opt dm-sans-regular'>{item.label}</span>
                  </span>
                </button>
                <br />
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* <div className='colordiv dispnone'>
        <span className='dm-sans-bold opt2'>Tap to View the New Bulletin</span>
        <img src="/images/admin/sidebar/showdown.svg" className='showdown' alt="Showdown" />
      </div> */}

      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
    </div>
  );
}

export default AdminSidebar;
