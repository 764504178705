import React, { useContext, useEffect, useRef, useState } from 'react'
import "./WebsitePDFs.css"
import $ from "jquery"
import { UserContext } from '../../authentication/pages/UserContext';
import Delete from "../common-components/Delete"

import { Document, Page } from 'react-pdf';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import { useNavigate } from 'react-router-dom';


function WebsitePDFs() {
  const navigate = useNavigate();
  const maxSize = 20 * 1024 * 1024;

  const [pageData, setPageData] = useState([
    {
      page_name: 'Parroquia',
      sub_pages: [
        {
          sub_name: 'Pastoral Parroquia',
          files: [null, null]
        },
        {
          sub_name: 'Equipo Sacerdotal Parroquial',
          files: [null, null]
        },
        {
          sub_name: 'Economia',
          files: [null, null]
        },
      ]
    },
    {
      page_name: 'Liturgia',
      sub_pages: [
        {
          sub_name: 'Bautismo',
          files: [null, null]
        },
        {
          sub_name: 'Confirmación',
          files: [null, null]
        },
        {
          sub_name: 'Confesión',
          files: [null, null]
        },
        {
          sub_name: 'Unción de los Enfermos',
          files: [null, null]
        },
        {
          sub_name: 'Orden sacerdotal y la Vocación Agustiniana',
          files: [null, null]
        },
        {
          sub_name: 'Matrimonio',
          files: [null, null]
        }
      ]
    },
    {
      page_name: 'Catequesis',
      sub_pages: [
        {
          sub_name: 'Primera',
          files: [null, null]
        },
        {
          sub_name: 'Confirmación de jóvenes',
          files: [null, null]
        },
        {
          sub_name: 'Confirmatión de Adultos',
          files: [null, null]
        }
      ]
    },

    {
      page_name:"Groupos",
      sub_pages:[
        {
          sub_name: 'Adoración Nocturna',
          files: [null, null]
        },
        {
          sub_name: 'Grupo Charlas Formativas',
          files: [null, null]
        },
        {
          sub_name: 'Grupo De Liturgia',
          files: [null, null]
        },
        {
          sub_name: 'Adoración De Jóvenes',
          files: [null, null]
        },
        {
          sub_name: 'Emaús Mujeres',
          files: [null, null]
        },
      ]
    },
    {
      page_name: 'Accion Social',
      sub_pages: [
        {
          sub_name: 'Cáritas Parroquial',
          files: [null, null]
        },
        {
          sub_name: 'Costurero misionero parroquial',
          files: [null, null]
        },
        {
          sub_name: 'Voluntariado misionero agustiniano',
          files: [null, null]
        },
      ]
    },
    {
      page_name: 'Despacho',
      sub_pages: [
        {
          sub_name: 'Despacho',
          files: [null, null]
        },
        {
          sub_name: 'Colaboración',
          files: [null, null]
        },
        {
          sub_name: 'Intenciones De Misas',
          files: [null, null]
        },
      ]
    },
    {
      page_name: 'Oracion',
      sub_pages: [
        {
          sub_name: 'Oración a la Virgen María',
          files: [null, null]
        },
        {
          sub_name: 'Oracion A Santa Rita',
          files: [null, null]
        },
        {
          sub_name: 'lectura y reflexiones del domingo',
          files: [null, null]
        },
      ]
    }
  ])


  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(0);

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Get Existing Data from Backend
  const  loadData = () => {
    pageData.forEach((page, pageIndex) => {
      page.sub_pages.forEach((subPage, subPageIndex) => {
        setIsLoading(true);
        API.get(`pdf/?title=${page.page_name}&subtitle=${subPage.sub_name}`)
          .then(response => {
            setIsLoading(false);
            // console.log(response.data);
            const filesFromBackend = response.data.results; // Assuming response.data.files contains an array of files
            // Update files for the current sub-page
            const updatedFiles = filesFromBackend.map(file => {
              const url = file.pdf;
              const fileName = url.substring(url.lastIndexOf('/') + 1);
              return { file: url, id: file.id, name: fileName,created_at:file.created_at };
            });
          // Ensure the files array has 2 elements, filling with null if necessary
            while (updatedFiles.length < 2) {
              updatedFiles.push(null);
            }
            // Update the state
            setPageData(prevPageData => {
              const newPageData = [...prevPageData];
              newPageData[pageIndex].sub_pages[subPageIndex].files = updatedFiles;
              return newPageData;
            });
          })
          .catch(error => {
            setIsLoading(false);
          });
      });
    });
  }
  
  
  useEffect(() => {
    loadData()
  }, []);


 
  useEffect(() => {
    console.log("pageData",pageData)
  }, [pageData]);


  // to toggle section
  const [openPages, setOpenPages] = useState(Array(pageData.length).fill(true));
  const togglePageSection = (index) => {
    setOpenPages(prevOpenPages =>
      prevOpenPages.map((isOpen, i) =>
        i === index ? !isOpen : isOpen
      )
    );
  };

  const handleFileChange = (event, pageIndex, subPageIndex, fileIndex) => {
    const file = event.target.files[0];
    if (file.size > maxSize) {
      setMessage('The file size exceeds 3MB');
      setIsErrorModalOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append('title', pageData[pageIndex].page_name);
    formData.append('subtitle', pageData[pageIndex].sub_pages[subPageIndex].sub_name);
    formData.append('pdf', file);
    // console.log(formData);
    setIsLoading(true);
    API.post(`pdf/`, formData, { headers: { 'Content-Type': 'multipart/form-data', } })
      .then((response) => {
        setIsLoading(false);
        loadData();
        // console.log(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsErrorModalOpen(true);
        setMessage(error.message);
        console.log(error);
      });


  };


  const handleFileDelete = (file) => {
    setSelectedItemID(file.id);
    setIsDeleteModalOpen(true)
    // console.log(file);
  }
  const deleteItem = (id) => {
    API.delete(`pdf/${id}/`)
      .then(response => {
        // console.log(response.data);
        setIsSuccessModalOpen(true);
        setMessage('File deleted successfully')
      })
      .catch(error => {
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
    setIsLoading(false);
  }

  const addUploadFields = (pageIndex, subPageIndex) => {
    setPageData(prevPageData => {
      const newPageData = [...prevPageData];
      newPageData[pageIndex].sub_pages[subPageIndex].files.push(null, null); // Add two new null entries
      return newPageData;
    });
  };

  const [isScrollingUp1, setIsScrollingUp1] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrollingUp1(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  const handlePDFView = (file) => {
    navigate('/admin/website/pdf', { state: { file } });
  };

  // console.log(pageData);




  return (
    <div className={`in-body ${isScrollingUp1 ? 'scrolling-up1' : ''}`}>
      {/* Iterate through Page divisions */}
      {pageData.map((page, pageIndex) => (
        <>
          <p className=' gal dm-sans-regular'>{page.page_name}  <img src="\images\admin\inicilar\Vector (4).svg" className='downar-par' onClick={() => togglePageSection(pageIndex)} /></p>
          {openPages[pageIndex] ?
            <div className='main-gal-in'>
              {/* Iterate through Sub divisions */}
              {page.sub_pages.map((sub_page, subPageIndex) => (

                <div className='gallery1'>

                  <div className='gal1' key={subPageIndex}>
                    <div className='firstwide50'>
                      <p className='dm-sans-regular insidetitle'>{sub_page.sub_name}</p>
                    </div>
                    <div className='first2wide50'>
                      {sub_page.files.map((file, fileIndex) => (
                        <>
                          <div className='upload' key={fileIndex} >
                            <input type='text' className={`smallinp-cb-up ${file ? 'input-with-file' : 'input-without-file'}`}
                              readOnly value={file ? file.name : "Subir archivo PDF"}
                              style={{
                                ...(file && {
                                  backgroundImage: 'url("/images/Vector (4).svg")',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'left 10px center',
                                  paddingRight: file.name.length > 20 ? '30px' : '0px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textAlign: "left"
                                })
                              }}
                              onClick={() => !file && document.getElementById(`fileInput-${pageIndex}-${subPageIndex}-${fileIndex}`).click()} />
                            <input type='file'
                              style={{ display: 'none' }}
                              className='file-upload'
                              accept='.pdf'
                              onChange={(event) => handleFileChange(event, pageIndex, subPageIndex, fileIndex)}
                              id={`fileInput-${pageIndex}-${subPageIndex}-${fileIndex}`}

                            />


                          </div>

                          <div className='btns'>
                            <div className={`view2 ${file ? 'click' : ''}`} onClick={() => file && handleFileDelete(file)}><img src='\images\admin\inicilar\Trash.svg' /> </div>
                            <div className={`view2 ${file ? 'click' : ''}`} onClick={() => file && handlePDFView(file)}> <img src="\images\admin\inicilar\Eye_Open.svg" /> </div>
                          </div>
                        </>
                      ))}

                      <div className='view' onClick={() => addUploadFields(pageIndex, subPageIndex)}>
                        <img src="/images/admin/inicilar/Shape.svg" />
                      </div>
                    </div>

                  </div>

                  


                </div>
              ))}

            </div>
            : ""}

        </>
      ))}
      {isDeleteModalOpen ? <Delete onSubmit={() => deleteItem(selectedItemID)} setterFunction={setIsDeleteModalOpen} /> : ""}
      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={loadData} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  )
}

export default WebsitePDFs