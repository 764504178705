import React, { useContext } from 'react';
import "./authentication.css"
import { Link } from 'react-router-dom';
import Header from '../../user-panel/common-components/Header';
import { UserContext } from './UserContext';

function Welcomepage() {
    const form =(e)=>{
        e.preventDefault();
      }

  return (
    <div className='container-fluid background-body'>  
   
        <form className="form-welcome" onSubmit={form}>
          <div className='welcome-ad'>
            <img src='\images\logo-spanish 1 1.svg' className='logo-admin-login'/>
            <span className='welcometext poppins-regular'>Bienvenido al <br/>panel de administración</span>
           
            <Link to="/admin" className='bluebtn-login manrope-text-bolder'>Ir al panel</Link>
          </div>

          <div className='side-welcome-img-box'>
          <img src='\images\Rectangle 5102.png' className='sideimg-admin-login'/>


          </div>


          
        </form>
       
    </div>
  )
}

export default Welcomepage