import React, { useContext, useRef, useState } from 'react';
import "./Messagepopup.css";
import { UserContext } from '../../authentication/pages/UserContext';
import API from '../../API';
import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import { useNavigate } from 'react-router-dom';

function Messagepopup({ messageItem, toReply, setterFunction }) {
    let navigate = useNavigate();
    const loadPage = () => {
        navigate(window.location.pathname);
    };
    const fileInputRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageModalOpen, setMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const handlePinClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    console.log(uploadedFile, "in file")

    const formatFileSize = (sizeInBytes) => {
        const sizeInKB = sizeInBytes / 1024;
        if (sizeInKB < 1024) {
            return `${sizeInKB.toFixed(2)} KB`;
        } else {
            const sizeInMB = sizeInKB / 1024;
            return `${sizeInMB.toFixed(2)} MB`;
        }
    };

    const [replyText, setReplyText] = useState(messageItem.reply);
    const [deleteconf, setDeleteconf] = useState(false);

    const confdeletebtn = () => {
        if (toReply) {
            setDeleteconf(!deleteconf);
        } else {
            setterFunction(false);
        }
    };

    const handleSubmit = () => {
        if (replyText && replyText.trim()) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('reply', replyText);
            if (uploadedFile) {
                formData.append('file', uploadedFile);
            }
            
            API.put(`contact-messages/${messageItem.id}/`, formData,
                { headers: {
    
                "Content-Type": "multipart/form-data"
                
              }})

                .then((response) => {
                    console.log(response);
                    setMessageModalOpen(true);
                    setMessage('Reply sent successfully!');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    };

    return (
        <div className='msgpop-body'>
            <div className='cardbody-messagepop'>
                <p className='dm-sans-bold title-messagepop'>Mensaje de</p>
                <p className='dm-sans-bold subtitle-messagepop'>Dirección de correo electrónico: {messageItem.email}</p>
                <p className='dm-sans-bold subtitle-messagepop'>MESSAGE</p>
                <textarea type='text' className='firstbox-messagepop' value={messageItem.message} readOnly></textarea>
                <p className='dm-sans-bold subtitle-messagepop'>{toReply ? 'Escribir Respuesta' : 'REPLY'}</p>
                <div className='alltextandupload'>
                    <textarea value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        type="text" className='firstbox-messagepop2'>
                    </textarea>
                    <div className='docview'>
                        <input ref={fileInputRef}
                            type="file"
                            accept=".pdf"
                            style={{ display: 'none' }}
                            onChange={handleFileChange} />
                        {uploadedFile && (
                            <div className='pdfmainbox'>
                                <div className='pdfmarkon'>
                                    <div className='pdf-box'>
                                        <img src="\images\admin\message\Layer 10.svg" alt="PDF Logo" />
                                    </div>
                                    <div className='uplo-message-div'>
                                        <span className='dm-sans-bold'>{uploadedFile.name}</span>
                                        <span style={{ color: "rgba(70, 70, 70, 1)", fontSize: "12px", fontWeight: "600" }}>{formatFileSize(uploadedFile.size)}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='hr-msg'></div>
                    {toReply &&
                        <div className='textdecor-btns'>
                            <div className='textdecor-each-btn dm-sans-regular' style={{ cursor: 'pointer' }} onClick={handlePinClick}><img src="\images\admin\message\icAttachment.svg" className='btns-mesg' /> Subir Documentos</div>
                        </div>
                    }
                </div>
                {toReply &&
                    <div className='mainbtns-sendreply' onClick={handleSubmit}>Enviar Mensaje</div>
                }
                <p className='close-messagepop' onClick={confdeletebtn}>Cerrar</p>
                {deleteconf ?
                    <div className='doyouwanttoclose'>
                        <img src="\images\admin\message\Group 289681.svg" onClick={() => setterFunction(false)} />
                        <span className=' dm-sans-bold textofdele'>¿Salir sin dejar un mensaje?</span>
                        <span className="dm-sans-bold confirmdele" onClick={() => setterFunction(false)}>Sí, Confirmar</span>
                        <span className="dm-sans-bold backdele" onClick={confdeletebtn}>Atrás</span>
                    </div> : ""}
            </div>
            {isLoading && <LoadingSpinner />}
            {isMessageModalOpen && <PositiveModal message={message} setterFunction={setterFunction} okClickedFunction={loadPage} />}
        </div>
    );
}

export default Messagepopup;
