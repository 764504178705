import React, { useState, useEffect } from 'react'
import "./CreateBulletin.css"
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../../ErrorPopup';
import PositiveModal from '../../PositiveModal';
import LoadingSpinner from '../../LoadingSpinner';
import BulletinPreview from './BulletinPreview';
import { Description } from '@mui/icons-material';


function OracionALaSantaMaria() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errors, setErrors] = useState(false)
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
      page_name: "st-marria-prayer",
      section_number: "1",
      title: "",
      description: "",
      link:""

  })


  

  useEffect(() => {

      const fetchData = async () => {
          try {
              const response = await API.get(`websitecontent/?page_name=st-marria-prayer&section_number=1`);
              const eventData = response.data.results[0];
              console.log("this is success in fetching data from masstime");
              console.log("response", response);



              // Set the form data with the formatted date
              setFormData(eventData);
          } catch (error) {
              console.log("API error", error);
          }
      };
      fetchData();

  }, []);


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      console.log(e)
      setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
      }));

      console.log(formData)

  };





  const validateForm = (data) => {
    const errors = {};
    if (!data.title.trim()) {
      errors.title = "El título es obligatorio.";
    }
    if (!data.description.trim()) {
      errors.description = "El contenido es obligatorio.";
    }
    



    return errors;
  };

  const handleSubmit = (event) => {
      event.preventDefault(); // Prevent the default form submission
      // Handle form submission logic here


        // Validate form data
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    // Proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);

      API.put(`websitecontent/${formData.id}/`, formData).then(response => {
         // navigate('/admin/masstime');\
         setIsLoading(false)
      })
          .catch(error => {
              setMessage(error.message)
              setIsErrorModalOpen(true)
          })



  };

  }





 






  return (
    <div className='createbulletin-body'>
      <div className='headerofevent-bul'>
        <div className='titlebox-ad dm-sans-regular'><span>Oración a la Virgen María</span></div>
      </div>

      <div className='input-body-div-cb dm-sans-regular '>
        <div className='title-cb'>
          <label className='lbl-cb'>Título</label>
          <input type='text' className='title-inp-cb' name='title' value={formData.title} onChange={handleInputChange} />
          {errors.title && <p className='invalid-feedback'>{errors.title}</p>}
        </div>

        <div className='title-cb'>
          <label className='lbl-cb'>Oración</label>
          <textarea type='text' className='title-inp-cb2' name='description' value={formData.description} onChange={handleInputChange} />
          {errors.description && <p className='invalid-feedback'>{errors.description}</p>}
        </div>


        <div className='title-cb-combine'>
          <div className='innertitle-cb w-100'>
            <label className='lbl-cb'>Link</label>
            <input
              type='text'
              name='link'
              className={`smallinp-cb ${errors[`programme_name`] ? 'invalid-input' : ''}`}
              value={formData.link}
              onChange={handleInputChange}
            />
            {errors.link && <p className='invalid-feedback'>{errors.link}</p>}

          </div>


        </div>

        

        <div className='btn-cb d-none'>
          <button className='btn-save-cb'  >
            Guardar
          </button>
        </div>

        <div className='hrdashed' />


        <div className='btn-cb-savedis'>
          <Link to="/admin/boletines-generales" className="discard-cb">
            Descartar
          </Link>
          <button className="solid-cb" onClick={handleSubmit} >
          Guardar
          </button>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { setIsSuccessModalOpen(false) }} />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
    </div>
  )
}

export default OracionALaSantaMaria;
