import React, { useEffect, useState } from 'react'
import "./Sunday.css"
import $ from "jquery"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import API from '../../API'
import PositiveModal from '../../PositiveModal'
import ErrorPopup from '../../ErrorPopup'
import LoadingSpinner from '../../LoadingSpinner'

function Sunday() {

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [originalSchedules, setOriginalSchedules] = useState([]);
  useEffect(() => {
    // Save original schedules when component mounts
    setOriginalSchedules([...schedules]);
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      // Save original schedules before setting to null
      setOriginalSchedules([...schedules]);
    }
    const updatedSchedules = schedules.map(schedule => {
      if (schedule.day_shift === 'morning') {
        return {
          ...schedule,
          start_time: isChecked ? originalSchedules.find(s => s.id === schedule.id).start_time : null,
          end_time: isChecked ? originalSchedules.find(s => s.id === schedule.id).end_time : null,
          mass_type: isChecked ? originalSchedules.find(s => s.id === schedule.id).mass_type : null
        };
      }
      return schedule;
    });
    updateSchedule(updatedSchedules);
  };

  const [isChecked2, setIsChecked2] = useState(false);

  const handleToggle2 = () => {
    setIsChecked2(!isChecked2);
    if (!isChecked2) {
      // Save original schedules before setting to null
      setOriginalSchedules([...schedules]);
    }
    const updatedSchedules = schedules.map(schedule => {
      if (schedule.day_shift === 'afternoon') {
        return {
          ...schedule,
          start_time: isChecked2 ? originalSchedules.find(s => s.id === schedule.id).start_time : null,
          end_time: isChecked2 ? originalSchedules.find(s => s.id === schedule.id).end_time : null,
          mass_type: isChecked2 ? originalSchedules.find(s => s.id === schedule.id).mass_type : null
        };
      }
      return schedule;
    });
    updateSchedule(updatedSchedules);
  };
  const [isChecked3, setIsChecked3] = useState(false);

  const handleToggle3 = () => {
    setIsChecked3(!isChecked3);
    if (!isChecked3) {
      // Save original schedules before setting to null
      setOriginalSchedules([...schedules]);
    }
    const updatedSchedules = schedules.map(schedule => {
      if (schedule.day_shift === 'confession') {
        return {
          ...schedule,
          start_time: isChecked3 ? originalSchedules.find(s => s.id === schedule.id).start_time : null,
          end_time: isChecked3 ? originalSchedules.find(s => s.id === schedule.id).end_time : null,
          mass_type: isChecked3 ? originalSchedules.find(s => s.id === schedule.id).mass_type : null
        };
      }
      return schedule;
    });
    updateSchedule(updatedSchedules);
  };


  const location = useLocation();
  const { title, data } = location.state || {
    title: "Sunday and Holidays",
    data: [],
  };
  const [schedules, setSchedules] = useState(data);

  const updateSchedule = (updatedSchedules) => {
    setSchedules(updatedSchedules);
    // You can perform additional logic here, such as saving to backend or local storage
  };

  const handleInputChange = (index, field, value) => {
    const updatedSchedules = [...schedules];
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      [field]: value
    };
    updateSchedule(updatedSchedules);
  };

  const formatTime = (time) => {
    // Check if time is null or undefined and return an empty string
    if (!time) return '';
    // Function to format time as HH:mm without modifying the data
    return time.slice(0, 5); // Extract HH:mm from HH:mm:ss
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    console.log(schedules);
    setIsLoading(true);
    API.put(`mass/bulk_update/`, schedules)
      .then((response) => {
        setIsLoading(false);
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true)
        if (error.response && error.response.data) {
          setMessage(error.response.data);
        } else {
          setMessage(error.message);
        }
      });
    // Handle form submission logic here
  };


  return (
    <div className='createpage'>

      <div className='headerofevent2'>
        <div className='titlebox2-ad dm-sans-regular'><span>Sunday and Holidays</span>
        </div>
      </div>

      <form className='craeteform dm-sans-bold' onSubmit={handleSubmit}>
        <p className='titlebox-wd dm-sans-bold '>Sunday and Holidays</p>
        <p className='subtext-wd dm-sans-bold '>Morning</p>
        <p className='subtext2-wd dm-sans-bold '>Tiempo</p>
        {schedules.map((schedule, index) => (
          // Check if the schedule's day_shift is "morning"
          schedule.day_shift === 'morning' && (
            <div key={index} className='time-inp-box'>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.start_time)}
                  onChange={(e) => handleInputChange(index, 'start_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.end_time)}
                  onChange={(e) => handleInputChange(index, 'end_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box2'>
                <input type='text' className='time-wd' placeholder='Type of mass' value={schedule.mass_type || ''}
                  onChange={(e) => handleInputChange(index, 'mass_type', e.target.value)} />
              </div>
            </div>
          )
        ))}
        <div className='rdo-wd-box' onClick={handleToggle}>
          <input type='radio' className='rdo-wd' checked={isChecked} readOnly />
          <label className='textof-rdo'>Sin Hora de Misa</label>
        </div>

        <p className='subtext-wd dm-sans-bold '>Afternoon</p>
        <p className='subtext2-wd dm-sans-bold '>Tiempo</p>
        {schedules.map((schedule, index) => (
          // Check if the schedule's day_shift is "afternoon"
          schedule.day_shift === 'afternoon' && (
            <div key={index} className='time-inp-box'>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.start_time)}
                  onChange={(e) => handleInputChange(index, 'start_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.end_time)}
                  onChange={(e) => handleInputChange(index, 'end_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box2'>
                <input type='text' className='time-wd' placeholder='Type of mass' value={schedule.mass_type || ''}
                  onChange={(e) => handleInputChange(index, 'mass_type', e.target.value)} />
              </div>
            </div>
          )
        ))}
        <div className='rdo-wd-box' onClick={handleToggle2}>
          <input type='radio' className='rdo-wd' checked={isChecked2} readOnly />
          <label className='textof-rdo'>Sin Hora de Misa</label>

        </div>
        <p className='subtext-wd dm-sans-bold '>Confesiónes</p>
        <p className='subtext2-wd dm-sans-bold '>Tiempo</p>
        {schedules.map((schedule, index) => (
          // Check if the schedule's day_shift is "confession"
          schedule.day_shift === 'confession' && (
            <div key={index} className='time-inp-box'>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.start_time)}
                  onChange={(e) => handleInputChange(index, 'start_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box'>
                <input type='time' className='time-wd' value={formatTime(schedule.end_time)}
                  onChange={(e) => handleInputChange(index, 'end_time', e.target.value)} />
                <img className='timelogo-oh' src="\images\admin\Masstime\Alarm.svg" alt="Alarm" />
              </div>
              <div className='time-wd-box2'>
                <input type='text' className='time-wd' placeholder='Type of mass' value={schedule.mass_type || ''}
                  onChange={(e) => handleInputChange(index, 'mass_type', e.target.value)} />
              </div>
            </div>
          )
        ))}
        <div className='rdo-wd-box' onClick={handleToggle3}>
          <input type='radio' className='rdo-wd' checked={isChecked3} readOnly />
          <label className='textof-rdo'>Sin Hora de Misa</label>

        </div>

        <div className='btns-ad'>

          <Link to="/admin/masstime" className='trans-btn'>Descartar</Link>
          <button className='solid-btn' >Guardar cambios</button>

        </div>



      </form>


      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/masstime') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />


    </div>
  )
}

export default Sunday