import React, { useEffect } from 'react'
import "./Pdfview.css"
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery"

import Pdfvieweruser from '../common-components/Pdfvieweruser';
function Pdfview() {
    useEffect(() => {
        $(function () {
          $(window).scrollTop(0);
        });
      }, [])
      const navigate = useNavigate();
      const location = useLocation();
      const {  pdf_url, bulletin_id } = location.state || {};

      console.log( pdf_url, bulletin_id  ,"inside passed file")
    
      const formatDate = (timestamp) => {
        const dateObj = new Date(timestamp);
        const options = { day: 'numeric', month: 'long', year: 'numeric', };
        return dateObj.toLocaleDateString('en-GB', options);
      }
   

  return (
    <div >
      
      <div className='pdfbox'>
      <div className='top-seco poppins-regular1 color'>
        <div className='back-btnz' onClick={()=>navigate('/bulletin')}>
          <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.548 2.56188C11.1343 1.97609 11.1343 1.02635 10.548 0.440561C9.96164 -0.145226 9.011 -0.145226 8.42467 0.44056L0.939749 7.91725C0.353417 8.50303 0.353417 9.45278 0.939749 10.0386L8.44791 17.5595C9.03424 18.1453 9.98488 18.1453 10.5712 17.5595C11.1575 16.9737 11.1575 16.024 10.5712 15.4382L5.6261 10.4779L26.082 10.4779C26.8651 10.4779 27.5 9.80633 27.5 8.97791C27.5 8.14948 26.8651 7.47791 26.082 7.47791L5.6261 7.47791L10.548 2.56188Z" fill="#2C3E50"/>
          </svg>
        </div>
        <div>
        Volver a la página del boletín
          
        </div>
      </div>
      <Pdfvieweruser file={pdf_url}/>
      </div>
      
    </div>
  )
}

export default Pdfview