export const imageUrls = [
    "/images/gallery/church/ALV_8984.jpg",
    // "/images/gallery/church/ALV_8985.jpg",
    // "/images/gallery/church/ALV_8986.jpg",
    // "/images/gallery/church/ALV_8987.jpg",
    // "/images/gallery/church/ALV_8988.jpg",
    // "/images/gallery/church/ALV_8989.jpg",
    // "/images/gallery/church/ALV_8990.jpg",
    "/images/gallery/church/ALV_8991.jpg",
    // "/images/gallery/church/ALV_8992.jpg",
    // "/images/gallery/church/ALV_8993.jpg",
    // "/images/gallery/church/ALV_8994.jpg",
    // "/images/gallery/church/ALV_8995.jpg",
    // "/images/gallery/church/ALV_8996.jpg",
    // "/images/gallery/church/ALV_8997.jpg",
    // "/images/gallery/church/ALV_8998.jpg",
    // "/images/gallery/church/ALV_8999.jpg",
    // "/images/gallery/church/ALV_9000.jpg",
    // "/images/gallery/church/ALV_9001.jpg",
    // "/images/gallery/church/ALV_9002.jpg",
    // "/images/gallery/church/ALV_9003.jpg",
    // "/images/gallery/church/ALV_9004.jpg",
    "/images/gallery/church/ALV_9005.jpg",
    // "/images/gallery/church/ALV_9006.jpg",
    // "/images/gallery/church/ALV_9007.jpg",
    "/images/gallery/church/ALV_9008.jpg",
    // "/images/gallery/church/ALV_9009.jpg",
    "/images/gallery/church/ALV_9013.jpg",
    "/images/gallery/church/ALV_9010.jpg",
    // "/images/gallery/church/ALV_9011.jpg",
    "/images/gallery/church/ALV_9012.jpg",
    // "/images/gallery/church/ALV_9014.jpg",
    "/images/gallery/church/ALV_9016.jpg",
    "/images/gallery/church/ALV_9017.jpg",
    // "/images/gallery/church/ALV_9018.jpg",
    // "/images/gallery/church/ALV_9019.jpg",
    // "/images/gallery/church/ALV_9020.jpg",
    // "/images/gallery/church/ALV_9021.jpg",
    // "/images/gallery/church/ALV_9022.jpg",
    "/images/gallery/church/ALV_9023.jpg",
    // "/images/gallery/church/ALV_9024.jpg",
    "/images/gallery/church/ALV_9041.jpg",
    // "/images/gallery/church/ALV_9025.jpg",
    "/images/gallery/church/ALV_9026.jpg",
    // "/images/gallery/church/ALV_9027.jpg",
    // "/images/gallery/church/ALV_9028.jpg",
    // "/images/gallery/church/ALV_9029.jpg",
    // "/images/gallery/church/ALV_9030.jpg",
    // "/images/gallery/church/ALV_9031.jpg",
    // "/images/gallery/church/ALV_9032.jpg",
    // "/images/gallery/church/ALV_9033.jpg",
    // "/images/gallery/church/ALV_9034.jpg",
    // "/images/gallery/church/ALV_9035.jpg",
    // "/images/gallery/church/ALV_9036-Mejorado-NR.jpg",
    // "/images/gallery/church/ALV_9036.jpg",
    "/images/gallery/church/ALV_9037.jpg",
    // "/images/gallery/church/ALV_9038.jpg",
    // "/images/gallery/church/ALV_9039.jpg",
    // "/images/gallery/church/ALV_9040.jpg",
    // "/images/gallery/church/ALV_9042.jpg",
    // "/images/gallery/church/ALV_9043.jpg",
    "/images/gallery/church/ALV_9044.jpg",
    "/images/gallery/church/ALV_9046.jpg",
    "/images/gallery/church/ALV_9053.jpg",
    "/images/gallery/church/ALV_9054.jpg",
    "/images/gallery/church/ALV_9061.jpg",
    // "/images/gallery/church/ALV_9062.jpg",
    "/images/gallery/church/ALV_9063.jpg",
    "/images/gallery/church/ALV_9064.jpg",
    "/images/gallery/church/ALV_9065.jpg",
    // "/images/gallery/church/ALV_9066.jpg",
    "/images/gallery/church/ALV_9067.jpg",
    // "/images/gallery/church/ALV_9070.jpg",
    "/images/gallery/church/ALV_9073.jpg",
    "/images/gallery/church/ALV_9074.jpg",
    // "/images/gallery/church/ALV_9075.jpg",
    "/images/gallery/church/ALV_9076.jpg",
    "/images/gallery/church/ALV_9077.jpg",
    // "/images/gallery/church/ALV_9078.jpg",
    "/images/gallery/church/ALV_9079.jpg",
    // "/images/gallery/church/ALV_9080.jpg",
    "/images/gallery/church/ALV_9081.jpg",
    "/images/gallery/church/ALV_9082.jpg",
    "/images/gallery/church/ALV_9083.jpg",
    // "/images/gallery/church/ALV_9084.jpg",
    // "/images/gallery/church/ALV_9085.jpg",
    // "/images/gallery/church/ALV_9086.jpg",
    // "/images/gallery/church/ALV_9087.jpg",
    // "/images/gallery/church/ALV_9088.jpg",
    // "/images/gallery/church/ALV_9089.jpg",
    // "/images/gallery/church/ALV_9090.jpg"
]