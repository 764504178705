import React, { useEffect, useState } from 'react'
import "./Catechism.css"
import $ from 'jquery'
import Header from '../common-components/Header';
import API from '../../API';

function Catechism() {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [PrimeraList, setPrimeraList] = useState([]);
  const [confirmacionList, setConfirmacionList] = useState([]);
  const [ConfirmationdeAdultosList, setConfirmationdeAdultosList] = useState([]);

  // Load data from backend
  const loadPDFs = (title, subtitle, setList) => {
    setIsLoading(true)
    API.get(`pdf/?title=${title}&subtitle=${subtitle}`)
      .then(response => {
        setList(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true);
        setMessage(error.message);
      });
  };
  const loadData = () => {
    loadPDFs('Catequesis', 'Primera', setPrimeraList);
    loadPDFs('Catequesis', 'Confirmación de jóvenes', setConfirmacionList);
    loadPDFs('Catequesis', 'Confirmatión de Adultos', setConfirmationdeAdultosList);
  };
  useEffect(() => {
    loadData();
  }, []);

  const downloadPDF = (pdf) => {
    const filename = `${pdf.title}_${pdf.subtitle}.pdf`;
    const link = document.createElement('a');
    link.href = pdf.pdf;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className='banner-cat' id='cat' style={{ backgroundImage: `url('/images/catechism/topimg.png')` }}>
        <Header />
      </div>

      <div className='subbody-cat' id='primera'>
        <p className=' headingtext-cat'><span className='great-vibes-regular'>Primera</span> <span className='poppins-regular1'>Comunión</span></p>
        <p className='dm-sans-regular subtextm'>
          La primera comunión consiste en recibir por primera vez el sacramento de la Eucaristía, después de una preparación adecuada y religiosa.
        </p>

        <div className='pdf-download-container'>
          {PrimeraList.map((pdf, index) => (
            <div key={index} className='btn-card3-esp dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className='subbody2-cat' id='conf-jovenes'>

        <img src='/images/catechism/candle.svg' className='candle'  />
        <p className='poppins-regular1 headingtext-cat'><p className='great-vibes-regular'>Confirmación</p> <p className='poppins-regular1 '>de jóvenes</p></p>
        <p className='dm-sans-regular  subtextm'>
          La Iglesia Católica considera la confirmación como el segundo de los tres Sacramentos de Iniciación. El bautismo es el primer sacramento y la Comunión es el tercer sacramento. La Confirmación es la perfección del Bautismo
        </p>

        <div className='pdf-download-container'>
          {confirmacionList.map((pdf, index) => (
            <div key={index} className='btn-card3-esp dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className='subbody-cat' id='conf-adultos'>
        <p className=' headingtext-cat adultos'><p className='great-vibes-regular '>Confirmación</p> <p className='poppins-regular1 '>de adultos</p></p>
        <p className='dm-sans-regular subtextm'>
          Por el sacramento de la Confirmación, [los bautizados] están más perfectamente ligados a la Iglesia y son enriquecidos con una fuerza especial del Espíritu Santo. Por eso están, como verdaderos testigos de Cristo, más estrictamente obligados a difundir y defender la fe de palabra y obra. Catecismo de la Iglesia Católica, n. 1285.
        </p>
        <div className='pdf-download-container'>
          {ConfirmationdeAdultosList.map((pdf, index) => (
            <div key={index} className='btn-card3-esp dm-sans-bold '>
              <img src='/images/esp-img/Outline/Download.svg' className='downloadlogo' />
              <span onClick={() => downloadPDF(pdf)}>
                Descargar PDF
              </span>
            </div>
          ))}
        </div>

      </div>

      <div className='subbody22'>
        <img src='/images/catechism/Rectangle 800.png' className='cat-last-img' />

      </div>






    </div>
  )
}

export default Catechism