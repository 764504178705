import React, { useEffect, useRef, useState } from 'react';
import "./Settings.css";
import API from '../../API';

import LoadingSpinner from '../../LoadingSpinner';
import PositiveModal from '../../PositiveModal';
import ErrorPopup from '../../ErrorPopup';
import { Navigate, useNavigate } from 'react-router-dom';

function Settings() {

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading,setIsLoading]=useState(false)
 
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    telephone: "",
    phonenumber: "",
    city: "",
    region: "",
    country_code:"",
    profile_image: null
  });

  useEffect(() => {
    const userId = localStorage.getItem('userID');
    API.get(`/user/edit_profile/${userId}/`)
      .then(response => {
        const user = response.data;
        setFormData({
          username: user.username,
          email: user.email,
          telephone: user.telephone,
          phonenumber: user.phonenumber,
          city: user.city ,
          region: user.region,
          country_code:user.country_code?user.country_code:"",
          profile_image: user.profile_image
        });
        setProfileImage(user.profile_image);
        
        console.log(response.data,"rwd");
        setIsLoading(false);
        
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
        console.log(error);
        setIsErrorModalOpen(true)
        setMessage(error.message)
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'file' ? files[0] : value
    }));
    if (type === 'file') {
      const file = files[0];
      const reader = new FileReader();
      console.log(reader)
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(formData)

  const handleSaveChanges = (e) => { 
   
    const userId = localStorage.getItem('userID');
    const updatedFormData = new FormData();
    updatedFormData.append('username', formData.username);
    updatedFormData.append('email', formData.email);
    updatedFormData.append('telephone', formData.telephone);
    updatedFormData.append('phonenumber', formData.phonenumber);
    updatedFormData.append('city', formData.city);
    updatedFormData.append('region', formData.region);
    updatedFormData.append('country_code', formData.country_code?formData.country_code:"");
    if (profileImage && profileImage !== formData.profile_image) {
      updatedFormData.append('profile_image', formData.profile_image);
      console.log(formData.profile_image)
    }


   
    setIsLoading( true)

    API.put(`/user/edit_profile/${userId}/`, updatedFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      
    .then(response => {
      console.log('Profile updated successfully', response);
    
      setIsLoading(false);
      setIsSuccessModalOpen(true);
      setMessage('Detalles actualizados exitosamente.')
       
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setIsLoading(false);
        setIsErrorModalOpen(true)
        if (error.response && error.response.data) {
          setMessage(error.response.data);
        } else {
          setMessage(error.message);
        }
      });
  };

  return (
    <div className='settingmaindiv'>
      <div className='headerofevent445'>
        <div className='titlebox-ad dm-sans-regular'><span>Configuración</span></div>
      </div>

      <div className='settings-body'>
        <div className='profile-settings-admin'>
          <div className='profile-pic-adminbox'>
            <div className='dasheddot'>
              <div
                className='profileround-admin'
                onClick={handleDivClick}
                style={{ backgroundImage: profileImage ? `url(${profileImage})` : 'none' }}
              >
                {profileImage ? (
                  <div className='up-img-admin' onClick={handleDivClick}>
                    <img src="\images\admin\settings\Img_fill.svg" alt="Upload Icon" />
                    <span className='dm-sans-regular col-white-set'>sube una imagen</span>
                  </div>
                ) : (
                  <span className='upload-placeholder dm-sans-regular'>Sube una imagen</span>
                )}
                <input
                  type='file'
                  ref={fileInputRef}
                  name='profile_image'
                  style={{ display: 'none' }}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <span className='allowedsize dm-sans-regular'>
            Permitidos *.jpeg, .jpg, <br/>.png tamaño máximo de 3.1 MB
            </span>
          </div>
        </div>

        <form className='form-admin-sett-box dm-sans-regular' onSubmit={(e) => { e.preventDefault(); handleSaveChanges(); }}>
          <div className='labelinp-line'>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Nombre</label>
              <input
                type="text"
                name='username'
                className='inpbox-admin-set'
                value={"Administrador"}
                onChange={handleInputChange}
                disabled={false}/>
            </div>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Dirección de correo electrónico</label>
              <input
                type="text"
                name='email'
                className='inpbox-admin-set'
                value={formData.email}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
          </div>

          <div className='labelinp-line'>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Número de teléfono móvil</label>
              <div className='d-flex'>
              <input
                type="number"
                name='country_code'
                className='inpbox-admin-set country-code'
                value={`${formData.country_code}`}
                onChange={handleInputChange}
                disabled={false}
               
              />
              <input
                type="text"
                name='phonenumber'
                className='inpbox-admin-set'
                value={`${formData.phonenumber}`}
                onChange={handleInputChange}
                disabled={false}
                max={10}
               
              />
                </div>
               
            </div>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Número de teléfono fijo</label>
              <input
                type="text"
                name='telephone'
                className='inpbox-admin-set'
                
                value={formData.telephone === "null" ? "" : formData.telephone}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='labelinp-line'>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Ciudad</label>
              <input
                type="text"
                name='city'
                className='inpbox-admin-set'
                value={formData.city === "null" ? "" : formData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className='textlabelinp'>
              <label className='dm-sans-regular'>Estado/Región</label>
              <input
                type="text"
                name='region'
                className='inpbox-admin-set'
                value={formData.region === "null" ? "" : formData.region}
                
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='labelinp-line2'>
            <button type='submit' className='savechange'>Guardar cambios</button>
          </div>
        </form>
      </div>
      {isSuccessModalOpen && <PositiveModal message={message} setterFunction={setIsSuccessModalOpen} okClickedFunction={() => { navigate('/admin/settings') }} />}
      {isLoading && <LoadingSpinner />}
      <ErrorPopup message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
    </div>
  );
}

export default Settings;
